import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { uploadFile } from '../../redux/actions/file';
import {
  selectCurrentOrganisationId,
  selectCurrentUserId,
  selectIsAdmin,
  selectIsHeadEmployee,
  selectIsHeadOrTrustedEmployee
} from '../../redux/selectors/auth';
import MatesService from '../../redux/services/mates.service';
import OrganisationsService from '../../redux/services/organisations.service';
import UsersDataService from '../../redux/services/user.service';
import ProfilePage from './ProfilePage';

const ProfilePageController = () => {
  const organisationId = useParams().orgId;
  const userId = useParams().userId;
  if (userId) {
    if (document.getElementsByClassName('sidebar')[0]) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
  } else {
    if (document.getElementsByClassName('sidebar')[0]) {
      document.getElementsByClassName('sidebar')[0].hidden = false;
    }
  }
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);
  const [validForm, setValidForm] = useState({
    email: true,
    oldPassword: true,
    newPassword: true,
    newPasswordRepeat: true,
    firstName: true,
    secondName: true,
    lastName: true,
    taxCode: true,
  });
  const [organisation, setOrganisation] = useState(null);

  const [errMsg, setErrMsg] = useState([]);
  const history = useHistory();

  const [password, setPassword] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const isHeadUser = useSelector(selectIsHeadOrTrustedEmployee);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentOrganisationId = useSelector(selectCurrentOrganisationId);
  const [changed, setChanged] = useState(false);

  const loadUserProfile = useCallback(async () => {
    if (userId && organisationId) {
      // /mates/<orgId>/<userId>
      const data = { mateId: userId, id: organisationId };
      MatesService.getMatesItem(data)
        .then((e) => {
          setItem(e);
        })
        .catch((e) => history.push('/page404'));
      OrganisationsService.getOrganisationsItem(organisationId)
        .then((e) => {
          setOrganisation(e);
        })
        .catch((e) => {});
        
    } else if ((userId && !organisationId) || (currentUserId && !currentOrganisationId)) {
      // /organisations/users/<userId>
      const id = userId || currentUserId;
      const user = await UsersDataService.getById(id);
      if (user && user.organisation_id) {
        const data = { mateId: user.id, id: user.organisation_id };
        MatesService.getMatesItem(data)
          .then((data) => {
            if (!data.error) {
              setItem(data);
            } else {
              setItem(user);
            }
          })
          .catch((e) => setItem(user));
        OrganisationsService.getOrganisationsItem(user.organisation_id)
          .then((data) => {
            if (!data.error) {
              setOrganisation(data);
            }
          })
          .catch((e) => {});
      } else {
        setItem(user);
      }
    } else {
      // /profile
      const data = { mateId: currentUserId, id: currentOrganisationId };
      MatesService.getMatesItem(data)
        .then(setItem)
        .catch((e) => {});

      OrganisationsService.getOrganisationsItem(currentOrganisationId)
        .then((data) => {
          if (!data.error) {
            setOrganisation(data);
          }
        })
        .catch((e) => {});
    }
  }, [userId, organisationId, currentUserId, currentOrganisationId]);

  useEffect(() => {
    if (isAdmin && !userId) {
      history.push('/applications');
    } else {
      void loadUserProfile();
    }
  }, []);

  const handleChange = (event) => {
    setValidForm({ ...validForm, [event.target.name]: true });
    setErrMsg([]);
    setChanged(true);
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const changeHandler = (event) => {
    const emailCheck =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textCheck = /^[А-ЯЄIЇҐа-яєіїґ]+$/i;
    const passwordCheck = /^(?=.*\d)(?=.*?[a-zA-Z]).{6,30}$/;
    const taxCodeCheck = /^[0-9]{10}$/;

    switch (event.target.name) {
      case 'email': // if (x === 'value1')
        if (emailCheck.test(String(event.target.value).toLowerCase())) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setItem({ ...item, [event.target.name]: event.target.value });
        break;
      case 'firstName':
      case 'secondName':
      case 'lastName':
        const isValid = textCheck.test(String(event.target.value).toLowerCase());
        setValidForm({ ...validForm, [event.target.name]: isValid });
        setItem({ ...item, [event.target.name]: event.target.value });
        break;
      case 'oldPassword':
        if (passwordCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setErrMsg([]);
        setPassword({ ...password, [event.target.name]: event.target.value });
        break;
      case 'newPassword':
        // validForm.newPasswordRepeat=false;
        if (passwordCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        if (event.target.value === password.newPasswordRepeat) {
          setValidForm({ ...validForm, newPasswordRepeat: true });
        }
        setErrMsg([]);
        setPassword({ ...password, [event.target.name]: event.target.value });
        break;
      case 'newPasswordRepeat':
        if (password.newPassword === event.target.value) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setErrMsg([]);
        setPassword({ ...password, [event.target.name]: event.target.value });
        break;

      case 'taxCode':
        if (taxCodeCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setItem({ ...item, [event.target.name]: event.target.value });
        break;
      default:
        setItem({ ...item, [event.target.name]: event.target.value });
        break;
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const changePassword = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }

      UsersDataService.updatePassword(item.id, password)
        .then(
          (res) => {
            if (res.data === 'OK') {
              setPassword({ newPassword: '', newPasswordRepeat: '', oldPassword: '' });
            }
          },
          (res) => {
            setErrMsg(res.response.data);
          },
        )
        .catch((err) => {
          console.log(err);
        });
    },
    [password],
  );

  const saveChange = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }

      UsersDataService.update(item.id, item)
        .then((response) => {
          setChanged(false);
          loadUserProfile();
        })
        .catch((e) => {
          const errors = e.response.data;

          setValidForm({ ...validForm, [errors.errors[0].param]: false });
          setErrMsg(errors);
        });
    },
    [item],
  );

  const loadFile = useCallback(
    async (event) => {
      var image = document.getElementById('output');
      if (event.target.files[0]) {
        image.src = 'https://i.gifer.com/74H8.gif';
        dispatch(uploadFile(event.target.files[0]))
          .then((response) => {
            item.picture = response;
            image.src = response;
            saveChange();
          })
          .catch(() => {});
      } else {
        image.src = item.picture;
      }
    },
    [item, saveChange],
  );

  if (!item) {
    return null;
  }

  return (
    <ProfilePage
      isAdmin={isAdmin}
      isHeadUser={isHeadUser}
      isOwnProfile={!userId}
      isOwnProfileEdit={!userId || userId ===currentUserId}
      item={item}
      organisation={organisation}
      changed={changed}
      loadFile={loadFile}
      saveChange={saveChange}
      handleChange={handleChange}
      passwordShown={passwordShown}
      changeHandler={changeHandler}
      togglePasswordVisibility={togglePasswordVisibility}
      password={password}
      changePassword={changePassword}
      loadUserProfile={loadUserProfile}
      validForm={validForm}
      errMsg={errMsg}
    />
  );
};

export default ProfilePageController;
