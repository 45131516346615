import { DICTIONARIESFULLLIST_SUCCESS } from '../actions/types';

const initialState = {};

export default function newDictionariesReducer(listFull = initialState, action) {
  const { type, list } = action;

  switch (type) {
    case DICTIONARIESFULLLIST_SUCCESS:
      return {
        appStatuses: list && list[4] ? appStatusesMapper(list[4]) : listFull.appStatuses,
      };
    default:
      return listFull;
  }
}

const appStatusesMapper = (items) => {
  const list = {};
  items.forEach((item) => {
    list[item.status] = item.title;
  });
  return list;
};
