import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/Pagination/Pagination';
import { getCertificateList } from '../../../redux/actions/certificate';
import Footer from '../../../components/Footer/Footer';
import Form from '../../DirectoriesPage/Form/Form';
import { PAGE_SIZE_CERTIFICATES } from '../../../constants/const';
import { selectRegions } from '../../../redux/selectors/dictionaries';
import setSex from '../../../helpers/setSex';
import NewLoader from '../../../components/NewLoader';
import { useHistory } from 'react-router-dom';


const PublicRosterPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { certificate, fullList, list } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('assignDate');
  const [prevSelected, setPrevSelected] = useState('assignDate');
  const [currentSort, setCurrentSort] = useState('desc');
  const [search, setSearch] = useState('');
  const [filterForm, setFilterForm] = useState([]);
  const [filter, setFilter] = useState(false);
  const [ready, setReady] = useState(false);
  const [formActive, setFormActive] = useState(false);
  const [intervalId, setIntervalId] = useState(false);
  const regions = useSelector(selectRegions);
  const history = useHistory();
  const [subtab, setSubTab] = useState({
    all: true,
    clone: false,
    refused: false,
  });

  const reviewClick = () => {
    history.push('/report');
  };

  const getCertificates = useCallback(
    async (arr, page) => {
      setReady(false);
      let data = [];
      if (search.length > 2 && (!arr || arr[0])) {
        data.push({ name: 'all', value: search });
      }
      if (sessionStorage.cert_all) {
        let all = JSON.parse(sessionStorage.cert_all);
        setFilterForm(all);
        if (all.length > 0) {
          all.forEach((element) => {
            if (element && element.value.length > 0) {
              data.push(element);
            }
          });
        }
      }

      if (
        arr &&
        arr[0] &&
        arr[0].sortName &&
        arr[0].sortName.length > 0 &&
        arr[0].sortName === selected
      ) {
        data.push({ sortName: selected, sortValue: arr[0].sortValue });
      } else if (
        arr &&
        arr[0] &&
        arr[0].sortName &&
        arr[0].sortName.length > 0 &&
        arr[0].sortName !== selected
      ) {
        data.push({ sortName: arr[0].sortName, sortValue: arr[0].sortValue });
      } else if (arr && arr.name && arr.name.length > 0) {
        data.push({ sortName: selected, sortValue: currentSort });
        data.push(arr);
      } else {
        data.push({ sortName: selected, sortValue: currentSort });
      }

      if (subtab.clone){
        data.push({ name: 'hasCopy', value: '1' });
      }else if (subtab.refused){
        data.push({ name: 'isDecline', value: '1' });
      }else{
        data.push({ name: 'isDecline', value: '0' });
      }
      
      await dispatch(getCertificateList(data, page)).then((res) => {
        setReady(true);
      });
    },
    [currentPage, selected, currentSort, search, subtab, ready],
  );
  const searchHandler = (e) => {
    clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      if (e.target.value.length > 2) {
        setSearch(e.target.value);
        let data = { name: 'all', value: e.target.value };
        getCertificates(data,1);
      } else {
        setSearch('');
        getCertificates({},1);
      }
    }, 1000)
    setIntervalId(newIntervalId);  
  };

  const clickSubHandler = (event) => {
    if(ready){
      setCurrentPage(1);
      let data={  
        all: false,
        clone: false,
        refused: false,}
        data[event.target.accessKey]=true;
      setSubTab(data);
      setReady(false);
    }
    
  };

  const onSortChange = (event) => {
    setCurrentPage(1);
    let data = [];
    let sort = 'asc';
    let field = event.target.id;
    setSelected(field);

    if (field !== prevSelected) {
      setPrevSelected(field);
      setCurrentSort('asc');
    } else if (selected === prevSelected) {
      if (currentSort === 'asc') {
        setCurrentSort('desc');
        sort = 'desc';
      } else if (currentSort === 'desc') {
        setCurrentSort('asc');
        sort = 'asc';
      }
    }
    data.push({ sortName: field, sortValue: sort });
    void getCertificates(data);
  };

  const filterClick = () => {
    setFilter(true);
    setFormActive(true);
  };

  const filterSave = () => {
    setFormActive(false);
    setFilter(false);
    sessionStorage.setItem('cert_all', JSON.stringify(filterForm));
    void getCertificates();
  };

  const getRegionTitle = (regionId) => {
    const region = regions.filter((item) => item.id === regionId).pop();
    return region ? region.title : '';
  };

  const getNosology = (nosologyId) => {
    
    const nosology = fullList[1].filter((item) => item.id === nosologyId).pop();
    return nosology ? nosology.shortTitle : '';
  };

  useEffect(() => {
    if(!ready){
      getCertificates();
    }
    if(ready){
      const tableContainer = document.querySelector('.public-table');
      const tableScroll = document.querySelector('.table-scroll');
  
      tableScroll.addEventListener('scroll', () => {
        tableContainer.scrollLeft = tableScroll.scrollLeft;
      });
  
      tableContainer.addEventListener('scroll', () => {
        tableScroll.scrollLeft = tableContainer.scrollLeft;
      });
    }
  }, [ready]);


   
  
  return (
    <div className="main public-page">
      <div
        className="public-sub-header"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h3>Публічний реєстр присвоєння спортивних звань</h3>
        <div  style={{ display: 'flex', alignItems: 'center' }}>
        <button
          className={
            filterForm && filterForm.length > 0
              ? 'icon-text-btn-secondary filter filter-on'
              : 'icon-text-btn-secondary filter'
          }
          onClick={filterClick}
        >
          Фільтр
        </button>
        <button className="icon-text-btn-secondary docs" onClick={reviewClick}>
          Звіти
        </button>
        </div>
      </div>
      <h1 className="public-header">Реєстр {subtab.all?'присвоєних': 'скасованих'} спортивних звань 
      {/* {certificate?.total && '(' + certificate?.total +')'} */}
      </h1>
      <label className="search">
        <div className="btn">
          <input
            className="search-input"
            type="text"
            onChange={searchHandler}
            placeholder="Пошук (мінімум 3 символа)"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>

      <div className="auth-subtab">
          <button
            accessKey="all"
            className={subtab.all ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Присвоєні
          </button>
          <button
            accessKey="refused"
            className={subtab.refused ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Скасовані
          </button>
          {/* <button
            accessKey="clone"
            className={subtab.clone ? 'auth-tab-item selected' : 'auth-tab-item'}
            onClick={clickSubHandler}
          >
            Дублікати посвідчення
          </button> */}
          
        </div>

      <div className="public-table" style={{ overflowX: 'scroll' }}>
        {ready ?
        <table className="public-table-cust roster">
          <thead>
            <tr className="roster">
              {/* <th className="selected"> <span>№ п/п</span></th> */}
              <th
                onClick={onSortChange}
                className={selected === 'assignDate' ? `selected ${currentSort}` : ''}
                id="assignDate"
              >
                <span id="assignDate">Присвоєння</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteName' ? `selected ${currentSort}` : ''}
                id="athleteName"
              >
                <span id="athleteName">ПІБ спортсмена/тренера</span>
              </th>
              <th
               id="sex"
               >
                 Стать
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'athleteCategoryTitle' ? `selected ${currentSort}` : ''}
                id="athleteCategoryTitle"
              >
                <span id="athleteCategoryTitle">Звання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'sportTitle' ? `selected ${currentSort}` : ''}
                id="sportTitle"
              >
                <span id="sportTitle">Вид спорту</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'organisationName' ? `selected ${currentSort}` : ''}
                id="organisationName"
              >
                <span id="organisationName">Суб’єкт подання</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'company' ? `selected ${currentSort}` : ''}
                id="company"
              >
                <span id="company">Відомство</span>
              </th>

              <th>Регіон</th>
              <th
                onClick={onSortChange}
                className={selected === 'trainerName' ? `selected ${currentSort}` : ''}
                id="trainerName"
              >
                <span id="trainerName">Тренер</span>
              </th>
              <th
                className={'trainerSex'}
                id="trainerSex"
              >
                <span id="trainerSex">Стать тренера</span>
              </th>
              <th
                onClick={onSortChange}
                className={selected === 'orderNumber' ? `selected ${currentSort}` : ''}
                id="orderNumber"
              >
                <span id="orderNumber">Наказ</span>
              </th>
              {/* <th
                onClick={onSortChange}
                className={selected === 'certificateNumber' ? `selected ${currentSort}` : ''}
                id="certificateNumber"
              >
                <span id="certificateNumber">Посвідчення</span>
              </th> */}
              <th>Підстава присвоєння</th>
              <th>
               Видано
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {certificate &&
              certificate.list &&
              certificate.list.length > 0 &&
              certificate.list.map((el, index) => {
                if (el.assignDate) {
                  return (
                    <tr className="roster" key={el.id}>
                      {/* <td>1</td> */}
                      <td>
                        {new Date(el.assignDate).toLocaleString('uk-UA', {
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric',
                        })}
                      </td>
                      <td>
                        {el.application.athleteLastName} {el.application.athleteFirstName}{' '}
                        {el.application.athleteSecondName}
                      </td>
                        <td>
                          {el.application.sex?list[el.application.sex]:'---'}
                        </td>
                      <td>
                        {el.application.athleteCategoryTitle &&
                        el.application.athleteCategoryTitle.split('/') &&
                        el.application.athleteCategoryTitle.split('/')[0]
                          ? el.application.athleteCategoryTitle.split('/')[0]
                          : el.application.athleteCategoryTitle}
                          <br />
                          <span style={{color:'grey'}}>
                            {el.application.sportCategoryId!=='f925fa34-258b-4d60-a604-eca9b027596f' &&  el.application.sportCategoryId!== '37a14ca2-4b35-4d3c-a180-4340338ab4eb' &&  el.application.sportCategoryId!=='1a5066b7-3dea-4fa1-8c16-dd5a449cb317' && fullList?.length>0 && getNosology(el.application.sportCategoryId)} 
                          </span>
                      </td>
                      <td>
                        {el.application.sportTitle} 
                      </td>
                      <td>
                        {el.application.organisation && el.application.organisation.shortName
                          ? el.application.organisation.shortName
                          : '----'}
                      </td>
                      <td>
                        {el.application.company && el.application.company!='null'? list[el.application.company] || el.application.company: '----'}
                      </td>
                      <td>
                        {(el.application.organisation && el.application.organisation.regionId) || el.application.regionId
                          ? getRegionTitle(el.application?.organisation?.regionId || el.application.regionId)
                          : '----'}
                      </td>
                      <td>
                        {el.application.trainerLastNameFirst
                          ? el.application.trainerLastNameFirst.toLowerCase().replace(/^\p{L}/u, (c) => c.toUpperCase()) +
                            ' ' +
                            el.application.trainerFirstNameFirst +
                            ' ' +
                            el.application.trainerSecondNameFirst
                          : ''}
                        {el.application.trainerLastNameSecond
                          ? ', ' +
                            el.application.trainerLastNameSecond +
                            ' ' +
                            el.application.trainerFirstNameSecond +
                            ' ' +
                            el.application.trainerSecondNameSecond 
                          : ''}
                        {el.application.trainerLastNameThird
                          ? ', ' +
                            el.application.trainerLastNameThird +
                            ' ' +
                            el.application.trainerFirstNameThird +
                            ' ' +
                            el.application.trainerSecondNameThird 
                          : ''}
                          {el.application.isFirstTrainer && "Перший тренер"}
                      </td>
                      <td>
                        {el.application.trainerSexFirst ? list[el.application.trainerSexFirst] : '----'} 
                        {el.application.trainerSexSecond && ', '+list[el.application.trainerSexSecond]} 
                        {el.application.trainerSexThird && ', '+list[el.application.trainerSexThird]} 
                      </td>
                      <td>{el.orderNumber}</td>
                      {/* <td>{el.certificateNumber}</td> */}

                      <td>
                        {el &&
                          el.application.achievements &&
                          el.application.achievements.length > 0 &&
                          el.application.achievements.map((elem, index) => {
                            return (
                              <React.Fragment key={elem.eventTitle + index}>
                                {' '}
                                {el.eventTitleIdTitle?
                                <>
                                  {el.eventTitleIdTitle + ' '+  el.eventDateStart.split('-')[0] +' '+ el.eventResultIdTitle}<br />{' '} 
                                </>:
                                <>
                                {elem.eventTitle} {elem.eventResult} <br />{' '}
                                </>}                              
                              </React.Fragment>
                            );
                          })}
                      </td>
                      <td>
                        {el.recipientName? el.recipientName+' '+el.application.updatedAt :'----'}
                      </td>
                      <td>
                        {el.url?.length>0&&
                         <button
                         className="icon-text-btn-secondary publicIcon"
                         onClick={() =>
                           window.open(el.url)
                         }
                          >
                            Копія&nbsp;наказу
                          </button>
                        }
                        {el && el.application.tournamentConfirmationDocumentUrl && (
                          <button
                            className="icon-text-btn-secondary publicIcon"
                            onClick={() =>
                              window.open(el.application.tournamentConfirmationDocumentUrl)
                            }
                          >
                            Протокол&nbsp;змагань
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
          {certificate && certificate.total > PAGE_SIZE_CERTIFICATES && (
            <tfoot className="">
              <tr>
                <td colSpan="7">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={certificate.total}
                    pageSize={PAGE_SIZE_CERTIFICATES}
                    onPageChange={async (page) => {
                      await setCurrentPage(page);
                      await getCertificates(null, page);
                    }}
                  />
                </td>
              </tr>
            </tfoot>
          )}

        </table>
        :
        <>
        <NewLoader/>
        </>
        }
        <div className="table-scroll">
          <div className="scroll-helper"></div>
        </div>
      </div>
      <Footer />

      {formActive && filter && (
        <Form
          form={filterForm}
          saveAddChange={filterSave}
          filter={filter}
          filterCert={true}
          setForm={setFilterForm}
          setFormActive={setFormActive}
        />
      )}
    </div>
  );   

};

export default PublicRosterPage;
