import {
  CONFIGURATIONGETLIST_SUCCESS,
  CONFIGURATIONGETLIST_FAIL,
  CONFIGURATIONUPDATE_SUCCESS,
  CONFIGURATIONUPDATE_FAIL,
} from './types';

import ConfigurationsService from '../services/configurations.service';

export const getConfiguration = (data, pagination) => (dispatch) => {
  return ConfigurationsService.getConfiguration(data, pagination).then(
    (response) => {
      dispatch({
        type: CONFIGURATIONGETLIST_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: CONFIGURATIONGETLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const updateConfiguration = (data) => (dispatch) => {
  return ConfigurationsService.updateConfiguration(data).then(
    (response) => {
      dispatch({
        type: CONFIGURATIONUPDATE_SUCCESS,
        list: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: CONFIGURATIONUPDATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
