import { DICTIONARIESFULLLIST_SUCCESS, DICTIONARIESFULLLIST_FAIL } from '../actions/types';

const initialState = [];

export default function fullListReducer(listFull = initialState, action) {
  const { type, list } = action;

  switch (type) {
    case DICTIONARIESFULLLIST_SUCCESS:
      return [...list];
    case DICTIONARIESFULLLIST_FAIL:
      return listFull;
    default:
      return listFull;
  }
}
