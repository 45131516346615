import {
  INSTRUCTIONSLIST_SUCCESS,
  INSTRUCTIONSLIST_FAIL,
  INSTRUCTIONSDELETE_SUCCESS,
  INSTRUCTIONSDELETE_FAIL,
  INSTRUCTIONSEDIT_SUCCESS,
  INSTRUCTIONSEDIT_FAIL,
  INSTRUCTIONSADD_SUCCESS,
  INSTRUCTIONSADD_FAIL,
} from './types';

import InstructionsService from '../services/instructions.service';

export const getInstructionsList = (data) => (dispatch) => {
  return InstructionsService.getInstructionsList(data).then(
    (response) => {
      dispatch({
        type: INSTRUCTIONSLIST_SUCCESS,
        list: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: INSTRUCTIONSLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const deleteInstructionsItem = (data) => (dispatch) => {
  return InstructionsService.deleteInstructionsItem(data).then(
    (response) => {
      dispatch({
        type: INSTRUCTIONSDELETE_SUCCESS,
      });
    },
    (error) => {
      dispatch({
        type: INSTRUCTIONSDELETE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
export const editInstructionsItem = (form) => (dispatch) => {
  return InstructionsService.editInstructionsItem(form).then(
    (response) => {
      dispatch({
        type: INSTRUCTIONSEDIT_SUCCESS,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: INSTRUCTIONSEDIT_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const addInstructionsItem = (form) => (dispatch) => {
  return InstructionsService.addInstructionsItem(form).then(
    (response) => {
      dispatch({
        type: INSTRUCTIONSADD_SUCCESS,
        list: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: INSTRUCTIONSADD_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
