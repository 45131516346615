import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import Pagination from '../../components/Pagination/Pagination';
import { logout } from '../../redux/actions/auth';
import { deleteMatesItem, editMatesItem, getMatesList } from '../../redux/actions/mates';
import { getOrganisationsItem } from '../../redux/actions/organisations';
import {
  selectCurrentOrganisationId,
  selectCurrentOrganisation as selectCurrentUserOrganisation,
  selectIsAdmin,
  selectIsHeadOrTrustedEmployee,
  selectIsHeadTrustedEmployee,
  selectIsHeadEmployee,
  selectCurrentUser,
} from '../../redux/selectors/auth';
import { selectCurrentOrganisation } from '../../redux/selectors/organisations';

const MateItemPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organisationId = useParams().orgId;
  const [currentPage, setCurrentPage] = useState(1);
  const [ready, setReady] = useState(false);
  const [deleteId, setDeleteItem] = useState({});
  const [alertActive, setAlertActive] = useState(false);
  const [search, setSearch] = useState('');

  const currentUserOrganisation = useSelector(selectCurrentUserOrganisation);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const isAdmin = useSelector(selectIsAdmin);
  const currentOrganisationId = useSelector(selectCurrentOrganisationId);
  const isHeadOrTrustedEmployee = useSelector(selectIsHeadOrTrustedEmployee);
  const isHeadTrustedEmployee = useSelector(selectIsHeadTrustedEmployee);
  const isHeadEmployee = useSelector(selectIsHeadEmployee);
  const currentUser = useSelector(selectCurrentUser);

  

  const { mates } = useSelector((state) => state);

  const getItem = useCallback(
    (search, page) => {
      let data = [];
      if (search && search.value.length > 2) {
        data.push({ name: 'lastName', value: search.value });
      }
      dispatch(getOrganisationsItem(organisationId))
        .then((response) => {
          dispatch(getMatesList(organisationId, data, page))
          .then((response) => {
            setReady(true);
          })
          .catch(() => {});
        })
        .catch(() => {});
    },
    [currentOrganisation, organisationId, search],
  );

  const backClick = () => {
    if (currentUserOrganisation.type === 1) {
      history.push('/organisations');
    } else {
      const backLink = sessionStorage.getItem('backLink');
      if (backLink) {
        history.push(backLink);
      } else {
        history.push('/organisations');
      }
    }
  };
  const setBoss = (e) => {
    let data = {
      value: e.target.checked,
      pos: 'isHeadTrustedEmployee',
      id: organisationId,
      mateId: e.target.id,
    };
    dispatch(editMatesItem(data))
      .then((res) => {
        if(!isAdmin && currentUser.id == e.target.id){
          dispatch(logout());
          window.location.href = '/login'
        }else{
          getItem();
        }
      })
      .catch((e) => {});
  };

  const searchHandler = (e) => {
    setCurrentPage(1);
    if (e.target.value.length > 2) {
      setSearch(e.target.value);
      let data = { name: 'all', value: e.target.value };
      getItem(data);
    } else {
      setSearch('');
      getItem();
    }
  };

  const addClick = () => {
    history.push(`/add-mate/${organisationId}`);
  };
  const handleDelete = useCallback(() => {
    let data = { id: organisationId, mateId: deleteId };

    dispatch(deleteMatesItem(data))
      .then((response) => {
        getItem();
        setAlertActive(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [deleteId]);
  const handleDeleteClick = (id) => {
    setDeleteItem(id);
    setAlertActive(true);
  };

  useEffect(() => {
    void getItem();
  }, [organisationId]);

  if (isAdmin) {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
  } else {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== false
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = false;
    }
  }

  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      {ready && (
        <h1 className="public-header">
          Співробітники {currentOrganisation && currentOrganisation.shortName}
        </h1>
      )}
      <label className="search">
        <div className="btn">
          <input
            className="search-input"
            type="text"
            onChange={searchHandler}
            placeholder="Пошук"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>
      <div className="create-app btn-tab">
        {isAdmin && (
          <button className="icon-text-btn-secondary back" onClick={backClick}>
            Назад
          </button>
        )}
        {(isAdmin || ( currentOrganisationId === organisationId && isHeadOrTrustedEmployee) )&& (
          <button className="icon-text-btn-primary btn-primary" onClick={addClick}>
            Додати співробітника
          </button>
        )}
      </div>
      {ready && (
        <div className="public-table">
          <table className="public-table-add">
            <thead>
              <tr>
                <th width="30%">ПІБ співробітників</th>
                <th width="20%">Спорт</th>
                <th width="10%">РНОКПП</th>
                <th width="10%">УНЗР</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mates && mates.list && mates.list.length > 0 ? (
                mates.list.map((item) => {
                  return (
                    <tr id={item.id} key={item.id}>
                      <td>
                        <Link className="mate-item" to={`/mates/${organisationId}/${item.id}`}>
                          {item.lastName} {item.firstName} {item.secondName}
                        </Link>
                        <span></span>
                      </td>
                      <td>
                        {item.sports?.length>0?item.sports.map(el=>{
                          return(
                            <>
                              {el.title+', '}
                            </>
                          )
                        }):'----'}
                      </td>
                      <td>
                        ...
                        {item.taxCode && item.taxCode.length > 5
                          ? item.taxCode.substr(item.taxCode.length - 5)
                          : item.taxCode}
                      </td>
                      <td>
                        ...
                        {item.unzr && item.unzr.length > 5
                          ? item.unzr.substr(item.unzr.length - 5)
                          : item.unzr}
                      </td>
                      <td className="icon-row">
                        {(isAdmin || currentOrganisationId === organisationId) &&
                          !item.isHeadEmployee && (
                            <>
                              <label className="form-switch">
                                <input
                                  id={item.id}
                                  type="checkbox"
                                  checked={item.isHeadTrustedEmployee}
                                  onChange={setBoss}
                                />
                                <i></i>призначити уповноважену особу
                              </label>
                              <i
                                className="icon delete-icon"
                                name={item.id}
                                onClick={() => handleDeleteClick(item.id)}
                              ></i>
                            </>
                          )}
                          {
                            item.isHeadEmployee &&
                            <>
                              Керівник
                            </>
                          }
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>Жодного співробітника не додано</td>
                </tr>
              )}
            </tbody>
            {mates && mates.total > 10 && (
              <tfoot className="">
                <tr>
                  <td colSpan="3">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={mates.total}
                      pageSize="10"
                      onPageChange={async (page) => {
                        await setCurrentPage(page);
                        await getItem(null, page);
                      }}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      )}
      {alertActive && (
        <>
          <div className="alert-label">
            <div>Ви впевнені, що хочете видалити?</div>
            <div className="alert-label-buttons">
              <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
                Видалити
              </button>
              <button
                className="round-btn-secondary close"
                onClick={() => setAlertActive(false)}
              ></button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MateItemPage;
