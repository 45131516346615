import axios from 'axios';
import authHeader from './auth-header';

import constants from '../../constants/constant.json';

const getAll = (
  offset = 0,
  limit = 10,
  sort_name = 'lastName',
  sort_value = 'asc',
  filter_name,
  filter_value,
) => {
  const urlParams = new URLSearchParams();

  urlParams.append('offset', offset.toString());
  urlParams.append('limit', limit.toString());
  urlParams.append(`sort[${sort_name}]`, sort_value);
  if (filter_name) {
    urlParams.append(`filters[${filter_name}]`, filter_value);
  }

  return axios
    .get(constants.API_URL + '/users/?' + urlParams.toString(), { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const getById = (id) => {
  return axios
    .get(constants.API_URL + `/users/${id}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const create = (data) => {
  return axios.post(constants.API_URL + `/users`, data, {
    headers: authHeader(),
    body: JSON.stringify(data),
  });
};

const update = (id, data) => {
  return axios
    .put(constants.API_URL + `/users/${id}`, data, { headers: authHeader() })
    .then((res) => {
      return res;
    });
};

const remove = (id) => {
  return axios.delete(constants.API_URL + `/users/${id}`, { headers: authHeader() }).then((res) => {
    return res;
  });
};

const updatePassword = (id, data) => {
  return axios.post(constants.API_URL + `/users/${id}/change-password`, data, {
    headers: authHeader(),
  });
};

const getCode = (data) => {
  return axios.post(constants.API_URL + `/users/email`, { body: JSON.stringify(data) });
};
const deleteUser = (id) => {
  return axios.delete(constants.API_URL + `/users/${id}`, { headers: authHeader() });
};


export default {
  getAll,
  getById,
  create,
  update,
  remove,
  getCode,
  updatePassword,
  deleteUser
};
