import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  DONE_REFRESHING_TOKEN,
  RESTORE_SUCCESS,
  USER_DATA_LOADED,
  USER_ORGANISATION_LOADED,
} from '../actions/types';

const user = JSON.parse(localStorage.getItem('userData'));

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        data: payload,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        errors: payload.errors,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case DONE_REFRESHING_TOKEN:
      return {
        ...state,
        isLoggedIn: true,
      };
    // case REFRESH_FAIL:
    //   return {
    //     ...state,
    //     isLoggedIn: false,
    //     user: null,
    //   };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case USER_DATA_LOADED:
      return {
        ...state,
        userData: payload,
      };
    case USER_ORGANISATION_LOADED:
      return {
        ...state,
        userOrganisation: payload,
      };
    default:
      return state;
  }
}
