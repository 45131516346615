import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const getOrganisationsList = (data) => {
  const urlParams = new URLSearchParams();

  urlParams.append('offset', data.offset || '0');
  urlParams.append('limit', data.limit || '100');

  if (data.sort) {
    Object.entries(data.sort).forEach(([name, value]) => {
      urlParams.append(`sort[${name}]`, value);
    });
  } else {
    urlParams.append(`sort[type]`, 'desc');
    urlParams.append(`sort[shortName]`, 'asc');
  }

  if (data.filter) {
    Object.entries(data.filter).forEach(([name, value]) => {
      urlParams.append(`filters[${name}]`, value);
    });
  }

  return axios
    .get(constants.API_URL + `/organisations/?${urlParams.toString()}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getOrganisationsItem = (id) => {
  return axios
    .get(constants.API_URL + '/organisations/' + id, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const deleteOrganisation = (id) => {
  return axios
    .delete(constants.API_URL + '/organisations/' + id, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const updateOrganisation = (data) => {
  const body = {
    ...data,
    title: data.name,
  };

  return axios
    .put(constants.API_URL + '/organisations/' + data.id, body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const createOrganisation = (data) => {
  return axios
    .post(constants.API_URL + '/organisations/', data, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getOrganisationsUnconfirmedList = () => {
  return axios
    .get(constants.API_URL + '/organisations/pending?sort[createdAt]=desc&offset=0&limit=100', {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getOrganisationsSubmissionList = (data) => {
  const urlParams = new URLSearchParams();
  urlParams.append('offset', data.offset || '0');
  urlParams.append('limit', data.limit || '100');
  if (data.sort) {
    Object.entries(data.sort).forEach(([name, value]) => {
      urlParams.append(`sort[${name}]`, value);
    });
  } else {
    urlParams.append(`sort[createdAt]`, 'desc');
  }

  if (data.filter) {
    Object.entries(data.filter).forEach(([name, value]) => {
      urlParams.append(`filters[${name}]`, value);
    });
  }

  return axios
    .get(constants.API_URL + `/organisations/submission-subjects?${urlParams.toString()}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};


const changeStatusUnconfirmedOrganisation = ({ id, type, reason }) => {
  let body = {};
  if (reason) {
    body = { reason };
  }
  return axios
    .post(constants.API_URL + '/organisations/' + id + '/' + type, body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getHeadEmployeeItem = (organisationId) => {
  return axios
    .get(constants.API_URL + `/organisations/${organisationId}/head-employee-name`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

export default {
  getOrganisationsList,
  getOrganisationsUnconfirmedList,
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
  getOrganisationsItem,
  changeStatusUnconfirmedOrganisation,
  getHeadEmployeeItem,
  getOrganisationsSubmissionList
};
