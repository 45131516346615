import React, { useState, useEffect } from 'react';

const SecondPage = (props) => {
  const [error, setError] = useState([]);

  useEffect(() => {
    if (props.errMsg && props.errMsg.errors && props.errMsg.errors[0]) {
      setError(props.errMsg.errors[0]);
      console.log(error);
    } else if (props.errMsg && props.errMsg[0]) {
      setError(props.errMsg[0]);
      console.log(error);
    } else {
      setError([]);
    }
  }, [props.errMsg]);

  const prevPage = () => {
    props.setNextPage(false);
  };
  const togglePasswordVisiblity = () => {
    props.setPasswordShown(props.passwordShown ? false : true);
  };

  return (
    <>
      <label className="labelText">
        <span>УНЗР (Унікальний номер запису в Єдиному державному демографічному реєстрі)</span>
        <input
          className={!props.validForm.unzr ? 'inputInvalid' : ''}
          type="text"
          name="unzr"
          required
          onChange={props.changeHandler}
          value={props.form.unzr}
        />
        {!props.validForm.unzr && (
          <span className="validError">Має бути 13 цифр в форматі "xxxxxxxx-xxxxx"</span>
        )}
        {error.param === 'unzr' && <span className="validError">{error.msg}</span>}
      </label>
      <label className="labelText ">
        <span>Пароль</span>
        <span className="prompt">
          Мінімум 6 символів латиницею, 1 велика і 1 маленька букви та 1 цифра
        </span>
        <div className="btn">
          <input
            className={!props.validForm.password ? 'inputInvalid psw-input' : 'psw-input'}
            type={props.passwordShown ? 'text' : 'password'}
            name="password"
            required
            onChange={props.changeHandler}
            value={props.form.password}
          />
          <i
            className={!props.passwordShown ? 'icon password-icon' : 'icon password-icon-noActive'}
            onClick={togglePasswordVisiblity}
          ></i>
        </div>
        {!props.validForm.password && <span className="validError">Перевірте пароль</span>}
        {error.param === 'password' && <span className="validError">{error.msg}</span>}
      </label>
      <label className="labelText btn">
        <span>Електронна пошта (логін)</span>
        {props.readyForChange && props.seconds > 0 && (
          <span className="prompt">Отримати код знову можна через: {props.seconds} сек.</span>
        )}
        <div className="btn">
          <input
            className={
              !props.validForm.email && props.form.email.length > 0
                ? 'inputInvalid btn txt-input'
                : 'btn txt-input'
            }
            type="email"
            name="email"
            required
            onChange={props.changeHandler}
            value={props.form.email}
          ></input>
          {props.code && !error.msg && props.seconds > 0 ? (
            <i className="icon ready-icon"></i>
          ) : (
            <button
              className="txt-button-small"
              onClick={props.resiveCode}
              disabled={!props.validForm.email || props.seconds > 0}
            >
              Отримати код{' '}
            </button>
          )}
        </div>
        {error.param === 'email' && <span className="validError">{error.msg}</span>}
      </label>

      <label className="labelText btn">
        <span>Код з пошти</span>
        <input
          className={!props.validForm.code ? 'inputInvalid btn txt-input' : 'btn txt-input'}
          disabled={!props.code}
          type="text"
          name="code"
          required
          onChange={props.changeHandler}
          value={props.form.code}
        />
        {!props.validForm.code && <span className="validError">Перевірте правильність коду</span>}
        {error.param === 'code' && <span className="validError">{error.msg}</span>}
      </label>
      <div className="button-control">
        <button
          type="submit"
          className="btn-primary text-btn-primary "
          onSubmit={props.registerHandler}
          disabled={
            !props.validForm.unzr ||
            !props.form.unzr.length > 0 ||
            !props.validForm.password ||
            !props.form.password.length > 0 ||
            !props.validForm.code ||
            !props.form.code.length > 0 ||
            error.param
          }
        >
          Зареєструватися
        </button>
        <button className="text-btn-tetriary" onClick={prevPage}>
          Назад{' '}
        </button>
      </div>
    </>
  );
};

export default SecondPage;
