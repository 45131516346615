import {
  DEPARTMENTSLIST_FAIL,
  DEPARTMENTSLIST_SUCCESS,
  DEPARTMENTSITEM_FAIL,
  DEPARTMENTSITEM_SUCCESS,
  DEPARTMENTSEDIT_SUCCESS,
  DEPARTMENTSDELETE_FAIL,
  DEPARTMENTSDELETE_SUCCESS,
  DEPARTMENTSEDIT_FAIL,
  DEPARTMENTSADD_FAIL,
  DEPARTMENTSADD_SUCCESS,
} from '../actions/types';

const initialState = [];

export default function departmentsReducer(departments = initialState, action) {
  const { type, list, item } = action;

  switch (type) {
    case DEPARTMENTSLIST_FAIL:
      return departments;
    case DEPARTMENTSLIST_SUCCESS:
      return [...list];
    case DEPARTMENTSITEM_FAIL:
      return departments;
    case DEPARTMENTSITEM_SUCCESS:
      return { currentOrganisation: item };
    case DEPARTMENTSEDIT_SUCCESS:
      return departments;
    case DEPARTMENTSDELETE_FAIL:
      return departments;
    case DEPARTMENTSDELETE_SUCCESS:
      return departments;
    case DEPARTMENTSEDIT_FAIL:
      return departments;
    case DEPARTMENTSADD_FAIL:
      return departments;
    case DEPARTMENTSADD_SUCCESS:
      return [{ ...departments, list }];
    default:
      return departments;
  }
}
