import { useCallback, useState } from 'react';

export const useMessage = () => {
  const [errMsg, setErrMsg] = useState([]);
  const message = useCallback((text) => {
    setErrMsg(text);
    if (typeof text === 'string' || text instanceof String) {
      return (Window.localerror = errMsg);
    }
  }, []);

  return { errMsg, message };
};
