import {
  DICTIONARIESLIST_FAIL,
  DICTIONARIESLIST_SUCCESS,
  DICTIONARIESDELETE_SUCCESS,
  DICTIONARIESDELETE_FAIL,
  DICTIONARIESEDIT_SUCCESS,
  DICTIONARIESEDIT_FAIL,
  DICTIONARIESADD_FAIL,
  DICTIONARIESADD_SUCCESS,
  DICTIONARIESITEM_FAIL,
  DICTIONARIESITEM_SUCCESS,
  DICTIONARIESFULLLIST_SUCCESS,
  DICTIONARIESFULLLIST_FAIL,
} from '../actions/types';

const initialState = [];

export default function dictionariesReducer(dictionary = initialState, action) {
  const { type, list } = action;

  switch (type) {
    case DICTIONARIESLIST_FAIL:
      return dictionary;
    case DICTIONARIESLIST_SUCCESS:
      return [...list];
    case DICTIONARIESEDIT_SUCCESS:
      return dictionary;
    case DICTIONARIESDELETE_FAIL:
      return dictionary;
    case DICTIONARIESDELETE_SUCCESS:
      return dictionary;
    case DICTIONARIESEDIT_FAIL:
      return dictionary;
    case DICTIONARIESADD_FAIL:
      return dictionary;
    case DICTIONARIESADD_SUCCESS:
      return dictionary;
    case DICTIONARIESITEM_SUCCESS:
      return [list];
    case DICTIONARIESITEM_FAIL:
      return dictionary;
    default:
      return dictionary;
  }
}
