import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAssignList } from "../../../redux/actions/assign";


const Assign =(props)=>{
    const dispatch = useDispatch();
    const applicationId = useParams().id;
    const [ready, setReady] = useState(false);
    const [form, setForm] = useState({});

    const initFunc=()=>{
        let data={id:applicationId};
        dispatch(getAssignList(data)).then(res=>{
            console.log(res);
            setForm(res);
            setReady(true);
        })
    }

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);

    return(
        <div className="create-application-container">
            {form.items?.length>0 &&
                <table>
                    <thead>
                        <tr>
                            <th>Затверджено</th>
                            <th>Підписант</th>
                            <th>Користувач</th>
                            {/* <th>Пошта</th> */}
                            <th>Серійний номер</th>
                            <th>Рішення</th>
                            <th>Дата</th>
                            <th></th>
                        </tr>
                    </thead>

                    {form.items.map((el,index)=>{
                        return(
                            <tr key={index}>
                                <td>
                                    {el.nameOrganization}
                                </td>
                                <td>
                                    {el.name}
                                </td>
                                <td>
                                    {el.signatureUser.lastName +' ' + el.signatureUser.firstName}
                                </td>
                                {/* <td>
                                    {el.signatureUser.email}
                                </td> */}
                                <td>
                                    {el.serial}
                                </td>
                                <td>
                                    {(el.applicationStatus==='5' && props.application.isOnComissionBySectionSpecialist)?'На комісію':(el.applicationStatus==='5' && props.application.isAcceptedBySectionSpecialist)?'Погоджено':el.applicationStatus==='5' && props.application.isAcceptedBySectionSpecialist===false?'Відхилено':''}
                                    {(el.applicationStatus==='8' && props.application.isOnComissionBySectionHead)?'На комісію':(el.applicationStatus==='8' && props.application.isAcceptedBySectionHead)?'Погоджено':el.applicationStatus==='8' && props.application.isAcceptedBySectionHead===false?'Відхилено':''}

                                    {(el.applicationStatus==='11' && props.application.isOnComissionBySectionSpecialist)?'На комісію':(el.applicationStatus==='11' && props.application.isAcceptedBySectionSpecialist)?'Погоджено':el.applicationStatus==='11' && props.application.isAcceptedBySectionSpecialist===false?'Відхилено':''}
                                    {(el.applicationStatus==='10' && props.application.isOnComissionBySectionHead)?'На комісію':(el.applicationStatus==='10' && props.application.isAcceptedBySectionHead)?'Погоджено':el.applicationStatus==='10' && props.application.isAcceptedBySectionHead===false?'Відхилено':''}
                                    
                                    
                                    {el.applicationStatus==='9' && 'На комісію'}
                                    {el.applicationStatus==='13' && 'На комісію'}

                                </td>
                                <td>
                                    {el.createdAt}
                                </td>
                                <td>
                                    <a
                                        target="_blank"
                                        href={el.file}
                                        className="document-link"
                                    >
                                    </a>
                                </td>
                            </tr>
                        )

                    })}

                </table>
            }

        </div>

    )
}

export default Assign;