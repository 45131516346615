import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createApplicationComment,
  getApplicationCommentItem,
  getApplicationsCommentList,
} from '../../../redux/actions/applicationMessage';
import { useHistory, useParams } from 'react-router-dom';
import { selectCurrentOrganisation } from '../../../redux/selectors/organisations';
import { uploadFile } from '../../../redux/actions/file';
import { createDocument } from '../../../redux/actions/documents';
import DocumentsService from '../../../redux/services/documents.service';
import { AppStatus, OrgType, SportType } from '../../../constants/const';

const Comments = () => {
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const { applicationMessage } = useSelector((state) => state);
  const [add, setAdd] = useState(false);
  const applicationId = useParams().id;
  const [form, setForm] = useState({ id: applicationId });
  const [ready, setReady] = useState(false);
  const [file, setFile] = useState(null);
  const currentApplication = useSelector((state) => state.applications.currentApplication);
  const history = useHistory();
  const dispatch = useDispatch();
  const addClick = () => {
    setAdd(true);
  };
  const closeClick = () => {
    setAdd(false);
  };
  const printClick = () => {
    history.push(`/print/${applicationId}/comment`);
  };

  const onDocumentUpload = useCallback(async (event) => {
    if (event.target.files[0]) {
      await dispatch(uploadFile(event.target.files[0]))
        .then((response) => {
          const data = {
            applicationId: applicationId,
            type: event.target.name,
            name: event.target.files[0].name,
            url: response,
          };
          setFile(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const addCommentSave = useCallback(
    async (e) => {
      e.preventDefault();
      const data = { ...form };

      if (file) {
        try {
          const document = await DocumentsService.addDocument(file);
          if (document) {
            data.documentId = document.id;
          }
        } catch (e) {
          console.log(e);
        }
      }

      await dispatch(createApplicationComment(data))
        .then(() => {
          setForm({ ...form, ['message']: '' });
          setFile(null);

          getMessages();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [form, file],
  );

  const getMessages = useCallback(async () => {
    await dispatch(getApplicationsCommentList(form))
      .then(() => {})
      .catch(() => {});
    setReady(true);
  }, [form]);

  useEffect(() => {
    getMessages();
  }, []);

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const updateComment = useCallback(
    async (el) => {
      let data = {
        itemId: el.id,
        id: applicationId,
      };
      await dispatch(getApplicationCommentItem(data))
        .then(() => {
          getMessages();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [applicationId],
  );

  if (
    currentOrganisation &&
    currentOrganisation.type == 0 &&
    applicationMessage &&
    applicationMessage.list &&
    applicationMessage.list.length > 0
  ) {
    applicationMessage.list.forEach((el) => {
      if (!el.isRead) {
        updateComment(el);
      }
    });
  }


  if (ready) {
    return (
      <div className="create-application-container">
        {applicationMessage &&
          (!applicationMessage.list || applicationMessage.list.length === 0) && (
            <div style={{ marginBottom: '40px' }}>Жодного коментаря ще не додано</div>
          )}
        {applicationMessage &&
          applicationMessage.list &&
          applicationMessage.list.length > 0 &&
          applicationMessage.list.map((el) => {
            return (
              <div className="comment-item" id={el.id} key={el.id}>
                {el.sender && !el.sender.picture && (
                  <button className="avatar">
                    {el.sender?.lastName.charAt(0)}
                    {el.sender?.firstName.charAt(0)}
                  </button>
                )}
                {el.sender && el.sender.picture && (
                  <img src={el.sender.picture} className="avatar avatar-picture"></img>
                )}
                <div className="comment-item-text" style={{ position: 'relative' }}>
                  <h5>
                    <span>
                      {el.sender?.lastName} {el.sender?.firstName}{' '}
                    </span>
                    {el.createdAt}
                    {el.isRead && (
                      <i className="readed-icon icon" style={{ top: '-10px', right: '-45px' }}></i>
                    )}
                  </h5>
                  <label>{el.sender.name}</label>
                  <div>
                    {' '}
                    <p>{el.message}</p>
                    {el.document && (
                      <a
                        target="_blank"
                        href={el.document.url}
                        className="document-link"
                        style={{ display: 'block' }}
                      >
                        {el.document.name}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        {add && (
          <div className="add-comment" style={{ alignItems: 'start', paddingBottom: '10px' }}>
            <button onClick={closeClick} className="round-comment" style={{ marginTop: '13px' }}>
              <i className="delete-icon icon"></i>
            </button>
            <form onSubmit={addCommentSave}>
              <input
                type="text"
                name="message"
                onChange={changeHandler}
                value={form.message || ''}
                placeholder="Ваш коментар"
              />
              <button
                type="submit"
                disabled={!form.message && !file}
                style={{ width: '120px', marginLeft: '10px' }}
                className="icon-text-btn-primary btn-primary"
              >
                Відправити
              </button>
              <label
                className="icon-text-btn-secondary dow"
                htmlFor="15"
                style={{ display: 'block' }}
              >
                Завантажити документ
                <div className="document-type-hint">
                  Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                </div>
              </label>
              <input
                accept="application/pdf,.jpg,.jpeg,.png"
                type="file"
                id="15"
                name="15"
                className="documentInput"
                onChange={onDocumentUpload}
              />
              {file && (
                <a
                  target="_blank"
                  href={file.url}
                  key={file.url}
                  className="document-link"
                  style={{ display: 'block' }}
                >
                  {file.name}
                </a>
              )}
            </form>
          </div>
        )}
        {!add && (
          <div className="create-application comm">
            <button className="icon-text-btn-secondary" disabled={(currentOrganisation.type === OrgType.SubmissionSubject && currentApplication.status !== AppStatus.Draft && currentApplication.status !== AppStatus.SubmissionSubjectFixing)||(currentOrganisation.type === OrgType.RegionSport && currentApplication.status !== AppStatus.RegionSportReview)} onClick={addClick}>
              <i className="add-icon" style={{ top: '0px' }}></i> Додати коментар
            </button>
            {applicationMessage && applicationMessage.list && applicationMessage.list.length > 0 && (
              <button className="icon-text-btn-secondary print" onClick={printClick}>
                Роздрукувати
              </button>
            )}
          </div>
        )}
      </div>
    );
  } else if (!ready) {
    return <div>Loading!</div>;
  }
};

export default Comments;
