import React, { useState, useEffect } from 'react';

const FirstPage = (props) => {
  const [error, setError] = useState([]);

  useEffect(() => {
    if (props.errMsg && props.errMsg.errors && props.errMsg.errors[0]) {
      setError(props.errMsg.errors[0]);
      console.log(error);
    } else {
      setError([]);
    }
  }, [props.errMsg]);

  const nextPage = () => {
    props.setNextPage(true);
  };

  return (
    <>
      <label className="labelText">
        <span>Прізвище</span>
        <input
          className={!props.validForm.lastName ? 'inputInvalid' : ''}
          type="text"
          name="lastName"
          onChange={props.changeHandler}
          value={props.form.lastName}
        />
        {!props.validForm.lastName && <span className="validError">Лише українські символи</span>}
        {error.param === 'lastName' && <span className="validError">{error.msg}</span>}
      </label>
      <label className="labelText">
        <span>Ім'я</span>
        <input
          className={!props.validForm.firstName ? 'inputInvalid' : ''}
          type="text"
          name="firstName"
          onChange={props.changeHandler}
          value={props.form.firstName}
          minLength="1"
        />
        {!props.validForm.firstName && <span className="validError">Лише українські символи</span>}
        {error.param === 'firstName' && <span className="validError">{error.msg}</span>}
      </label>
      <label className="labelText">
        <span>По батькові</span>
        <input
          className={!props.validForm.secondName ? 'inputInvalid' : ''}
          type="text"
          name="secondName"
          onChange={props.changeHandler}
          value={props.form.secondName}
        />
        {!props.validForm.secondName && <span className="validError">Лише українські символи</span>}
        {error.param === 'secondName' && <span className="validError">{error.msg}</span>}
      </label>
      <button
        className="btn-primary text-btn-primary "
        onClick={nextPage}
        disabled={
          !props.validForm.firstName ||
          !props.form.firstName.length > 0 ||
          !props.validForm.secondName ||
          !props.form.secondName.length > 0 ||
          !props.validForm.lastName ||
          !props.form.lastName.length > 0
        }
      >
        Далі
      </button>
      <a className="link" onClick={props.handleClick}>
        Відмінити
      </a>
    </>
  );
};

export default FirstPage;
