import {
  COVERHISTORYCREATE_SUCCESS,
  COVERHISTORYCREATE_FAIL,
  COVERHISTORYLIST_SUCCESS,
  COVERHISTORYLIST_FAIL,
  COVERHISTORYITEM_SUCCESS,
  COVERHISTORYITEM_FAIL,
  COVERHISTORYROLLBACK_SUCCESS,
  COVERHISTORYROLLBACK_FAIL,
} from '../actions/types';

const initialState = [];

export default function applicationsReducer(coverHistory = initialState, action) {
  const { type, list, item } = action;

  switch (type) {
    case COVERHISTORYITEM_SUCCESS:
      return { currentCoverHistory: item };
    case COVERHISTORYITEM_FAIL:
      return coverHistory;
    case COVERHISTORYLIST_FAIL:
      return coverHistory;
    case COVERHISTORYLIST_SUCCESS:
      return [...list];
    case COVERHISTORYROLLBACK_SUCCESS:
      return coverHistory;
    case COVERHISTORYROLLBACK_FAIL:
      return coverHistory;
    case COVERHISTORYCREATE_SUCCESS:
      return [{ ...coverHistory, list }];
    case COVERHISTORYCREATE_FAIL:
      return coverHistory;
    default:
      return coverHistory;
  }
}
