import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';
import authService from './auth.service';

const getSupportList = (data, pagination = 1) => {
    let filter = `?offset=${(pagination - 1) * 50}&limit=50&sort[sentAt]=desc`;
    return axios
      .get(constants.API_URL + `/support` + filter, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const getSupportListUnread = (data) => {
    return axios
      .post(constants.API_URL + `/support/unread-support`,{...data}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createSupportRoom = (data) => {
    return axios
      .post(constants.API_URL + `/support/create-room`,data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const adminCreateSuport = (data) => {
    return axios
      .post(constants.API_URL + `/support/admin-request`,data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  

  const addMessageSupportRoom = (data) => {
    return axios
      .post(constants.API_URL + `/support/${data.id}/write-message`,data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };  


const getSupportItem = (body,pagination=1) => {
  let filter = `?offset=${(pagination - 1) * 100}&limit=100&sort[sentAt]=desc`;
  return axios
    .get(constants.API_URL + `/support/${body.id}/all-message-room`+ filter, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const deleteSupportItem = (body) => {
  return axios
    .delete(constants.API_URL + `/users/${body.id}/notifications/${body.itemId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const uploadSupport =(data)=>{
  let bodyFormData = new FormData();
  bodyFormData.append('file', data);
  return axios
  .post(constants.API_URL + `/support/upload`,bodyFormData, { headers: authHeader() })
  .then((response) => {
    return response.data;
  })
  .catch((e) => {
    console.log(e);
  });
}




export default{
    getSupportList,
    getSupportItem,
    deleteSupportItem,
    createSupportRoom,
    addMessageSupportRoom,
    uploadSupport,
    adminCreateSuport,
    getSupportListUnread
}