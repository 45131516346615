import axios from 'axios';
import authHeader from './auth-header';

import constants from '../../constants/constant.json';

const getConfiguration = () => {
  return axios
    .get(constants.API_URL + '/configurations/document-number', { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const updateConfiguration = (body) => {
  return axios
    .put(
      constants.API_URL + '/configurations/document-number',
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getConfiguration,
  updateConfiguration,
};
