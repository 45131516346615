import {
  SUPPORTGETLIST_SUCCESS,
  SUPPORTGETLIST_FAIL,
  SUPPORTDELETE_SUCCESS,
  SUPPORTDELETE_FAIL,
  SUPPORTGETITEM_SUCCESS,
  SUPPORTGETITEM_FAIL,
  SUPPORTREADED_SUCCESS,
  SUPPORTREADED_FAIL,
} from '../actions/types';

const initialState = [];

export default function supportReducer(support = initialState, action) {
  const { type, list, item, offset, total } = action;
  switch (type) {
    case SUPPORTGETITEM_SUCCESS:
      return { currentSupport: item };
    case SUPPORTGETITEM_FAIL:
      return support;
    case SUPPORTGETLIST_FAIL:
      return support;
    case SUPPORTGETLIST_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    case SUPPORTDELETE_SUCCESS:
      return support;
    case SUPPORTDELETE_FAIL:
      return support;
    case SUPPORTREADED_SUCCESS:
      return support;
    case SUPPORTREADED_FAIL:
      return support;
    default:
      return support;
  }
}
