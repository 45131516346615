import React, { useState, useEffect, useCallback } from 'react';
import Avatar from '../../components/Avatar/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { getSportCategories } from '../../redux/actions/categories';
import {
  getDictionariesList,
  deleteDictionariesItem,
  editDictionariesItem,
  addDictionariesItem,
  getDictionariesListWithout,
  editShowDictionaries,
} from '../../redux/actions/dictionaries';
import Form from './Form/Form';
import { getConfiguration, updateConfiguration } from '../../redux/actions/configuration';

const DirectoriesPage = () => {
  const [ready, setReady] = useState(false);
  const [formActive, setFormActive] = useState(false);
  const [subList, setSubList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [conf, setConf] = useState(false);
  const { categories } = useSelector((state) => state);
  const [activeElement, setActive] = useState(' ');
  const [activeSubElement, setSubActive] = useState('');
  const { dictionaries } = useSelector((state) => state);
  const { currentConfiguration } = useSelector((state) => state.configuration);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    initialState: {
      name: '',
      category: activeElement,
      id: '',
      prevName: '',
      type: '',
      accessKey: '',
    },
  });
  const [deleteItem, setDeleteItem] = useState({});
  const [alertActive, setAlertActive] = useState(false);
  const getSport = useCallback(async () => {
    setReady(true);
    if(activeSubElement.length>12){
      getSubListActiveFunc();
    }else{
      await dispatch(getSportCategories())
      .then((response) => {})
      .catch(() => {});
      }
  }
, []);

  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }

  const handleDeleteClick = (event) => {
    setDeleteItem(event);
    setAlertActive(true);
  };
  const handleDelete = useCallback(() => {
    let event = deleteItem;
    let data = {};
    if(activeSubElement.length>12){
      data = {
        id: event.target.parentElement.id,
        category: 'sport-categories/' + activeElement + '/sports/'+activeSubElement+'/discipline/',
      };
    }
    else if (event.target.parentElement.accessKey) {
      data = {
        id: event.target.parentElement.id,
        category: 'sport-categories/' + activeElement + '/sports/',
      };
    } else {
      data = { id: event.target.parentElement.id, category: activeElement };
    }

    dispatch(deleteDictionariesItem(data))
      .then((response) => {
        if(activeSubElement.length>0){
        getSubListActiveFunc();
        }else{
          handleClick(data.category);
        }
        setAlertActive(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dictionaries, activeElement, deleteItem]);

  const handleEditConfiguration = () => {
    setConf(true);
    setFormActive(true);
    setForm({ value: currentConfiguration.value });
  };

  const editConfiguration = () => {
    setFormActive(false);
    setConf(false);

    dispatch(updateConfiguration({ ...form })).then((res) => {
      dispatch(getConfiguration());
    });
  };

  const handleEdit = (event, item) => {
    setEdit(true);
    setConf(false);
    let data = {};
    if(activeSubElement.length>12){
      data = {
        name: item.title,
        titleGenitive: item.titleGenitive,
        titleDative: item.titleDative,
        id: item.id,
        category: 'sport-categories/' + activeElement + '/sports/'+activeSubElement+'/discipline/',
        prevName: item.title,
        prevTitleGenitive: item.titleGenitive,
        prevTitleDative: item.titleDative,
      };
    }
    else if (event.target.parentElement.accessKey) {
      setSubActive('');
      data = {
        name: item.title,
        titleGenitive: item.titleGenitive,
        titleDative: item.titleDative,
        id: item.id,
        category: 'sport-categories/' + item.sportCategoryId + '/sports/',
        prevName: item.title,
        prevTitleGenitive: item.titleGenitive,
        prevTitleDative: item.titleDative,
      };
    } else if (activeElement === 'ministry-executives') {
      let words = event.target.parentElement.innerText.split(' ');
      let position = words.slice([3]);
      setSubActive('');
      data = {
        name: '',
        category: activeElement,
        id: event.target.parentElement.id,
        prevName: event.target.parentElement.innerText,
        firstName: words[1],
        lastName: words[0],
        secondName: words[2],
        position: position.join(' '),
      };
    } else if (activeElement === 'sport-categories') {
      setSubActive('');
      let arr = event.target.parentElement.innerText.split(' ');
      if (arr[arr.length - 1] === '(нозологія)') {
        arr.pop();
        let val = arr.join(' ');
        data = {
          name: '',
          shortName: '',
          category: activeElement,
          id: event.target.parentElement.id,
          prevName: val,
          prevShortName: event.target.accessKey
        };
      } else {
        setSubActive('');
        data = {
          name: '',
          category: activeElement,
          id: event.target.parentElement.id,
          prevName: event.target.parentElement.innerText,
        };
      }
    } else if (event.target.parentElement.slot) {
      setSubActive('');
      data = {
        name: '',
        category: activeElement,
        id: event.target.parentElement.id,
        prevName: event.target.parentElement.innerText,
        prevShortName: event.target.parentElement.slot,
      };
    }else if(event.target.parentElement.slot){
      setSubActive('');
      data = {
        name: '',
        category: activeElement,
        id: event.target.parentElement.id,
        prevName: event.target.parentElement.innerText,
        prevShortName: event.target.parentElement.slot,
      };
    } 
    else {
      setSubActive('');
      data = {
        name: '',
        category: activeElement,
        id: event.target.parentElement.id,
        prevName: event.target.parentElement.innerText,
      };
    }
    debugger
    setForm(data);
    setFormActive(true);
  };

  const handleAdd = (event) => {
    setEdit(false);
    setConf(false);
    let data = {};

    if(activeSubElement.length>12 && !event.target.accessKey){
      data = {
        name: '',
        id: event.target.parentElement.id,
        category: 'sport-categories/' + activeElement + '/sports/'+activeSubElement+'/discipline',
      };
    }
    else if (event.target.accessKey) {
      setSubActive('');
      data = {
        name: '',
        id: event.target.parentElement.id,
        category: 'sport-categories/' + activeElement + '/sports',
      };
    } else {
      setSubActive('');
      data = {
        name: '',
        category: activeElement,
        id: event.target.parentElement.id,
      };
    }
    setForm(data);
    setFormActive(true);
  };

  // const setList = (event)=>{
  //     let data ={name:'',id:event.target.parentElement.id,
  //     category: "sport-categories/"+activeElement+"/sports"}
  //     arr.forEach(element => {
  //
  //         data.name=element;
  //         dispatch(addDictionariesItem(data))
  //     });
  // }

  const saveEditChange = () => {
    dispatch(editDictionariesItem(form))
      .then(() => {
        if(activeSubElement.length>12){
          getSubListActiveFunc();
        }
        else if (form.category) {
          handleClick(form.category);
        } else {
          handleClick(activeElement);
        }
        setFormActive(false);
        if (activeElement === 'sport-categories') {
          getSport();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setEdit(false);
  };

  const setShow =(e)=>{
    console.log(e.target.checked)
    let data={};
    if(e.target.checked){
      data.isShow=1;
    }else{
      data.isShow=0;
    }
    dispatch(editShowDictionaries(data)).then(res=>{
      handleClick(activeElement);
    })
  }
  const saveAddChange = () => {
    dispatch(addDictionariesItem(form))
      .then(() => {
        if(activeSubElement.length>12){
          getSubListActiveFunc();
        }
        else if (form.category) {
          handleClick(form.category);
        } else {
          handleClick(activeElement);
        }
        setFormActive(false);
        if (activeElement === 'sport-categories') {
          getSport();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleClickConfiguration = useCallback(
    async (event) => {
      if (event && event.target && event.target.id === activeElement) {
        setActive('Clear');
        setTitle('');
      } else {
        setActive(event.target.id);
        setTitle(event.target.outerText);
        await dispatch(getConfiguration());
      }
    },
    [activeElement, title],
  );

  const handleClick = useCallback(
    async (event) => {
      const eventTargetId = event?.target?.id;
      if (eventTargetId === activeElement) {
        setActive('Clear');
        setTitle('');
      } else {
        if (eventTargetId) {
          setTitle(event.target.outerText);

          let data = '';

          if (event.target.accessKey) {
            data = 'sport-categories/' + eventTargetId + '/sports';
          } else {
            data = event.target.id;
          }

          await dispatch(getDictionariesList(data))
            .then(() => {
              setActive(eventTargetId);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          await dispatch(getDictionariesList(event))
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    [activeElement, title],
  );

  const getSubListActiveFunc=(eventTargetId)=>{
    let id = ''
    if(eventTargetId){
      id=eventTargetId;
    }else{
      id= activeSubElement;
    }
    let data = '';
    data ='sport-categories/'+activeElement+'/sports/'+id+'/discipline';
    dispatch(getDictionariesListWithout(data))
          .then((res) => {
            setSubList(res);
          })
          .catch((e) => {
            console.log(e);
          });
  }

  const handleSubActiveClick =(event)=>{
    const eventTargetId = event?.target?.id;
    if (eventTargetId === activeSubElement) {
      setSubActive('');
    }else{
      setSubActive(eventTargetId);
      getSubListActiveFunc(eventTargetId);
    }
  }
  useEffect(() => {
    if (categories.length === 0) {
      getSport();
    }
  }, [dictionaries, categories]);

  if (ready === false) {
    getSport();
  }

  return (
    <div className="main directory-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      <h1 className="public-header">Довідники</h1>
      <label className="search">
        {/* <div className='btn'>
                    <input className='search-input' type="text" placeholder="Пошук"/>
                    <i className='icon search-icon'></i>
                </div> */}
      </label>

      <div className="directory">
        {ready &&
          categories.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <div
                  key={item.id}
                  accessKey={item.id}
                  className="directory-subheader"
                  id={item.id}
                  name={item.id}
                  onClick={handleClick}
                >
                  Види спорту «{item.title}» {item.type === 2 && '(нозологія)'}
                </div>
                {activeElement === item.id && (
                  <div>
                    <div className="directory-subheader-list">
                      <button
                        accessKey={item.id}
                        className="icon-text-btn-small"
                        onClick={handleAdd}
                      >
                        <i className="add-icon"></i> Додати рядок
                      </button>
                    </div>
                    {activeElement === item.id &&
                      dictionaries.map((newItem) => {
                        return (
                          <>
                          <div
                            key={newItem.id}
                            accessKey={newItem.id}
                            className="directory-subheader-list"
                            id={newItem.id}
                            name={newItem.id}
                            onClick={handleSubActiveClick}
                          >
                            {item.type === 3 ? newItem.title.split(' ').pop() : newItem.title}{' '}
                            {newItem.titleGenitive && (
                              <span  id={newItem.id} style={{ fontSize: '15px', marginLeft: '20px' }}>
                                <b  id={newItem.id} >род.відм.:</b> {newItem.titleGenitive}
                              </span>
                            )}
                            {newItem.titleDative && (
                              <span id={newItem.id} style={{ fontSize: '15px', marginLeft: '20px' }}>
                                <b  id={newItem.id} >дав.відм.:</b> {newItem.titleDative}
                              </span>
                            )}
                            <i
                              className="icon edit-icon"
                              onClick={(e) => handleEdit(e, newItem)}
                            ></i>{' '}
                            <i
                              className="icon delete-icon"
                              name={newItem.id}
                              onClick={handleDeleteClick}
                            ></i>
                          </div>
                          {activeSubElement === newItem.id && <div>
                            <div className="directory-subheader-list">
                              <button
                                className="icon-text-btn-small"
                                onClick={handleAdd}
                              >
                                <i className="add-icon"></i> Додати рядок дисципліни
                              </button>
                              {subList.length>0 && subList.map(el=>{
                                return(
                                  <div
                                    key={el.id}
                                    accessKey={el.id}
                                    className="directory-subheader-list"
                                    id={el.id}
                                    name={el.id}
                                  >
                                    {item.type === 3 ? el.title.split(' ').pop() : el.title}{' '}
                                    {el.titleGenitive && (
                                      <span style={{ fontSize: '15px', marginLeft: '20px' }}>
                                        <b>род.відм.:</b> {el.titleGenitive}
                                      </span>
                                    )}
                                    {el.titleDative && (
                                      <span style={{ fontSize: '15px', marginLeft: '20px' }}>
                                        <b>дав.відм.:</b> {el.titleDative}
                                      </span>
                                    )}
                                    <i
                                      className="icon edit-icon"
                                      onClick={(e) => handleEdit(e, el)}
                                    ></i>{' '}
                                    <i
                                      className="icon delete-icon"
                                      name={el.id}
                                      onClick={handleDeleteClick}
                                    ></i>
                                  </div>
                                )
                              })

                              }

                              
                            </div>
                          </div>}
                          </>
                        );
                      })}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        <div
          className="directory-subheader"
          id="sport-categories"
          name="sport-categories"
          onClick={handleClick}
        >
          Категорії видів спорту
        </div>
        {activeElement === 'sport-categories' && (
          <div>
            {/* <div className="directory-subheader-list"><button className="icon-text-btn-small" onClick={handleAdd}> <i className="add-icon"></i> Додати рядок</button></div> */}
            {activeElement === 'sport-categories' &&
              dictionaries.map((item) => {
                
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.type === 2 ? (
                      <>
                        {' '}
                        {item.title} <span style={{ color: '#D0D0D0' }}>(нозологія)</span>
                      </>
                    ) : (
                      item.title
                    )}{' '}
                    <i className="icon edit-icon" accessKey={item.shortTitle} onClick={handleEdit}></i>
                    {/* <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i> */}
                  </div>
                );
              })}
          </div>
        )}

        <div
          className="directory-subheader"
          id="trainer-categories"
          name="trainer-categories"
          onClick={handleClick}
        >
          Категорії тренерів
        </div>
        {activeElement === 'trainer-categories' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'trainer-categories' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title} <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}
        <div
          className="directory-subheader"
          id="judge-categories"
          name="judge-categories"
          onClick={handleClick}
        >
          Суддівські категорії
        </div>
        {activeElement === 'judge-categories' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'judge-categories' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title} <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}
        <div
          className="directory-subheader"
          id="tournament-ranks"
          name="tournament-ranks"
          onClick={handleClick}
        >
          Ранги змагань
        </div>
        {activeElement === 'tournament-ranks' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'tournament-ranks' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    slot={item.description}
                    name={item.id}
                  >
                    {item.title}
                    {/* {item.description && <span>{item.description}</span>} */}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

        <div className="directory-subheader" id="regions" name="regions" onClick={handleClick}>
          Регіони
        </div>
        {activeElement === 'regions' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'regions' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title} <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}
        <div
          className="directory-subheader"
          id="sport-titles"
          name="sport-titles"
          onClick={handleClick}
        >
          Спортивні звання
        </div>
        {activeElement === 'sport-titles' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'sport-titles' &&
              dictionaries.map((item) => {
                return (
                  <div className="directory-subheader-list">
                    <div key={item.id} id={item.id} slot={item.description} name={item.id}>
                      {item.title}
                      {/* {item.description&&item.description} */}
                      <br />
                      <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                      <i
                        className="icon delete-icon"
                        name={item.id}
                        onClick={handleDeleteClick}
                      ></i>
                    </div>
                    <div style={{ color: 'darkgray' }}>
                      Номер Посвідчення: {item.documentNumber}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        <div
          className="directory-subheader"
          id="ministry-executives"
          name="ministry-executives"
          onClick={handleClick}
        >
          Kерівники міністерства
        </div>
        {activeElement === 'ministry-executives' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'ministry-executives' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.lastName} {item.firstName} {item.secondName} {item.position}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

        <div
          className="directory-subheader"
          id="application-status-titles"
          name="application-status-titles"
          onClick={handleClick}
        >
          Статуси заявки зовнішні
        </div>
        {activeElement === 'application-status-titles' && (
          <div>
            {activeElement === 'application-status-titles' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.status}
                    className="directory-subheader-list"
                    id={item.status}
                    name={item.status}
                  >
                    {item.title} <i className="icon edit-icon" onClick={handleEdit}></i>
                  </div>
                );
              })}
          </div>
        )}

        {/* <div
          className="directory-subheader"
          id="cover-letter-status-titles"
          name="cover-letter-status-titles"
          onClick={handleClick}
        >
          Статуси супровідного листа
        </div>
        {activeElement === 'cover-letter-status-titles' && (
          <div>
            {activeElement === 'cover-letter-status-titles' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.status}
                    name={item.status}
                  >
                    {item.title} <i className="icon edit-icon" onClick={handleEdit}></i>
                  </div>
                );
              })}
          </div>
        )} */}

        <div
          className="directory-subheader"
          id="user-role-titles"
          name="user-role-titles"
          onClick={handleClick}
        >
          Ролі користувачей
        </div>
        {activeElement === 'user-role-titles' && (
          <div>
            {activeElement === 'user-role-titles' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title} <i className="icon edit-icon" onClick={handleEdit}></i>
                  </div>
                );
              })}
          </div>
        )}
        <div
          className="directory-subheader"
          id="departments"
          name="departments"
          onClick={handleClick}
        >
          Відомства
        </div>
        {activeElement === 'departments' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'departments' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}
        <div
          className="directory-subheader"
          id="sex"
          name="sex"
          onClick={handleClick}
        >
          Стать
        </div>
        {activeElement === 'sex' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'sex' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

        <div
          className="directory-subheader"
          id="accomplished-results"
          name="accomplished-results"
          onClick={handleClick}
        >
          Виконаний результат
        </div>
        {activeElement === 'accomplished-results' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'accomplished-results' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

<div
          className="directory-subheader"
          id="competitions-category"
          name="competitions-category"
          onClick={handleClick}
        >
          Категорія змагань
        </div>
        {activeElement === 'competitions-category' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'competitions-category' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

<div
          className="directory-subheader"
          id="applications-rejected"
          name="applications-rejected"
          onClick={handleClick}
        >
          Шаблони відхилень заявок
        </div>
        {activeElement === 'applications-rejected' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'applications-rejected' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                    slot={item.description}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

        <div
          className="directory-subheader"
          id="commission-applications"
          name="commission-applications"
          onClick={handleClick}
        >
          Шаблони для заявок на комісію
        </div>
        {activeElement === 'commission-applications' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'commission-applications' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    slot={item.description}
                    name={item.id}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

         <div
          className="directory-subheader"
          id="category-catalog"
          name="category-catalog"
          onClick={handleClick}
        >
          Запити Підтримки
        </div>
        {activeElement === 'category-catalog' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'category-catalog' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.title}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}

        <div
          className="directory-subheader"
          id="users-list-permissions"
          name="users-list-permissions"
          onClick={handleClick}
        >
          Накази про призначення відповідальних осіб
        </div>

        {activeElement === 'users-list-permissions' && dictionaries.length>0 &&
          <label className="form-switch labelText">
            <input type="checkbox" checked={dictionaries[0].isShow} onChange={setShow} />
            <i></i>Показувати розділ
          </label>
        }

        {activeElement === 'users-list-permissions' && (
          <div>
            <div className="directory-subheader-list">
              <button className="icon-text-btn-small" onClick={handleAdd}>
                {' '}
                <i className="add-icon"></i> Додати рядок
              </button>
            </div>
            {activeElement === 'users-list-permissions' &&
              dictionaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="directory-subheader-list"
                    id={item.id}
                    name={item.id}
                  >
                    {item.url && (
                      <a href={item.url} target="_blank" className="document-link">
                        {item.url}
                      </a>
                    )}
                    {/* {item.title} */}
                    <i className="icon edit-icon" onClick={handleEdit}></i>{' '}
                    <i className="icon delete-icon" name={item.id} onClick={handleDeleteClick}></i>
                  </div>
                );
              })}
          </div>
        )}


        <div
          className="directory-subheader"
          id="configurations"
          name="configurations"
          onClick={handleClickConfiguration}
        >
          Конфігурації
        </div>
        {activeElement === 'configurations' && (
          <div>
            {activeElement === 'configurations' && (
              <div
                key={currentConfiguration && currentConfiguration.key}
                className="directory-subheader-list"
                id={currentConfiguration && currentConfiguration.key}
                name={currentConfiguration && currentConfiguration.key}
              >
                Номер посвідчення : {currentConfiguration && currentConfiguration.value}{' '}
                <i className="icon edit-icon" onClick={handleEditConfiguration}></i>
              </div>
            )}
          </div>
        )}
      </div>

      {formActive && (
        <Form
          setFormActive={setFormActive}
          form={form}
          setForm={setForm}
          saveEditChange={saveEditChange}
          saveAddChange={saveAddChange}
          edit={edit}
          title={title}
        ></Form>
      )}
      {formActive && conf && (
        <Form
          setFormActive={setFormActive}
          conf={conf}
          form={form}
          setForm={setForm}
          saveAddChange={editConfiguration}
        ></Form>
      )}
      {alertActive && (
        <>
          <div className="alert-label">
            <div>Ви впевнені, що хочете видалити?</div>
            <div className="alert-label-buttons">
              <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
                Видалити
              </button>
              <button
                className="round-btn-secondary close"
                onClick={() => setAlertActive(false)}
              ></button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DirectoriesPage;
