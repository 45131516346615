import { ASSIGNGETLIST_SUCCESS } from './types';

import AssignService from '../services/assign.service';

export const getAssignList = (data) => (dispatch) => {
    return AssignService.getAssignList(data).then(
      (response) => {
        dispatch({
          type: ASSIGNGETLIST_SUCCESS,
          list: response.items,
          total:response.total,
          limit: response.limit,
          offset: response.offset,
        });
        return response;
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      },
    );
  };