import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDictionariesList, editDictionariesItem } from '../../../redux/actions/dictionaries.js';
import { createOrganisation, getOrganisationsList } from '../../../redux/actions/organisations';

import Form from '../../DirectoriesPage/Form/Form';
import { selectOrganisations } from '../../../redux/selectors/organisations';
import OrganisationsService from '../../../redux/services/organisations.service';

const Regions = () => {
  if (
    document.getElementsByClassName('sidebar') &&
    document.getElementsByClassName('sidebar')[0] &&
    document.getElementsByClassName('sidebar')[0].hidden !== true
  ) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }
  const [isPageReady, setIsPageReady] = useState(false);
  const [organisationsReady, setOrganisationsReady] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { dictionaries } = useSelector((state) => state);
  const organisations = useSelector(selectOrganisations);
  const [alertActive, setAlertActive] = useState(false);
  const [activeElement, setActive] = useState('');
  const [formActive, setFormActive] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editOrg, setEditOrg] = useState(false);
  const [add, setAdd] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [form, setForm] = useState({});

  const getRegions = useCallback(async () => {
    await dispatch(getDictionariesList('/regions'));
    setIsPageReady(true);
  }, [dictionaries]);

  const handleClick = useCallback(
    async (event) => {
      let target;
      if (event.target && event.target.id) {
        target = event.target.id;
      } else {
        target = event;
      }

      if (target === activeElement && event.target && event.target.id) {
        setActive('');
      } else {
        setActive(target);
        const filter = { regionId: target };
        await dispatch(getOrganisationsList({ filter }))
          .then(() => {
            setActive(event.target.id);
            setOrganisationsReady(true);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [activeElement],
  );

  const addOrganisationClick = (event) => {
    setEdit(false);
    setForm({ type: 0 });
    setAdd(true);
    setFormActive(true);
    // setAdd(false);
  };

  const editClick = (event) => {
    let target = event.target.parentElement.id;

    let data = {
      prevName: event.target.parentElement.outerText,
      name: '',
      id: target,
    };
    setForm(data);
    setAdd(false);
    setEdit(true);
    setFormActive(true);
  };
  const editClickOrg = (event) => {
    let target = event.target.parentElement.id;

    let data = {
      prevName: event.target.parentElement.accessKey,
      prevShortName: event.target.parentElement.outerText,
      regionId:activeElement || '',
      name: '',
      id: target,
    };
    setForm(data);
    setAdd(false);
    setEdit(true);
    setEditOrg(true);
    setFormActive(true);
  };
  const saveEditChangeOrg = () => {
    setForm({ category: '/organisations' });
    form.category = '/organisations';
    OrganisationsService.updateOrganisation(form).then(() => {
      handleClick(activeElement);
      setFormActive(false);
      getRegions();
    });
    setEdit(false);
    setEditOrg(false);
  };

  const saveEditChange = () => {
    setForm({ category: '/regions' });
    form.category = '/regions';
    dispatch(editDictionariesItem(form))
      .then((response) => {
        handleClick(activeElement);
        setFormActive(false);
        getRegions();
      })
      .catch((e) => {
        console.log(e);
      });
    setEdit(false);
  };

  const saveAddChange = () => {
    form.regionId = activeElement;

    setForm({ regionId: activeElement, status: 0 });
    dispatch(createOrganisation(form))
      .then((response) => {
        handleClick(activeElement);
        setFormActive(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const mateClick = (event) => {
    history.push(`/mates/${event.target.parentElement.id}`);
    sessionStorage.setItem('backLink', '/organisations');
  };
  const handleDelete = useCallback(() => {
    const deleteId = deleteItem.target.parentElement.id;

    OrganisationsService.deleteOrganisation(deleteId).then(() => {
      handleClick(activeElement);
      setAlertActive(false);
    });
  }, [organisations, activeElement, deleteItem]);

  const handleDeleteClick = (event) => {
    setDeleteItem(event);
    setAlertActive(true);
  };

  useEffect(() => {
    void getRegions();
  }, []);

  return (
    <>
      <div className="organisation-subheader">Регіони</div>
      {isPageReady &&
        dictionaries.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <div
                className={
                  activeElement === item.id
                    ? 'organisation-subheader-list active'
                    : 'organisation-subheader-list'
                }
                id={item.id}
                onClick={handleClick}
              >
                {item.title}
                <i className="icon edit-icon" onClick={editClick}></i>
              </div>
              {activeElement === item.id && (
                <div className="organisation-subheader-list-item">
                  <div className="organisation-subheader-list first-line">
                    <button
                      className="icon-text-btn-small"
                      accessKey={item.id}
                      onClick={addOrganisationClick}
                    >
                      {' '}
                      <i className="add-icon"></i>Додати огранізацію
                    </button>
                  </div>
                  {organisationsReady &&
                    organisations.map((organisation) => {
                      return (
                        <div
                          className="directory-subheader-list"
                          key={organisation.id}
                          id={organisation.id}
                          accessKey={organisation.name}
                        >
                          {' '}
                          {organisation.shortName && organisation.shortName.length > 0
                            ? organisation.shortName
                            : organisation.name}
                          <i className="icon mates-icon" onClick={mateClick}></i>
                          <i
                            className="icon edit-icon"
                            accessKey={organisation.shortName}
                            onClick={editClickOrg}
                          ></i>
                          {organisation.type === 0 && (
                            <i className="icon delete-icon" onClick={handleDeleteClick}></i>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
            </React.Fragment>
          );
        })}
      {formActive && (
        <Form
          setFormActive={setFormActive}
          saveAddChange={saveAddChange}
          edit={edit}
          editOrg={editOrg}
          add={add}
          setEdit={setEdit}
          saveEditChange={saveEditChange}
          saveEditChangeOrg={saveEditChangeOrg}
          form={form}
          setForm={setForm}
        />
      )}
      {alertActive && (
        <>
          <div className="alert-label">
            <div>Ви впевнені, що хочете видалити?</div>
            <div className="alert-label-buttons">
              <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
                Видалити
              </button>
              <button
                className="round-btn-secondary close"
                onClick={() => setAlertActive(false)}
              ></button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Regions;
