import {
  DOCUMENTSGETITEM_SUCCESS,
  DOCUMENTSGETITEM_FAIL,
  DOCUMENTSGETLIST_FAIL,
  DOCUMENTSGETLIST_SUCCESS,
  DOCUMENTSDELETE_SUCCESS,
  DOCUMENTSDELETE_FAIL,
  DOCUMENTSCREATE_FAIL,
  DOCUMENTSCREATE_SUCCESS,
} from '../actions/types';

const initialState = [];

export default function applicationsReducer(documents = initialState, action) {
  const { type, list } = action;

  switch (type) {
    case DOCUMENTSGETITEM_SUCCESS:
      return documents;
    case DOCUMENTSGETITEM_FAIL:
      return documents;
    case DOCUMENTSGETLIST_FAIL:
      return documents;
    case DOCUMENTSGETLIST_SUCCESS:
      return [...list];
    case DOCUMENTSDELETE_SUCCESS:
      return documents;
    case DOCUMENTSDELETE_FAIL:
      return documents;
    case DOCUMENTSCREATE_FAIL:
      return documents;
    case DOCUMENTSCREATE_SUCCESS:
      return [{ ...documents, list }];
    default:
      return documents;
  }
}
