import { CERTIFICATEGETLIST_SUCCESS, CERTIFICATEGETITEM_SUCCESS, CERTIFICATEGETREPLIST_SUCCESS } from '../actions/types';

const initialState = [];

export default function certificateReducer(state = initialState, action) {
  const { type, list, item, offset, total } = action;

  switch (type) {
    case CERTIFICATEGETITEM_SUCCESS:
      return { ...state, currentCertificate: item };
    case CERTIFICATEGETLIST_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    case CERTIFICATEGETREPLIST_SUCCESS:
      return state;
    default:
      return state;
  }
}
