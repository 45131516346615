import { RETRIEVE_USERS,DELETE_USERS } from './types';

import UsersDataService from '../services/user.service';

export const retrieveUsers =
  (offset, limit, sort_name, sort, filter_name, filter) => async (dispatch) => {
    try {
      const response = await UsersDataService.getAll(
        offset,
        limit,
        sort_name,
        sort,
        filter_name,
        filter,
      );

      dispatch({
        type: RETRIEVE_USERS,
        payload: response,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getUser = (id) => (dispatch) => {
  let res = UsersDataService.getById(id).then(
    (data) => {
      dispatch({
        type: RETRIEVE_USERS,
        payload: { currentUser: data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    },
  );
  return res;
};


export const  deleteUser =(id)=> (dispatch) => {
  let res = UsersDataService.deleteUser(id).then(
    (data) => {
      dispatch({
        type: DELETE_USERS,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject();
    },
  );
  return res;
};
