import {
  CONFIGURATIONGETLIST_SUCCESS,
  CONFIGURATIONGETLIST_FAIL,
  CONFIGURATIONUPDATE_SUCCESS,
  CONFIGURATIONUPDATE_FAIL,
} from '../actions/types';

const initialState = [];

export default function configurationReducer(configuration = initialState, action) {
  const { type, item } = action;

  switch (type) {
    case CONFIGURATIONUPDATE_SUCCESS:
      return configuration;
    case CONFIGURATIONUPDATE_FAIL:
      return configuration;
    case CONFIGURATIONGETLIST_FAIL:
      return configuration;
    case CONFIGURATIONGETLIST_SUCCESS:
      return { currentConfiguration: item };

    default:
      return configuration;
  }
}
