import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisationsItem } from '../../redux/actions/organisations';
import { addMatesItem, getMatesUnconfirmedList } from '../../redux/actions/mates';

import Avatar from '../../components/Avatar/Avatar';
import Pagination from '../../components/Pagination/Pagination';
import { selectCurrentOrganisation } from '../../redux/selectors/organisations';

const AddMateItemPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const organisationId = useParams().id;
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const { mates } = useSelector((state) => state);
  const [selected, setSelected] = useState('');
  const history = useHistory();
  const [search, setSearch] = useState('');

  if (
    document.getElementsByClassName('sidebar') &&
    document.getElementsByClassName('sidebar')[0] &&
    document.getElementsByClassName('sidebar')[0].hidden !== true
  ) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }

  const getItem = useCallback(
    async (search, page) => {
      let data = [];
      if (search && search.value.length > 2) {
        data.push({ name: 'all', value: search.value });
      }
      setReady(true);
      await dispatch(getOrganisationsItem(organisationId))
        .then((response) => {})
        .catch(() => {});
      await dispatch(getMatesUnconfirmedList(organisationId, data, page))
        .then((response) => {})
        .catch(() => {});
      setReady(true);
    },
    [currentOrganisation, search, organisationId],
  );

  // const addClick = ()=>{
  //     history.push(`/add-mate/${organisationId}`)
  // }
  const searchHandler = (e) => {
    setCurrentPage(1);
    setSelected('');
    if (e.target.value.length > 2) {
      setSearch(e.target.value);
      let data = { name: 'all', value: e.target.value };
      getItem(data);
    } else {
      setSearch('');
      getItem();
    }
  };

  const addHandler = useCallback(async () => {
    let data = {
      userId: selected,
      id: organisationId,
    };
    await dispatch(addMatesItem(data)).then((response) => {
      setSelected('');
      getItem();
    });
  }, [selected]);
  const clickHandler = useCallback(
    (event) => {
      if (selected === event.currentTarget.id) {
        setSelected('');
      } else {
        setSelected(event.currentTarget.id);
      }
    },
    [selected],
  );

  const backHandler = () => {
    history.push(`/mates/${organisationId}`);
  };

  if (ready === false) {
    getItem();
  }

  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      {ready && (
        <h1 className="public-header">
          Додавання співробітників {currentOrganisation && currentOrganisation.shortName}
        </h1>
      )}
      <label className="search">
        <div className="btn">
          <input
            className="search-input"
            type="text"
            onChange={searchHandler}
            placeholder="Пошук"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>
      <div className="create-app btn-tab">
        <button className="icon-text-btn-secondary back" onClick={backHandler}>
          Назад
        </button>
        <button
          className="icon-text-btn-primary btn-primary"
          disabled={!selected}
          onClick={addHandler}
        >
          Додати
        </button>
      </div>
      {ready && (
        <div className="public-table">
          <table className="public-table-add">
            <thead>
              <tr>
                <th width="50%">
                  <span>ПІБ співробітників</span>
                </th>
                <th width="20%">
                  <span>Cпорт</span>
                </th>
                <th width="10%">
                  <span>РНОКПП</span>
                </th>
                <th width="10%">
                  <span>УНЗР</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mates && mates.list && mates.list.length > 0 ? (
                mates.list.map((item) => {
                  return (
                    <tr
                      className={selected && selected === item.id ? 'selected' : ''}
                      onClick={clickHandler}
                      id={item.id}
                      key={item.id}
                    >
                      <td>
                        {item.lastName} {item.firstName} {item.secondName}
                      </td>
                      <td>
                        {item.sports?.length>0?item.sports.map(el=>{
                          return(
                            <>
                              {el.title+', '}
                            </>
                          )
                        }):'----'}
                      </td>
                      <td>
                        ...
                        {item.taxCode && item.taxCode.length > 5
                          ? item.taxCode.substr(item.taxCode.length - 5)
                          : item.taxCode}
                      </td>
                      <td>
                        ...
                        {item.unzr && item.unzr.length > 5
                          ? item.unzr.substr(item.unzr.length - 5)
                          : item.unzr}
                      </td>
                      <td className="td-pos">
                        <i className="icon ready-icon"></i>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h2>Жодного зареєстрованого користувача</h2>
                  </td>
                </tr>
              )}
            </tbody>
            {mates && mates.total > 10 && (
              <tfoot className="">
                <tr>
                  <td colSpan="3">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={mates.total}
                      pageSize="10"
                      onPageChange={async (page) => {
                        await setCurrentPage(page);
                        await getItem(null, page);
                      }}
                    />{' '}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default AddMateItemPage;
