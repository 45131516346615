import { DICTIONARIESFULLLIST_FAIL, DICTIONARIESFULLLIST_SUCCESS, LISTID_SUCCESS} from './types';

import FullListService from '../services/full-list.service';

export const getFullList = (data) => (dispatch) => {
  return FullListService.getFullList(data)
    .then(
      (response) => {
        dispatch({
          type: DICTIONARIESFULLLIST_SUCCESS,
          list: response,
        });
        let dat ={};
        for(let i = 0; i<response.length; i++){
          for (let [key, value] of Object.entries(response)) {
            value.forEach(element => {
              if(element.sports?.length>0){
                element.sports.map(ele=>{
                  dat[ele.id]=ele.title;
                })
              };
              dat[element.id]=element.title;
            });
          }
        }
        dispatch({
          type:LISTID_SUCCESS,
          list:dat,
        });
        return response;
      },
      (error) => {
        dispatch({
          type: DICTIONARIESFULLLIST_FAIL,
        });
        console.log(error);
        return Promise.reject();
      },
    )
    .catch((e) => {
      console.log(e);
    });
};
