import { refreshToken } from '../actions/auth';
import { selectRefreshToken, selectToken } from '../selectors/auth';

export function jwt({ dispatch, getState }) {
  return (next) => (action) => {
    const tokenEncoded = selectToken(getState());
    const refreshTokenS = selectRefreshToken(getState());

    if (typeof action === 'function' && tokenEncoded) {
      let token = JSON.parse(atob(tokenEncoded.split('.')[1]));
      let tokenExpiration = token.exp * 1000 - Date.now();
      console.log('expiration in :' + tokenExpiration);

      if (tokenExpiration && tokenExpiration < 0) {
        if (!getState().auth.freshTokenPromise) {
          return refreshToken(dispatch, refreshTokenS).then(() => next(action));
        } else {
          return getState().auth.freshTokenPromise.then(() => next(action));
        }
      }
    }
    return next(action);
  };
}
