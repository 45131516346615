import {
  MATESLIST_FAIL,
  MATESLIST_SUCCESS,
  MATESDELETE_SUCCESS,
  MATESITEM_FAIL,
  MATESITEM_SUCCESS,
  MATESDELETE_FAIL,
  MATESEDIT_SUCCESS,
  MATESEDIT_FAIL,
  MATESADD_FAIL,
  MATESADD_SUCCESS,
} from '../actions/types';

const initialState = [];

export default function matesReducer(mates = initialState, action) {
  const { type, list, item, offset, total } = action;

  switch (type) {
    case MATESLIST_FAIL:
      return mates;
    case MATESLIST_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    case MATESITEM_FAIL:
      return mates;
    case MATESITEM_SUCCESS:
      return { currentMate: item };
    case MATESEDIT_SUCCESS:
      return mates;
    case MATESDELETE_FAIL:
      return mates;
    case MATESDELETE_SUCCESS:
      return mates;
    case MATESEDIT_FAIL:
      return mates;
    case MATESADD_FAIL:
      return mates;
    case MATESADD_SUCCESS:
      return [{ ...mates, list }];
    default:
      return mates;
  }
}
