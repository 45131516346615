import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

// const createHistoryPoint = (body) => {
//   return axios
//     .post(
//       constants.API_URL + `/applications/${body.id}/history-points`,
//       { ...body },
//       { headers: authHeader() },
//     )
//     .then((response) => {
//       return response.data;
//     });
// };

const createHistoryPoint = (body) => {
  let bodyFormData = new FormData();
  bodyFormData.append('file',  new Blob([JSON.stringify(body)], { type: 'text/plain' }));
  const authorizationHeaders = authHeader();
  return axios({
    method: 'post',
    url: constants.API_URL + `/applications/${body.id}/history-points`,
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authorizationHeaders,
    },
  });
};


const getHistoryPointList = (body) => {
  return axios
    .get(
      constants.API_URL +
        `/applications/${body}/history-points?offset=0&limit=100&sort[createdAt]=desc`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getHistoryPointItem = (body) => {
  return axios
    .get(constants.API_URL + `/applications/${body.id}/history-points/${body.historyId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const rollbackHistoryPoint = (body) => {
  return axios
    .post(constants.API_URL + `/applications/${body.id}/history-points/rollback`, body, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  createHistoryPoint,
  getHistoryPointList,
  getHistoryPointItem,
  rollbackHistoryPoint,
};
