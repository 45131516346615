import {
  HISTORYCREATE_SUCCESS,
  HISTORYCREATE_FAIL,
  HISTORYLIST_SUCCESS,
  HISTORYLIST_FAIL,
  HISTORYITEM_SUCCESS,
  HISTORYITEM_FAIL,
  HISTORYROLLBACK_SUCCESS,
  HISTORYROLLBACK_FAIL,
} from '../actions/types';

const initialState = [];

export default function applicationsReducer(history = initialState, action) {
  const { type, list, item } = action;

  switch (type) {
    case HISTORYITEM_SUCCESS:
      return { currentHistory: item };
    case HISTORYITEM_FAIL:
      return history;
    case HISTORYLIST_FAIL:
      return history;
    case HISTORYLIST_SUCCESS:
      return [...list];
    case HISTORYROLLBACK_SUCCESS:
      return history;
    case HISTORYROLLBACK_FAIL:
      return history;
    case HISTORYCREATE_SUCCESS:
      return [{ ...history, list }];
    case HISTORYCREATE_FAIL:
      return history;
    default:
      return history;
  }
}
