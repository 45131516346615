import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../components/Avatar/Avatar';
import {
  addInstructionsItem,
  deleteInstructionsItem,
  editInstructionsItem,
  getInstructionsList,
} from '../../redux/actions/instructions';
import Form from '../DirectoriesPage/Form/Form';
import { selectCurrentOrganisation } from '../../redux/selectors/organisations';
import { selectCurrentUserId, selectIsAdmin } from '../../redux/selectors/auth';
import Footer from '../../components/Footer/Footer';

const InstructionsPage = () => {
  const dispatch = useDispatch();
  const [winOpen, setOpen] = useState('');
  const [ready, setReady] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [search, setSearch] = useState('');
  const [alertActive, setAlertActive] = useState(false);
  const [editInstr, setEdit] = useState(false);
  const [formActive, setFormActive] = useState(false);
  const [form, setForm] = useState({});
  const { instructions } = useSelector((state) => state);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const currentUserId = useSelector(selectCurrentUserId);
  const isAdmin = useSelector(selectIsAdmin);

  const handleDownload = (e) => {
    const { accessKey } = e.currentTarget;
    if (accessKey.endsWith('.pdf')) {
      window.open(accessKey, '_blank');
    } else {
      // Завантажте файл, якщо це не PDF
      window.location.href = accessKey;
    }
  };
  const handleClick = (e) => {
    setOpen(winOpen === e.currentTarget.id ? '' : e.currentTarget.id);
  };

  const changeSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length < 3 && search >= 3) {
      initialFunction();
    } else {
      initialFunction(e.target.value);
    }
  };

  const createClick = () => {
    setFormActive(true);
    setEdit(false);
  };
  const createSave = () => {
    dispatch(addInstructionsItem(form))
      .then((res) => {
        setFormActive(false);
        initialFunction();
      })
      .catch((e) => {
        console.log(e);
        setFormActive(false);
        initialFunction();
      });
  };

  const editClick = (e) => {
    setForm(instructions[e.target.id]);
    setEdit(true);
    setFormActive(true);
  };

  const saveEditChange = () => {
    dispatch(editInstructionsItem(form))
      .then((res) => {
        setFormActive(false);
        initialFunction();
        setEdit(false);
      })
      .catch((e) => {
        console.log(e);
        setFormActive(false);
        setEdit(false);
        initialFunction();
      });
  };

  const handleDelete = () => {
    let e = deleteItem;
    dispatch(deleteInstructionsItem(e.target.id)).then((res) => {
      initialFunction();
      setAlertActive(false);
    });
  };
  const handleDeleteClick = (e) => {
    setDeleteItem(e);
    setAlertActive(true);
  };

  const initialFunction = (text) => {
    dispatch(getInstructionsList(text))
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      });
    setReady(true);
  };
  const apStartPlay = () => {
    let elem = document.getElementById('apPlayButton');
    elem.style.display = 'none';
    let playelem = document.getElementById('apVideoPlayer');
    playelem.play();
  };

  useEffect(() => {
    if (!ready) {
      initialFunction();
    }
  }, [ready]);

  return (
    <>
      <div className="main auth-page">
        <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
        {currentUserId && (
          <div className="auth-menu">
            <Avatar />
          </div>
        )}
        <h1 className="public-header">Інструкції з користування Системою</h1>

        <label className="search">
          <div className="btn">
            <input
              className="search-input"
              type="text"
              onChange={changeSearch}
              value={search}
              placeholder="Пошук"
            />
            <i className="icon search-icon"></i>
          </div>
        </label>
        {isAdmin && (
          <div className="create-app btn-tab">
            <button className="icon-text-btn-primary btn-primary" onClick={createClick}>
              Додати
            </button>
          </div>
        )}
        <div
          style={{ gridArea: 'tab', marginBottom: '16px', marginTop: '-16px', lineHeight: '120%' }}
        >
          {/* <p
            style={{
              textIndent: '20px',
              textAlign: 'justify',
              margin: '5px 10px',
              fontStyle: 'italic',
            }}
          >
            Інформаційна система «Онлайн сервіс для переведення у публічну площину процесу
            присвоєння спортивних звань з видів спорту, офіційно визнаних в Україні»&nbsp;(далі–
            Система) працює в режимі тестової експлуатації. Усі заявки на присвоєння спортивних
            звань подаються та розглядаються у звичайному режимі, але додатково можуть дублюватись в
            електронному вигляді в Системі. Подача заявки в електронному вигляді надасть Вам
            можливість узгодити з обласним структурним підрозділом з питань фізичної культури та
            спорту (далі – РегіонСпорт) пакет документів перед фізичною подачею, відстежувати у
            реальному часі перебіг обробки заявки та бачити коментарі посадовців.
          </p>
          <p
            style={{
              textIndent: '20px',
              textAlign: 'justify',
              margin: '5px 10px',
              fontStyle: 'italic',
            }}
          >
            Наразі порядок подання заявки для присвоєння спортивних звань спортивного звання з використанням Системи
            наступний:
          </p>
          <p
            style={{
              textIndent: '20px',
              textAlign: 'justify',
              margin: '5px 10px',
              fontStyle: 'italic',
            }}
          >
            Спортивна організація (суб’єкт подання) створює заявку в Системі та відправляє її на
            розгляд. Якщо заявку схвалюють у РегіонСпорті, тоді суб’єкт подання передає в
            РегіонСпорт паперову версію документів (завантажує з Системи та друкує подання, ставить
            печатку і підпис, додає 2 фотографії (3х4см), протоколи змагань та копії 1,2 сторінок
            паспорту і передає пакет документів до РегіонСпорту). Далі слідкує за статусами розгляду
            заявки у Системі та очікує на присвоєння спортивного звання.
          </p> */}
        </div>

        <div className="directory">
          {ready &&
            instructions &&
            instructions.length > 0 &&
            instructions.map((el, index) => {
              if (
                (currentOrganisation && currentOrganisation.type == el.type) ||
                (currentUserId && el.type == '00') ||
                el.type === null ||
                el.type === 'any' ||
                (currentOrganisation && currentOrganisation.type == 99)
              ) {
                return (
                  <React.Fragment key={el.id}>
                    <div
                      style={{
                        position: 'relative',
                        backgroundColor: 'white',
                        color: `${
                          (currentOrganisation && currentOrganisation.type == el.type) ||
                          el.type === null ||
                          el.type === 'any'
                            ? 'black'
                            : '#89A'
                        }`,
                      }}
                      accessKey={el.url}
                      className="directory-subheader"
                      id={el.id}
                      name={el.id}
                      title={el.description}
                    >
                      <div
                        accessKey={el.url}
                        id={el.id}
                        onClick={
                          el.videoUrl && el.videoUrl.length > 0 ? handleClick : handleDownload
                        }
                      >
                        {el.title}
                      </div>
                      {isAdmin && (
                        <>
                          <i className="icon edit-icon" id={index} onClick={editClick}></i>
                          <i
                            className="icon delete-icon"
                            id={el.id}
                            name={el.id}
                            onClick={handleDeleteClick}
                          ></i>
                        </>
                      )}
                    </div>
                    {el.videoUrl && el.videoUrl.length > 0 && winOpen === el.id && (
                      <div>
                        <div className="videoPlayerContainer">
                          <video
                            preload="metadata"
                            id="apVideoPlayer"
                            width="100%"
                            height="100%"
                            controls="controls"
                          >
                            <source type="video/mp4" src={el.videoUrl} data-quality="HD" />
                          </video>
                          <svg id="apPlayButton" onClick={apStartPlay}>
                            <use href="/Res/icon80PlayCircle.svg#icon80" />
                          </svg>
                        </div>
                        <button
                          style={{ margin: '0 10px 20px' }}
                          className="icon-text-btn-primary btn-primary"
                          accessKey={el.url}
                          onClick={handleDownload}
                        >
                          Переглянути PDF
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                );
              }
            })}
        <Footer />
        </div>
        {formActive && (
          <Form
            setFormActive={setFormActive}
            instruction={true}
            form={form}
            setForm={setForm}
            saveAddChange={editInstr ? saveEditChange : createSave}
          ></Form>
        )}
        {alertActive && (
          <>
            <div className="alert-label">
              <div>Ви впевнені, що хочете видалити?</div>
              <div className="alert-label-buttons">
                <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
                  Видалити
                </button>
                <button
                  className="round-btn-secondary close"
                  onClick={() => setAlertActive(false)}
                ></button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InstructionsPage;
