import {
  APPLICATIONMESSAGEGETLIST_SUCCESS,
  APPLICATIONMESSAGEGETLIST_FAIL,
  APPLICATIONMESSAGECREATE_SUCCESS,
  APPLICATIONMESSAGECREATE_FAIL,
  APPLICATIONMESSAGEDELETE_SUCCESS,
  APPLICATIONMESSAGEDELETE_FAIL,
  APPLICATIONMESSAGEGETITEM_SUCCESS,
  APPLICATIONMESSAGEGETITEM_FAIL,
} from './types';

import ApplicationMessageService from '../services/applicationMessage.service';

export const getApplicationsCommentList = (data, pagination) => (dispatch) => {
  return ApplicationMessageService.getApplicationsCommentList(data, pagination).then(
    (response) => {
      dispatch({
        type: APPLICATIONMESSAGEGETLIST_SUCCESS,
        list: response.items,
        total: response.total,
        offset: response.offset,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: APPLICATIONMESSAGEGETLIST_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};

export const createApplicationComment = (data) => (dispatch) => {
  return ApplicationMessageService.addApplicationCommentItem(data).then(
    (response) => {
      dispatch({
        type: APPLICATIONMESSAGECREATE_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: APPLICATIONMESSAGECREATE_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};

export const getApplicationCommentItem = (data) => (dispatch) => {
  return ApplicationMessageService.getApplicationCommentItem(data).then(
    (response) => {
      dispatch({
        type: APPLICATIONMESSAGEGETITEM_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: APPLICATIONMESSAGEGETITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const deleteApplicationCommentItem = (data) => (dispatch) => {
  return ApplicationMessageService.deleteApplicationCommentItem(data).then(
    (response) => {
      dispatch({
        type: APPLICATIONMESSAGEDELETE_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: APPLICATIONMESSAGEDELETE_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};
