import {
  HISTORYCREATE_SUCCESS,
  HISTORYCREATE_FAIL,
  HISTORYLIST_SUCCESS,
  HISTORYLIST_FAIL,
  HISTORYITEM_SUCCESS,
  HISTORYITEM_FAIL,
  HISTORYROLLBACK_SUCCESS,
  HISTORYROLLBACK_FAIL,
} from './types';

import HistoryService from '../services/history.service';

export const getHistoryList = (data) => (dispatch) => {
  return HistoryService.getHistoryPointList(data).then(
    (response) => {
      dispatch({
        type: HISTORYLIST_SUCCESS,
        list: response.items,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: HISTORYLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const createHistory = (data) => (dispatch) => {
  return HistoryService.createHistoryPoint(data)
    .then((response) => {
      dispatch({
        type: HISTORYCREATE_SUCCESS,
        item: response,
      });

      return response;
    })
    .catch((error) => {
      dispatch({
        type: HISTORYCREATE_FAIL,
      });
      console.log(error);
      if (error.response) {
        return error.response.data;
      }
    });
};

export const rollbackHistory = (data) => (dispatch) => {
  return HistoryService.rollbackHistoryPoint(data).then(
    (response) => {
      dispatch({
        type: HISTORYROLLBACK_SUCCESS,
        item: response,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: HISTORYROLLBACK_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getHistoryItem = (data) => (dispatch) => {
  return HistoryService.getHistoryPointItem(data).then(
    (response) => {
      dispatch({
        type: HISTORYITEM_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: HISTORYITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
