import {
  ROLE_ADMIN,
  ROLE_HEAD_EMPLOYEE,
  ROLE_HEAD_TRUSTED_EMPLOYEE,
  ROLE_REGION_SPORT,
} from '../../constants/const';

export const selectCurrentUserId = (state) =>
  state.auth && state.auth.user && state.auth.user.payload && state.auth.user.payload.id;
export const selectCurrentOrganisationId = (state) =>
  state.auth &&
  state.auth.user &&
  state.auth.user.payload &&
  state.auth.user.payload.organisationId;

export const selectPermissions = (state) =>
  (state.auth &&
    state.auth.user &&
    state.auth.user.payload &&
    state.auth.user.payload.permissions) ||
  [];

export const selectToken = (state) => state.auth && state.auth.user && state.auth.user.token;

export const selectRefreshToken = (state) =>
  state.auth && state.auth.user && state.auth.user.refreshToken;

export const selectCurrentUser = (state) => state.auth && state.auth.userData;

export const selectCurrentOrganisation = (state) => state.auth && state.auth.userOrganisation;

export const selectIsAdmin = (state) => selectPermissions(state).includes(ROLE_ADMIN);

export const selectIsRegionSport = (state) => selectPermissions(state).includes(ROLE_REGION_SPORT);

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn || false;

export const selectIsHeadEmployee = (state) =>
  selectPermissions(state).includes(ROLE_HEAD_EMPLOYEE);

export const selectIsHeadTrustedEmployee = (state) =>
  selectPermissions(state).includes(ROLE_HEAD_TRUSTED_EMPLOYEE);

export const selectIsHeadOrTrustedEmployee = (state) =>
  selectIsHeadEmployee(state) || selectIsHeadTrustedEmployee(state);
