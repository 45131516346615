import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from '../../DirectoriesPage/Form/Form';
import Pagination from '../../../components/Pagination/Pagination';
import { retrieveUsers } from '../../../redux/actions/users';
import { selectUsersList, selectUsersTotal } from '../../../redux/selectors/users';
import UsersDataService from '../../../redux/services/user.service';

const PAGE_SIZE = 25;

export const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector(selectUsersList);
  const usersTotal = useSelector(selectUsersTotal);

  const [form, setForm] = useState({});
  const [search, setSearch] = useState(sessionStorage.getItem('usersSearch') || '');
  const [formActive, setFormActive] = useState(false);
  const [edit, setEdit] = useState(false);
  const [alertActive, setAlertActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = useCallback(
    async (page = currentPage, searchValue = search) => {
      const limit = PAGE_SIZE;
      const offset = (page - 1) * limit;
      const filterValue = sessionStorage.getItem('usersSearch') || '';
      const filterName = filterValue.length > 2 ? 'all' : undefined;
      dispatch(retrieveUsers(offset, limit, 'lastName', 'asc', filterName, filterValue));
    },
    [currentPage, search],
  );

  const saveEditChange = () => {
    setForm({ category: 'users' });
    form.category = 'users';
    UsersDataService.update(form.id, form)
      .then(() => {
        setFormActive(false);
        void getUsers();
      })
      .catch((e) => {
        console.log(e);
      });
    setEdit(false);
  };

  const mateClick = (id) => {
    history.push(`/organisations/users/${id}`);
    sessionStorage.setItem('backLink', '/organisations/users');
  };

  const editClick = (user) => {
    const data = {
      id: user.id,
      lastName: user.lastName,
      firstName: user.firstName,
      secondName: user.secondName,
      category: 'users',
    };
    setForm(data);
    setEdit(true);
    setFormActive(true);
  };

  const handleDelete = useCallback(() => {
    UsersDataService.remove(deleteId)
      .then(() => {
        setAlertActive(false);
        void getUsers();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [deleteId]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setAlertActive(true);
  };

  const onSearchChange = (e) => {
    const value = e.target.value || '';
    setSearch(value);
    setCurrentPage(1);
    sessionStorage.setItem('usersSearch', value.trim());
    if (value.trim().length > 2 || !value.length) {
      void getUsers(1);
    }
  };

  useEffect(() => {
    void getUsers();
  }, []);

  return (
    <>
      <label className="search" style={{ margin: '-20px 0 20px 0', display: 'block' }}>
        <div className="btn" style={{ float: 'none' }}>
          <input
            className="search-input"
            type="text"
            onInput={onSearchChange}
            defaultValue={search}
            placeholder="Пошук (мінімум 3 символа)"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>
      <table className="public-table-cust">
        <thead>
          <tr>
            <th>ПІБ</th>
            <th>Організація</th>
            <th style={{ width: '100px' }}>Роль</th>
            <th style={{ width: '100px' }}>РНОКПП</th>
            <th style={{ width: '100px' }}>УНЗР</th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {!!users.length &&
            users.map((user) => {
              return (
                <tr id={user.id} key={user.id}>
                  <td>
                    <span className="mate-item" onClick={() => mateClick(user.id)}>
                      {user.lastName} {user.firstName} {user.secondName}
                    </span>
                  </td>
                  <td>{user.shortName}</td>
                  <td>
                    {user.isHeadEmployee && 'Керівник'}
                    {user.isHeadTrustedEmployee && 'Уповноважена особа'}
                  </td>
                  <td>
                    ...
                    {user.taxCode && user.taxCode.length > 5
                      ? user.taxCode.substr(user.taxCode.length - 5)
                      : user.taxCode}
                  </td>
                  <td>
                    ...
                    {user.unzr && user.unzr.length > 5
                      ? user.unzr.substr(user.unzr.length - 5)
                      : user.unzr}
                  </td>
                  <td id={user.id} className="icon-row">
                    <i
                      className="icon edit-icon"
                      accessKey={user.id}
                      onClick={() => editClick(user)}
                    ></i>
                    {/*<i className="icon delete-icon" onClick={() => handleDeleteClick(user.id)}></i>*/}
                  </td>
                </tr>
              );
            })}
          {!users.length && (
            <tr>
              <td colSpan="6">Немає користувачей</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="6">
              {usersTotal > PAGE_SIZE && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={usersTotal}
                  pageSize={PAGE_SIZE}
                  onPageChange={async (page) => {
                    await setCurrentPage(page);
                    await getUsers(page);
                  }}
                />
              )}
            </td>
          </tr>
        </tfoot>
      </table>

      {formActive && (
        <Form
          setFormActive={setFormActive}
          edit={edit}
          setEdit={setEdit}
          saveEditChange={saveEditChange}
          form={form}
          setForm={setForm}
        />
      )}
      {alertActive && (
        <div className="alert-label">
          <div>Ви впевнені, що хочете видалити?</div>
          <div className="alert-label-buttons">
            <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
              Видалити
            </button>
            <button
              className="round-btn-secondary close"
              onClick={() => setAlertActive(false)}
            ></button>
          </div>
        </div>
      )}
    </>
  );
};
