import {
  ORGANISATIONSLIST_SUCCESS,
  ORGANISATIONSDELETE_SUCCESS,
  ORGANISATIONSADD_SUCCESS,
  ORGANISATIONSITEM_SUCCESS,
  HEADEMPLOYEEGETITEM_SUCCESS,
} from './types';

import OrganisationsService from '../services/organisations.service';

export const getOrganisationsList = (data) => (dispatch) => {
  return OrganisationsService.getOrganisationsList(data).then(
    (response) => {
      if (response) {
        dispatch({
          type: ORGANISATIONSLIST_SUCCESS,
          list: response.items,
          total: response.total,
        });
      }

      return (response && response.items) || [];
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    },
  );
};

export const getOrganisationsSubmissionList = (data) => (dispatch) => {
  return OrganisationsService.getOrganisationsSubmissionList(data).then(
    (response) => {
      if (response) {
        dispatch({
          type: ORGANISATIONSLIST_SUCCESS,
          list: response.items,
          total: response.total,
        });
      }
      return (response) || [];
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    },
  );
};


export const getOrganisationsItem = (id) => (dispatch) => {
  return OrganisationsService.getOrganisationsItem(id).then(
    (response) => {
      dispatch({
        type: ORGANISATIONSITEM_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    },
  );
};

export const createOrganisation = (form) => (dispatch) => {
  return OrganisationsService.createOrganisation(form).then((response) => {
    dispatch({
      type: ORGANISATIONSADD_SUCCESS,
      list: response,
    });

    return response;
  });
};

export const getOrganisationsUnconfirmedList = () => (dispatch) => {
  return OrganisationsService.getOrganisationsUnconfirmedList().then(
    (response) => {
      dispatch({
        type: ORGANISATIONSLIST_SUCCESS,
        list: response.items,
        total: response.total,
      });

      return response.items;
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    },
  );
};

export const getHeadEmployee = (data) => (dispatch) => {
  return OrganisationsService.getHeadEmployeeItem(data).then(
    (response) => {
      dispatch({
        type: HEADEMPLOYEEGETITEM_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    },
  );
};
