import {
  NOTIFICATIONGETLIST_SUCCESS,
  NOTIFICATIONGETLIST_FAIL,
  NOTIFICATIONDELETE_SUCCESS,
  NOTIFICATIONDELETE_FAIL,
  NOTIFICATIONGETITEM_SUCCESS,
  NOTIFICATIONGETITEM_FAIL,
  NOTIFICATIONREADED_SUCCESS,
  NOTIFICATIONREADED_FAIL,
} from '../actions/types';

const initialState = [];

export default function applicationsMessageReducer(notification = initialState, action) {
  const { type, list, item, offset, total } = action;
  switch (type) {
    case NOTIFICATIONGETITEM_SUCCESS:
      return { currentNotification: item };
    case NOTIFICATIONGETITEM_FAIL:
      return notification;
    case NOTIFICATIONGETLIST_FAIL:
      return notification;
    case NOTIFICATIONGETLIST_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    case NOTIFICATIONDELETE_SUCCESS:
      return notification;
    case NOTIFICATIONDELETE_FAIL:
      return notification;
    case NOTIFICATIONREADED_SUCCESS:
      return notification;
    case NOTIFICATIONREADED_FAIL:
      return notification;
    default:
      return notification;
  }
}
