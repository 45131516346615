import {
  NOTIFICATIONGETLIST_SUCCESS,
  NOTIFICATIONGETLIST_FAIL,
  NOTIFICATIONDELETE_SUCCESS,
  NOTIFICATIONDELETE_FAIL,
  NOTIFICATIONGETITEM_SUCCESS,
  NOTIFICATIONGETITEM_FAIL,
  NOTIFICATIONREADED_SUCCESS,
  NOTIFICATIONREADED_FAIL,
} from './types';

import NotificationService from '../services/notification.service';

export const getNotificationList = (data, pagination) => (dispatch) => {
  return NotificationService.getNotificationList(data, pagination).then(
    (response) => {
      if (response && response.items) {
        dispatch({
          type: NOTIFICATIONGETLIST_SUCCESS,
          list: response.items,
          total: response.total,
          offset: response.offset,
        });
        return response.items;
      } else {
        return [];
      }
    },
    (error) => {
      dispatch({
        type: NOTIFICATIONGETLIST_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};

export const getNotificationListUnread = (data)=>(dispatch) => {
  return NotificationService.getNotificationListUnread(data).then(
    (response) => {
        return response;
    },
    (error) => {
      dispatch({
        type: NOTIFICATIONGETLIST_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};

export const getNotificationItem = (data) => (dispatch) => {
  return NotificationService.getNotificationItem(data).then(
    (response) => {
      dispatch({
        type: NOTIFICATIONGETITEM_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: NOTIFICATIONGETITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const deleteNotificationItem = (data) => (dispatch) => {
  return NotificationService.deleteNotificationItem(data).then(
    (response) => {
      dispatch({
        type: NOTIFICATIONDELETE_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: NOTIFICATIONDELETE_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};

export const setReadedNotification = (data) => (dispatch) => {
  return NotificationService.setReadedNotification(data).then(
    (response) => {
      dispatch({
        type: NOTIFICATIONREADED_SUCCESS,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: NOTIFICATIONREADED_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};

export const setAllReadedNotification = (data) => (dispatch) => {
  return NotificationService.setAllReadedNotification(data).then(
    (response) => {
      dispatch({
        type: NOTIFICATIONREADED_SUCCESS,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: NOTIFICATIONREADED_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};
