import axios from 'axios';
import authHeader from './auth-header';

import constants from '../../constants/constant.json';


const uploadFile = (data) => {
  // return axios.post(constants.API_URL + `/assets`,data,{ headers: {'Content-Type': 'multipart/form-data'} ,body: JSON.stringify(data)});
  let bodyFormData = new FormData();
  bodyFormData.append('file', data);
  let user = JSON.parse(localStorage.getItem('userData'));

  return axios({
    method: 'post',
    url: constants.API_URL + `/assets`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + user.token  },
  });
};
const uploadInstruction = (data) => {
  let bodyFormData = new FormData();
  bodyFormData.append('file', data);
  let user = JSON.parse(localStorage.getItem('userData'));
  return axios({
    method: 'post',
    url: constants.API_URL + `/instructions/upload`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + user.token },
  });
};

export default {
  uploadFile,
  uploadInstruction,
};
