import React from "react";

const NewLoader = () => {
  return (
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default NewLoader;
