import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SidebarAuth } from './Auth/SidebarAuth';

export const Sidebar = () => {
  const [visible, setVisible] = useState(false);
  const { auth } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);

  const openSidebar = () => {
    document.getElementsByClassName('sidebar')[0].visibility = 'visible';
  };

  return (
    <>
      <div className={visible ? 'sidebar open' : 'sidebar'} onClick={() => setVisible(false)}>
        {!visible && <div className="sidebar-logo"></div>}
        {visible && (
          <div
            className="sidebar-open"
            onClick={() => setVisible(false)}
            style={{ color: 'yellow' }}
          ></div>
        )}
        <NavLink
          className={visible ? 'sidebar-nav open' : 'sidebar-nav'}
          to="/applications"
          activeClassName="selected"
        >
          <i className="sidebar-icon applications-icon"></i>
          Заявки
        </NavLink>
        <NavLink className="sidebar-nav" to="/roster" activeClassName="selected">
          <i className="sidebar-icon roster-icon"></i>
          Реєстр
        </NavLink>
        {
          <NavLink className="sidebar-nav" to="/instructions" activeClassName="selected">
            <i className="sidebar-icon directories-icon"></i>
            Інструкції
          </NavLink>
        }
          {!auth.isLoggedIn && (
          <NavLink className="sidebar-nav" to="/org" activeClassName="selected">
            <i className="sidebar-icon organisations-icon"></i>
            Організації
          </NavLink>
        )}

      { !auth.isLoggedIn && fullList[12]?.length>0 && fullList[12][0]?.isShow &&(
        <NavLink className="sidebar-nav" to={'/matesListPublic'} activeClassName="selected">
          <i className="sidebar-icon mates-icon"></i>
          Співробітники
        </NavLink>
      )}
        {!auth.isLoggedIn && (
          <NavLink className="sidebar-nav" to="/login" activeClassName="selected">
            <i className="sidebar-icon login-icon"></i>
            Авторизація користувача
          </NavLink>
        )}
        {auth.isLoggedIn && <SidebarAuth />}

        <div
          style={{
            color: 'white',
            marginTop: '60px',
            fontSize: '16px',
            fontWeight: 'normal',
            textAlign: 'center',
            lineHeight: '1.5',
          }}
        >
          Підтримка:
          <br />
          <a href='tel:+380891205013' style={{ fontSize: '14px', fontWeight: '500', color:'white' }}>+38 (066) 382 09 04</a>
          <br />
          <a href="mailto:sport.thedigital@gmail.com?subject=Звернення до тех підтримки" style={{ fontSize: '14px', fontWeight: '500', color:'white', textDecoration:'underline' }}>sport.thedigital @gmail.com</a>
          <br />
          Пн-Пт
          <br />
          09:00-18:00
        </div>
        <br />
        {/* <div style={{color:'white', fontSize:'10px', fontWeight:'normal',textAlign:'center'}}>
                <div style={{marginBottom:'20px'}}> Завантажити інструкції:</div>

                <a className='instruction' href="https://title.mms.gov.ua/api/v1/assets/instruction_1.pdf" title="Реєстрація, вхід, відновлення доступу" target="_blank" >
                    1. Реєстрація, вхід
                    </a>
                
                <a  className='instruction'  href="https://title.mms.gov.ua/api/v1/assets/instruction_2.pdf" title="Реєстрація організації, додання співробітників" target="_blank">
                    2. Перші дії
                </a>
                

                <>
                    <a className='instruction' title='Створення та відправка заявки на присвоєння спортивного звання. Обробка повернень, статуси заявки.' href="https://title.mms.gov.ua/api/v1/assets/instruction_3.pdf" target="_blank">
                        3. Cуб'єкт подання
                    </a>
                </>
                <>
                    <a  className='instruction' title='. Структурний підрозділ ОДА з питань фізичної культури та спорту (РегіонСпорт).' href="https://title.mms.gov.ua/api/v1/assets/instruction_4.pdf" target="_blank" >
                       4. Регіон спорт
                    </a>
                </>
            </div> */}
      </div>
      {!visible && <div className="sidebar-open" onClick={() => setVisible(true)}></div>}
    </>
  );
};

export default Sidebar;
