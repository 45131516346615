import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';
import authService from './auth.service';
import { PAGE_SIZE_APPLICATIONS } from '../../constants/const';

const createApplication = (body) => {
  return axios
    .post(constants.API_URL + '/applications', body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const getApplicationItem = (id) => {
  return axios
    .get(constants.API_URL + '/applications/' + id, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const updateApplication = (body) => {
  return axios
    .put(constants.API_URL + `/applications/${body.id}`, { ...body }, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const certificateFilter = (body) => {
  return axios
    .post(constants.API_URL + `/certificates/filters`, body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};



const getApplicationsList = (data, page = 1) => {
  const urlParams = new URLSearchParams();
  const limit = PAGE_SIZE_APPLICATIONS;
  urlParams.append('offset', ((page - 1) * limit).toString());
  urlParams.append('limit', limit.toString());

  if (data) {
    data.forEach((element, index) => {
      if (element.name === 'status') {
        urlParams.append(`filters[${element.name}][]`, element.value);
      } else if (element.sortName && element.sortName.length > 0) {
        urlParams.set(`sort[${element.sortName}]`, element.sortValue);
      } else {
        urlParams.append(`filters[${element.name}]`, element.value);
      }
    });
  }

  return axios
    .get(constants.API_URL + '/applications?' + urlParams.toString(), { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const deleteApplication = (id) => {
  return axios
    .delete(constants.API_URL + '/applications/' + id, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const duplicateApplication = (id) => {
  return axios
    .post(constants.API_URL + `/applications/${id}/duplicate`, {}, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const updateMultipleApplications = (body) => {
  return axios
    .post(constants.API_URL + `/applications/update`, { ...body }, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const generateDigitalApplication = (id) => {
  return axios
    .post(constants.API_URL + `/applications/${id}/generate`, {}, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const getDigitalApplication = (id) => {
  return axios
    .get(constants.API_URL + `/applications/${id}/signature`, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};
const getDigitalApplicationFileType = (body) => {
  return axios
    .post(constants.API_URL + `/documents`,body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const getDigitalApplicationFileTypeNew = (body) => {
  return axios
    .post(constants.API_URL + `/documents/documents-new`,body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const setDigitalApplicationSignatureNew = (body) => {
  return axios
    .post(constants.API_URL + `/documents/signature-new`,body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const setDigitalApplicationSignature = (body) => {
  return axios
    .post(constants.API_URL + `/documents/signature`,body, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};
const setTrainerReview =(body)=>{
  return axios
  .put(constants.API_URL + `/applications/${body.id}/trainer-review`,body, { headers: authHeader() })
  .then((response) => response.data)
  .catch((e) => {
    console.log(e);
  });
}


export default {
  getApplicationsList,
  getApplicationItem,
  createApplication,
  certificateFilter,
  updateApplication,
  deleteApplication,
  duplicateApplication,
  updateMultipleApplications,
  generateDigitalApplication,
  getDigitalApplication,
  getDigitalApplicationFileType,
  setDigitalApplicationSignature,
  setTrainerReview,
  getDigitalApplicationFileTypeNew,
  setDigitalApplicationSignatureNew
};
