import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getHistoryList, rollbackHistory } from '../../../redux/actions/history';
import { selectIsAdmin } from '../../../redux/selectors/auth';

const HistoryOfChange = () => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.history);
  const [ready, setReady] = useState(false);
  const applicationId = useParams().id;
  const fullList = useSelector((state) => state.fullList);
  const isAdmin = useSelector(selectIsAdmin);

  const returnClick = () => {
    let data = { id: applicationId };
    dispatch(rollbackHistory(data)).then((res) => {
      getHisList();
    });
  };

  const getHisList = useCallback(async () => {
    await dispatch(getHistoryList(applicationId))
      .then((res) => {})
      .catch((e) => {});
    setReady(true);
  }, []);

  useEffect(() => {
    getHisList();
  }, []);

  if (ready) {
    return (
      <div className="create-application-container">
        {history &&
          history.length > 0 &&
          history.map((e, index) => {
            if(index== history.length-1){
              return;
            }else{
            return (
              <div key={e.id} className="history-item">
                <div className="history-date">{e.createdAt}</div>

                <div className="history-info">
                  <h5>{e.organisation && e.organisation.name}</h5>
                  {e.employee && e.employee.lastName} {e.employee && e.employee.firstName}{' '}
                  {e.employee && e.employee.secondName}
                  <br />
                  {e.employee && e.employee.isHeadEmployee && 'Керівник'}
                  {e.employee && e.employee.isHeadTrustedEmployee && 'Уповноважена особа'}
                  {e.employee &&
                    !e.employee.isHeadEmployee &&
                    !e.employee.isHeadTrustedEmployee &&
                    'Співробітник'}
                  <br />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#1D1D1B',
                      marginTop: '10px',
                      display: 'block',
                    }}
                  >
                    {fullList &&
                      fullList[4] &&
                      fullList[4].map((el) => {
                        if (el.status === e.status) {
                          return el.title;
                        }
                      })}
                  </span>
                  {/* <span style={{ color: '#1D1D1B' }}> {e.comment && e.comment}</span> */}
                  {e.file?.length>0 && <span>
                    <a href={e.file} className="document-link">
                      Висновок комісії
                    </a>
                  </span>}
                </div>
              </div>
            )};
          })}

        {isAdmin && (
          <button className="icon-text-btn-secondary" onClick={returnClick}>
            Відправити заявку на один статус назад
          </button>
        )}
      </div>
    );
  } else if (!ready) {
    return 'Loading!';
  }
};

export default HistoryOfChange;
