export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const DONE_REFRESHING_TOKEN = 'DONE_REFRESHING_TOKEN';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const LOGOUT = 'LOGOUT';
export const CODE_SUCCESS = 'CODE_SUCCESS';
export const CODE_FAIL = 'CODE_FAIL';
export const RESTORE_SUCCESS = 'RESTORE_SUCCESS';
export const RESTORE_FAIL = 'RESTORE_FAIL';
export const USER_DATA_LOADED = 'USER_DATA_LOADED';
export const USER_ORGANISATION_LOADED = 'USER_ORGANISATION_LOADED';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const RETRIEVE_USERS = 'RETRIEVE_USERS';
export const DELETE_USERS = 'DELETE_USERS';

export const UPLOADFILE_SUCCESS = 'UPLOADFILE_SUCCESS';
export const UPLOADFILE_FAIL = 'UPLOADFILE_FAIL';

export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAIL = 'CATEGORIES_FAIL';

export const DICTIONARIESLIST_SUCCESS = 'DICTIONARIESLIST_SUCCESS';
export const DICTIONARIESLIST_FAIL = ' DICTIONARIESLIST_FAIL';
export const DICTIONARIESDELETE_SUCCESS = 'DICTIONARIESDELETE_SUCCESS';
export const DICTIONARIESDELETE_FAIL = 'DICTIONARIESDELETE_FAIL';
export const DICTIONARIESEDIT_SUCCESS = 'DICTIONARIESEDIT_SUCCESS';
export const DICTIONARIESEDIT_FAIL = 'DICTIONARIESEDIT_FAIL';
export const DICTIONARIESADD_FAIL = 'DICTIONARIESADD_FAIL';
export const DICTIONARIESADD_SUCCESS = 'DICTIONARIESADD_SUCCESS';
export const DICTIONARIESITEM_SUCCESS = 'DICTIONARIESITEM_SUCCESS';
export const DICTIONARIESITEM_FAIL = 'DICTIONARIESITEM_FAIL';
export const DICTIONARIESFULLLIST_SUCCESS = 'DICTIONARIESFULLLIST_SUCCESS';
export const DICTIONARIESFULLLIST_FAIL = 'DICTIONARIESFULLLIST_FAIL';

export const APPLICATIONGETITEM_SUCCESS = 'APPLICATIONGETITEM_SUCCESS';
export const APPLICATIONGETLIST_SUCCESS = 'APPLICATIONGETLIST_SUCCESS';

export const COVERGETLIST_SUCCESS = 'COVERGETLIST_SUCCESS';
export const COVERGETLIST_FAIL = 'COVERGETLIST_FAIL';
export const COVERCREATE_SUCCESS = 'COVERCREATE_SUCCESS';
export const COVERCREATE_FAIL = 'COVERCREATE_FAIL';
export const COVERDELETE_SUCCESS = 'COVERDELETE_SUCCESS';
export const COVERDELETE_FAIL = 'COVERDELETE_FAIL';
export const COVERUPDATE_SUCCESS = 'COVERUPDATE_SUCCESS';
export const COVERUPDATE_FAIL = 'COVERUPDATE_FAIL';
export const COVERGETITEM_SUCCESS = 'COVERGETITEM_SUCCESS';
export const COVERGETITEM_FAIL = 'COVERGETITEM_FAIL';

export const APPLICATIONMESSAGEGETLIST_SUCCESS = 'APPLICATIONMESSAGEGETLIST_SUCCESS';
export const APPLICATIONMESSAGEGETLIST_FAIL = 'APPLICATIONMESSAGEGETLIST_FAIL';
export const APPLICATIONMESSAGECREATE_SUCCESS = 'APPLICATIONMESSAGECREATE_SUCCESS';
export const APPLICATIONMESSAGECREATE_FAIL = 'APPLICATIONMESSAGECREATE_FAIL';
export const APPLICATIONMESSAGEDELETE_SUCCESS = 'APPLICATIONMESSAGEDELETE_SUCCESS';
export const APPLICATIONMESSAGEDELETE_FAIL = 'APPLICATIONMESSAGEDELETE_FAIL';
export const APPLICATIONMESSAGEGETITEM_SUCCESS = 'APPLICATIONMESSAGEGETITEM_SUCCESS';
export const APPLICATIONMESSAGEGETITEM_FAIL = 'APPLICATIONMESSAGEGETITEM_FAIL';

export const ORGANISATIONSLIST_SUCCESS = 'ORGANISATIONSLIST_SUCCESS';
export const ORGANISATIONSDELETE_SUCCESS = 'ORGANISATIONSDELETE_SUCCESS';
export const ORGANISATIONSADD_SUCCESS = 'ORGANISATIONSADD_SUCCESS';
export const ORGANISATIONSITEM_SUCCESS = 'ORGANISATIONSITEM_SUCCESS';
export const HEADEMPLOYEEGETITEM_SUCCESS = 'HEADEMPLOYEEGETITEM_SUCCESS';

export const DEPARTMENTSLIST_FAIL = 'DEPARTMENTSLIST_FAIL';
export const DEPARTMENTSLIST_SUCCESS = 'DEPARTMENTSLIST_SUCCESS';
export const DEPARTMENTSITEM_FAIL = 'DEPARTMENTSITEM_FAIL';
export const DEPARTMENTSITEM_SUCCESS = 'DEPARTMENTSITEM_SUCCESS';
export const DEPARTMENTSEDIT_SUCCESS = 'DEPARTMENTSEDIT_SUCCESS';
export const DEPARTMENTSDELETE_FAIL = 'DEPARTMENTSDELETE_FAIL';
export const DEPARTMENTSDELETE_SUCCESS = 'DEPARTMENTSDELETE_SUCCESS';
export const DEPARTMENTSEDIT_FAIL = 'DEPARTMENTSEDIT_FAIL';
export const DEPARTMENTSADD_FAIL = 'DEPARTMENTSADD_FAIL';
export const DEPARTMENTSADD_SUCCESS = 'DEPARTMENTSADD_SUCCESS';

export const MATESLIST_FAIL = 'MATESLIST_FAIL';
export const MATESLIST_SUCCESS = 'MATESLIST_SUCCESS';
export const MATESDELETE_SUCCESS = 'MATESDELETE_SUCCESS';
export const MATESITEM_FAIL = 'MATESITEM_FAIL';
export const MATESITEM_SUCCESS = 'MATESITEM_SUCCESS';
export const MATESDELETE_FAIL = 'MATESDELETE_FAIL';
export const MATESEDIT_SUCCESS = 'MATESEDIT_SUCCESS';
export const MATESEDIT_FAIL = 'MATESEDIT_FAIL';
export const MATESADD_FAIL = 'MATESADD_FAIL';
export const MATESADD_SUCCESS = 'MATESADD_SUCCESS';

export const DOCUMENTSGETITEM_SUCCESS = 'DOCUMENTSGETITEM_SUCCESS';
export const DOCUMENTSGETITEM_FAIL = 'DOCUMENTSGETITEM_FAIL';
export const DOCUMENTSGETLIST_FAIL = 'DOCUMENTSGETLIST_FAIL';
export const DOCUMENTSGETLIST_SUCCESS = 'DOCUMENTSGETLIST_SUCCESS';
export const DOCUMENTSDELETE_SUCCESS = 'DOCUMENTSDELETE_SUCCESS';
export const DOCUMENTSDELETE_FAIL = 'DOCUMENTSDELETE_FAIL';
export const DOCUMENTSCREATE_FAIL = 'DOCUMENTSCREATE_FAIL';
export const DOCUMENTSCREATE_SUCCESS = 'DOCUMENTSCREATE_SUCCESS';

export const COVERDOCUMENTSGETITEM_SUCCESS = 'COVERDOCUMENTSGETITEM_SUCCESS';
export const COVERDOCUMENTSGETITEM_FAIL = 'COVERDOCUMENTSGETITEM_FAIL';
export const COVERDOCUMENTSGETLIST_FAIL = 'COVERDOCUMENTSGETLIST_FAIL';
export const COVERDOCUMENTSGETLIST_SUCCESS = 'COVERDOCUMENTSGETLIST_SUCCESS';
export const COVERDOCUMENTSDELETE_SUCCESS = 'COVERDOCUMENTSDELETE_SUCCESS';
export const COVERDOCUMENTSDELETE_FAIL = 'COVERDOCUMENTSDELETE_FAIL';
export const COVERDOCUMENTSCREATE_FAIL = 'COVERDOCUMENTSCREATE_FAIL';
export const COVERDOCUMENTSCREATE_SUCCESS = 'COVERDOCUMENTSCREATE_SUCCESS';

export const HISTORYCREATE_SUCCESS = 'HISTORYCREATE_SUCCESS';
export const HISTORYCREATE_FAIL = 'HISTORYCREATE_FAIL';
export const HISTORYLIST_SUCCESS = 'HISTORYLIST_SUCCESS';
export const HISTORYLIST_FAIL = 'HISTORYLIST_FAIL';
export const HISTORYITEM_SUCCESS = 'HISTORYITEM_SUCCESS';
export const HISTORYITEM_FAIL = 'HISTORYITEM_FAIL';
export const HISTORYROLLBACK_SUCCESS = 'HISTORYROLLBACK_SUCCESS';
export const HISTORYROLLBACK_FAIL = 'HISTORYROLLBACK_FAIL';

export const COVERHISTORYCREATE_SUCCESS = 'COVERHISTORYCREATE_SUCCESS';
export const COVERHISTORYCREATE_FAIL = 'COVERHISTORYCREATE_FAIL';
export const COVERHISTORYLIST_SUCCESS = 'COVERHISTORYLIST_SUCCESS';
export const COVERHISTORYLIST_FAIL = 'COVERHISTORYLIST_FAIL';
export const COVERHISTORYITEM_SUCCESS = 'COVERHISTORYITEM_SUCCESS';
export const COVERHISTORYITEM_FAIL = 'COVERHISTORYITEM_FAIL';
export const COVERHISTORYROLLBACK_SUCCESS = 'COVERHISTORYROLLBACK_SUCCESS';
export const COVERHISTORYROLLBACK_FAIL = 'COVERHISTORYROLLBACK_FAIL';

export const NOTIFICATIONGETLIST_SUCCESS = 'NOTIFICATIONGETLIST_SUCCESS';
export const NOTIFICATIONGETLIST_FAIL = 'NOTIFICATIONGETLIST_FAIL';
export const NOTIFICATIONDELETE_SUCCESS = 'NOTIFICATIONDELETE_SUCCESS';
export const NOTIFICATIONDELETE_FAIL = 'NOTIFICATIONDELETE_FAIL';
export const NOTIFICATIONGETITEM_SUCCESS = 'NOTIFICATIONGETITEM_SUCCESS';
export const NOTIFICATIONGETITEM_FAIL = 'NOTIFICATIONGETITEM_FAIL';
export const NOTIFICATIONREADED_SUCCESS = 'NOTIFICATIONREADED_SUCCESS';
export const NOTIFICATIONREADED_FAIL = 'NOTIFICATIONREADED_FAIL';

export const CERTIFICATEGETLIST_SUCCESS = 'CERTIFICATEGETLIST_SUCCESS';
export const CERTIFICATEGETITEM_SUCCESS = 'CERTIFICATEGETITEM_SUCCESS';
export const CERTIFICATEUNIQUEGETITEM_SUCCESS = 'CERTIFICATEUNIQUEGETITEM_SUCCESS';
export const CERTIFICATEGETREPLIST_SUCCESS = 'CERTIFICATEGETREPLIST_SUCCESS';



export const CONFIGURATIONGETLIST_SUCCESS = 'CONFIGURATIONGETLIST_SUCCESS';
export const CONFIGURATIONGETLIST_FAIL = 'CONFIGURATIONGETLIST_FAIL';
export const CONFIGURATIONUPDATE_SUCCESS = 'CONFIGURATIONUPDATE_SUCCESS';
export const CONFIGURATIONUPDATE_FAIL = 'CONFIGURATIONUPDATE_FAIL';

export const INSTRUCTIONSLIST_SUCCESS = 'INSTRUCTIONSLIST_SUCCESS';
export const INSTRUCTIONSLIST_FAIL = 'INSTRUCTIONSLIST_FAIL';
export const INSTRUCTIONSDELETE_SUCCESS = 'INSTRUCTIONSDELETE_SUCCESS';
export const INSTRUCTIONSDELETE_FAIL = 'INSTRUCTIONSDELETE_FAIL';
export const INSTRUCTIONSEDIT_SUCCESS = 'INSTRUCTIONSEDIT_SUCCESS';
export const INSTRUCTIONSEDIT_FAIL = 'INSTRUCTIONSEDIT_FAIL';
export const INSTRUCTIONSADD_SUCCESS = 'INSTRUCTIONSADD_SUCCESS';
export const INSTRUCTIONSADD_FAIL = 'INSTRUCTIONSADD_FAIL';

export const ASSIGNGETLIST_SUCCESS = 'ASSIGNGETLIST_SUCCESS';
export const LISTID_SUCCESS = 'LISTID_SUCCESS';

export const SUPPORTGETLIST_SUCCESS = 'SUPPORTGETLIST_SUCCESS';
export const SUPPORTGETLIST_FAIL = 'SUPPORTGETLIST_FAIL';
export const SUPPORTDELETE_SUCCESS = 'SUPPORTDELETE_SUCCESS';
export const SUPPORTDELETE_FAIL = 'SUPPORTDELETE_FAIL';
export const SUPPORTGETITEM_SUCCESS = 'SUPPORTGETITEM_SUCCESS';
export const SUPPORTGETITEM_FAIL = 'SUPPORTGETITEM_FAIL';
export const SUPPORTREADED_SUCCESS = 'SUPPORTREADED_SUCCESS';
export const SUPPORTREADED_FAIL = 'SUPPORTREADED_FAIL';

