import { CATEGORIES_FAIL, CATEGORIES_SUCCESS } from '../actions/types';

const initialState = [];

export default function categoriesReducer(category = initialState, action) {
  const { type, categories, list } = action;

  switch (type) {
    case CATEGORIES_SUCCESS:
      return [...categories];
    case CATEGORIES_FAIL:
      return category;
    default:
      return category;
  }
}
