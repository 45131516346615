import {
  MATESLIST_FAIL,
  MATESLIST_SUCCESS,
  MATESDELETE_SUCCESS,
  MATESITEM_FAIL,
  MATESITEM_SUCCESS,
  MATESDELETE_FAIL,
  MATESEDIT_SUCCESS,
  MATESEDIT_FAIL,
  MATESADD_FAIL,
  MATESADD_SUCCESS,
} from './types';

import MatesService from '../services/mates.service';

export const getMatesList = (data, search, page) => (dispatch) => {
  return MatesService.getMatesList(data, search, page).then((response) => {
    dispatch({
      type: MATESLIST_SUCCESS,
      list: response.items,
      total: response.total,
      offset: response.offset,
    });
    return response;
  });
};
export const getMatesItem = (data) => (dispatch) => {
  return MatesService.getMatesItem(data).then(
    (response) => {
      dispatch({
        type: MATESITEM_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: MATESITEM_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};
export const deleteMatesItem = (data) => (dispatch) => {
  return MatesService.deleteMatesItem(data).then(
    (response) => {
      dispatch({
        type: MATESDELETE_SUCCESS,
      });
    },
    (error) => {
      dispatch({
        type: MATESDELETE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
export const editMatesItem = (form) => (dispatch) => {
  return MatesService.editMatesItem(form).then(
    (response) => {
      dispatch({
        type: MATESEDIT_SUCCESS,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: MATESEDIT_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const addMatesItem = (form) => (dispatch) => {
  return MatesService.addMatesItem(form).then(
    (response) => {
      dispatch({
        type: MATESADD_SUCCESS,
        list: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: MATESADD_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getMatesUnconfirmedList = (data, search, page) => (dispatch) => {
  return MatesService.getMatesUnconfirmedList(data, search, page).then((response) => {
    dispatch({
      type: MATESLIST_SUCCESS,
      list: response.items,
      total: response.total,
      offset: response.offset,
    });
    return response.items;
  });
};
