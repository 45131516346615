import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const addApplicationCommentItem = (body) => {
  return axios
    .post(constants.API_URL + `/applications/${body.id}/messages`, body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getApplicationCommentItem = (body) => {
  return axios
    .get(constants.API_URL + `/applications/${body.id}/messages/${body.itemId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const deleteApplicationCommentItem = (body) => {
  return axios
    .delete(constants.API_URL + `/applications/${body.id}/messages/${body.itemId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getApplicationsCommentList = (data, pagination = 1) => {
  let filter = `?offset=${(pagination - 1) * 10}&limit=120&sort[createdAt]=desc`;

  return axios
    .get(constants.API_URL + `/applications/${data.id}/messages` + filter, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  addApplicationCommentItem,
  getApplicationCommentItem,
  deleteApplicationCommentItem,
  getApplicationsCommentList,
};
