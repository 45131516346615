import React from 'react';
import { Redirect } from 'react-router-dom';

const SCALE = 0.75;
const FONT_SIZE = '25px';
const FONT_FAMILY = '"Times New Roman"';

class Letter extends React.Component {
  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
    if (!this.props.currentOrganisation) {
      return <Redirect to={'/applications'} />;
    }

    const { currentApplication } = this.props;

    const athleteCategoryTitle = currentApplication.athleteCategoryTitle.split('/')[0];
    const sportTitle = currentApplication.sportTitleGenitive || currentApplication.sportTitle;
    const athleteLastName =
      currentApplication.athleteLastNameDative || currentApplication.athleteLastName;
    const athleteFirstName =
      currentApplication.athleteFirstNameDative || currentApplication.athleteFirstName;
    const athleteSecondName =
      currentApplication.athleteSecondNameDative || currentApplication.athleteSecondName;

    return (
      <>
        <div
          style={{
            margin: '80px auto 0',
            fontFamily: FONT_FAMILY,
            gridArea: 'article',
            width: '794px',
            transform: `scale(` + SCALE + ')',
          }}
        >
          <p
            style={{
              textAlign: 'left',
              marginLeft: 'auto',
              // marginBottom: '10px',
              width: '360px',
              fontFamily: FONT_FAMILY,
              fontSize: FONT_SIZE,
            }}
          >
            Міністерство молоді та спорту України
          </p>
          <br />
          <p
            style={{
              textAlign: 'left',
              width: '270px',
              marginBottom: '60px',
              fontFamily: FONT_FAMILY,
              fontSize: FONT_SIZE,
            }}
          >
            Про присвоєння <br /> спортивного звання
          </p>
          <p
            style={{
              marginBottom: '25px',
              fontSize: FONT_SIZE,
              fontFamily: FONT_FAMILY,
              textIndent: '40px',
              lineHeight: '35px',
            }}
          >
            {this.props.currentOrganisation.name} порушує клопотання щодо присвоєння спортивного
            звання «{athleteCategoryTitle}» з {sportTitle}{' '}
            <b>
              {athleteLastName} {athleteFirstName} {athleteSecondName}
            </b>
            .
          </p>
          <p
            style={{
              lineHeight: '35px',
              textIndent: '40px',
              fontSize: FONT_SIZE,
              fontFamily: FONT_FAMILY,
            }}
          >
            Перелік документів що додається:
            <ul style={{ listStyle: 'disc', marginLeft: '60px', textIndent: '0' }}>
              <li>Подання;</li>
              <li>Ксерокопія паспорту(1, 2 ст.);</li>
              <li>Ксерокопія протоколу змагань;</li>
              <li>Фото 3 Х 4 (2 шт.).</li>
            </ul>
          </p>
          <p
            style={{
              marginTop: '40px',
              lineHeight: '35px',
              textIndent: '40px',
              fontSize: FONT_SIZE,
              fontFamily: FONT_FAMILY,
            }}
          >
            Документи на присвоєння даного спортивного звання подано в інформаційній системі «Онлайн
            сервіс для переведення у публічну площину процесу присвоєння спортивних звань з видів
            спорту, офіційно визнаних в Україні».
          </p>
          <h2
            style={{
              marginTop: '120px',
              display: 'inline-block',
              fontSize: FONT_SIZE,
              fontFamily: FONT_FAMILY,
            }}
          >
            Начальник управління
          </h2>
          <h2
            style={{
              marginTop: '120px',
              display: 'inline-block',
              float: 'right',
              fontSize: FONT_SIZE,
              fontFamily: FONT_FAMILY,
            }}
          >
            {this.props.headEmployee &&
            this.props.headEmployee.lastName &&
            this.props.headEmployee.firstName ? (
              <>
                {this.props.headEmployee.firstName} {this.props.headEmployee.lastName.toUpperCase()}{' '}
              </>
            ) : (
              '______________________'
            )}
          </h2>
        </div>
      </>
    );
  }
}

export default Letter;
