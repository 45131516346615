import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const addDocument = (body) => {
  return axios
    .post(constants.API_URL + `/applications/${body.applicationId}/documents`, body, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getDocument = (body) => {
  return axios
    .get(constants.API_URL + `/applications/${body.applicationId}/documents/${body.id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const deleteDocument = (body) => {
  return axios
    .delete(constants.API_URL + `/applications/${body.applicationId}/documents/${body.id}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};
const getDocumentsList = async (body) => {
  const response = await axios.get(
    constants.API_URL + `/applications/${body}/documents?offset=0&limit=50`,
    {
      headers: authHeader(),
    },
  );
  return response.data;
};

const regenerateDigitalProtocol = async(body)=>{
  const response = await axios.post(
    constants.API_URL + `/test/regenerate-digitals-protocol`,
    body,
    {
      headers: authHeader(),
    },
  );
  return response.data;
}
export default {
  getDocumentsList,
  deleteDocument,
  getDocument,
  addDocument,
  regenerateDigitalProtocol,
};
