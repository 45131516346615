import {
  COVERGETLIST_SUCCESS,
  COVERGETLIST_FAIL,
  COVERCREATE_SUCCESS,
  COVERCREATE_FAIL,
  COVERDELETE_SUCCESS,
  COVERDELETE_FAIL,
  COVERUPDATE_SUCCESS,
  COVERUPDATE_FAIL,
  COVERGETITEM_SUCCESS,
  COVERGETITEM_FAIL,
} from '../actions/types';

const initialState = [];

export default function coverReducer(cover = initialState, action) {
  const { type, list, item, offset, total } = action;

  switch (type) {
    case COVERGETITEM_SUCCESS:
      return { currentCover: item };
    case COVERGETITEM_FAIL:
      return cover;
    case COVERGETLIST_FAIL:
      return cover;
    case COVERGETLIST_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    case COVERUPDATE_SUCCESS:
      return cover;
    case COVERUPDATE_FAIL:
      return cover;
    case COVERCREATE_FAIL:
      return cover;
    case COVERDELETE_SUCCESS:
      return cover;
    case COVERDELETE_FAIL:
      return cover;
    case COVERCREATE_SUCCESS:
      return [{ ...cover, item }];
    default:
      return cover;
  }
}
