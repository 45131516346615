import React, { useState,useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Avatar from "../../../components/Avatar/Avatar";
import { uploadFile } from "../../../redux/actions/file";
import { addMessageSupportRoom, getSupportItem, uploadSupport } from "../../../redux/actions/support";
import { selectCurrentUserId, selectIsAdmin } from '../../../redux/selectors/auth';
import documentsService from "../../../redux/services/documents.service";


const SupportItemPage=()=>{
    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const currentUserId = useSelector(selectCurrentUserId);
    const {list} = useSelector(state=>state);
    const [search, setSearch] = useState('');
    const [ready, setReady] = useState(false);
    const [form, setForm] = useState({});
    const currentId = useParams().id;
    const [file, setFile] = useState(null);
    const history = useHistory();

    const changeHandler = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value });
    };

    const onDocumentUpload = useCallback(async (event) => {
      if (event.target.files[0]) {
        await dispatch(uploadSupport(event.target.files[0]))
          .then((response) => {
            const data = {
              idRoom: currentId,
              name: event.target.files[0].name,
              url: response,
            };
            setFile(data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }, []);

    const addCommentSave = useCallback(
      async (e) => {
        e.preventDefault();
        const data = {  };
        data.message=form.message;
        data.id=currentId;
        if (file) {
          try {
            // const document = await documentsService.addDocument(file);
            // if (document) {
              data.url = file.url;
              data.name = file.name;
            // }
          } catch (e) {
            console.log(e);
          }
        }
        await dispatch(addMessageSupportRoom(data))
          .then(() => {
            setForm({ ...form, ['message']: '' });
            setFile(null);
            initialFunction();
          })
          .catch((e) => {
            console.log(e);
          });
      },
      [form, file],
    );

    const backClick = () => {
      history.push(`/support`);
      };

    const initialFunction = () => {
        let data={};
        data.id=currentId;

        dispatch(getSupportItem(data)).then(res=>{
          setForm(res);
          setReady(true);
        }).catch(e=>{
          setReady(true);
          console.log(e);
        })

      };

      useEffect(() => {
        if (!ready) {
          initialFunction();
        }
      }, [ready]);

      console.log(list);

    return(
     <div className="main auth-page">
        <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
        {currentUserId && (
          <div className="auth-menu">
            <Avatar />
          </div>
        )}
        {ready && <>
       
        <h1 className="public-header">{list[form.items?.topic]||'Інше'}</h1>
          <div className="create-app btn-tab">
            <button className="icon-text-btn-secondary back" onClick={backClick}>
              Назад
            </button>
          </div>

      <div className="create-application-container">
        {form &&
          (!form?.items?.messagesRoom || form?.items?.messagesRoom.length === 0) && (
            <div style={{ marginBottom: '40px' }}>Жодного коментаря ще не додано</div>
          )}
          {form?.items?.messagesRoom &&
          form?.items?.messagesRoom.length > 0 &&
          form?.items?.messagesRoom.map((el) => {
            return (
              <div className="comment-item" id={el.id} key={el.id}>
                {el.sender && !el.sender.picture && (
                  <button className="avatar">
                    {el.sender?.lastName.charAt(0)}
                    {el.sender?.firstName.charAt(0)}
                  </button>
                )}
                {el.sender && el.sender.picture && (
                  <img src={el.sender.picture} className="avatar avatar-picture"></img>
                )}
                <div className="comment-item-text" style={{ position: 'relative' }}>
                  <h5>
                    <span>
                      {el.sender?.lastName} {el.sender?.firstName}{' '}
                    </span>
                    {el.updatedAt}
                    {el.isRead && (
                      <i className="readed-icon icon" style={{ top: '-10px', right: '-45px' }}></i>
                    )}
                  </h5>
                  {/* <label>{el.sender.name}</label> */}
                  <div>
                    {' '}
                    <p>{el.message}</p>
                    {el.nameDocument && el.urlDocument && (
                      <a
                        target="_blank"
                        href={el.urlDocument}
                        className="document-link"
                        style={{ display: 'block' }}
                      >
                        {el.nameDocument}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="add-comment" style={{ alignItems: 'start', paddingBottom: '10px' }}>
            <form onSubmit={addCommentSave}>
              <textarea
                type="text"
                name="message"
                onChange={changeHandler}
                style={{minWidth:'300px', minHeight:'50px'}}
                value={form.message || ''}
                placeholder="Повідомлення"
              />
              <button
                type="submit"
                disabled={!form.message && !file}
                style={{ width: '120px', marginLeft: '10px' }}
                className="icon-text-btn-primary btn-primary"
              >
                Відправити
              </button>
              <label
                className="icon-text-btn-secondary dow"
                htmlFor="15"
                style={{ display: 'block' }}
              >
                Завантажити документ
                <div className="document-type-hint">
                  Допустимі формати файлів ".pdf", ".jpg", ".jpeg", ".png"
                </div>
              </label>
              <input
                accept="application/pdf,.jpg,.jpeg,.png"
                type="file"
                id="15"
                name="15"
                className="documentInput"
                onChange={onDocumentUpload}
              />
              {file && (
                <a
                  target="_blank"
                  href={file.url}
                  key={file.url}
                  className="document-link"
                  style={{ display: 'block' }}
                >
                  {file.name}
                </a>
              )}
            </form>
          </div>
       
      </div>
    </>}

    </div>
    )
}

export default SupportItemPage;