import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createDocument, deleteDocument } from '../../../redux/actions/documents';
import { uploadFile } from '../../../redux/actions/file';
import Form from '../../DirectoriesPage/Form/Form';
import ApplicationService from '../../../redux/services/application.service';
import { selectCurrentOrganisationId, selectCurrentOrganisation } from '../../../redux/selectors/auth';

const SPORT_CATEGORY_INVASPORT = 99;

const Application = (props) => {
  const dispatch = useDispatch();
  const applicationId = useParams().id;
  const [value, setValue] = useState({});
  const [formActive, setFormActive] = useState(false);
  const [judgeEdit, setJudgeEdit] = useState(false);
  const { fullList } = useSelector((state) => state);
  const { list } = useSelector((state) => state);
  const currentOrganisation = useSelector(selectCurrentOrganisation);

  
  const documents = useSelector((state) => state.documents);

  const applicationReady =
    (props.application.status !== 0 && props.application.status !== 1) ||
    !props.isSubmissionSubject;

  const sportCategoryChange = (event) => {
    const { id, value, title } = event.target.selectedOptions[0].dataset;
    let type = parseInt(value);
    if (isNaN(type)) {
      type = null;
    }

    if (type === SPORT_CATEGORY_INVASPORT) {
      props.setForm({
        ...props.form,
        sportCategoryId: null,
        sportCategoryType: type,
        sportCategoryTitle: null,
        sportId: null,
        sportTitle: null,
      });
    } else {
      props.setForm({
        ...props.form,
        sportCategoryId: id || null,
        sportCategoryType: type,
        sportCategoryTitle: title || null,
        sportId: null,
        sportTitle: null,
      });
    }
  };

  const selectChangeItem = (event) => {
    if (event.target.selectedOptions[0].slot && event.target.selectedOptions[0].accessKey) {
      let obj = { ...props.form };
      obj.athleteCategoryTitleId = event.target.selectedOptions[0].id;
      obj.athleteCategoryTitle = event.target.selectedOptions[0].slot;
      props.setForm(obj);
    } else if (event.target.selectedOptions[0].slot) {
      props.setForm({ ...props.form, [event.target.name]: event.target.selectedOptions[0].slot });
    } else {
      props.setForm({ ...props.form, [event.target.name]: event.target.selectedOptions[0].value });
    }
  };

  const selectChangeItemRegion = (event)=>{
    if (event.target.selectedOptions[0].slot && event.target.selectedOptions[0].accessKey) {
      props.setForm({ ...props.form, [event.target.name]: event.target.selectedOptions[0].accessKey });
    }
  }

  const sportChange = (event) => {
    props.setForm({
      ...props.form,
      sportId: event.target.selectedOptions[0].value,
      sportTitle: event.target.selectedOptions[0].title,
    });
  };

  const sexChange=(event)=>{
    if(event.target.name==='codeType'){
      let data= props.form;
      data.codeType=event.target.value;
      data.code='';
      props.setForm({...data});
    }else{
      props.setForm({ ...props.form, [event.target.name]: event.target.value });
    }
  }
  const editClick = (e) => {
    setValue(e.target.id);
    setJudgeEdit(true);
    setFormActive(true);
  };

  const editClickAchievements = (e) => {
    setValue(e.target.id);
    props.setForm({ ...props.form, ['index']: e.target.accessKey });
    setFormActive(true);
  };

  const deleteClickAchievements = (elementIndex) => {
    props.form.achievements.splice(elementIndex, 1);
    onBlurOut();
  };

  // const saveAddChange = useCallback(
  //   async (event) => {
  //     if (event) {
  //       props.setForm(event);
  //       setFormActive(false);
  //       onBlurOut(event);
  //     } else {
  //       setFormActive(false);
  //       onBlurOut();
  //     }
  //   },
  //   [props.setForm, props.form],
  // );

  const saveAddChange = async (event) => {
      if (event) {
        props.setForm(event);
        setFormActive(false);
        onBlurOut(event);
      } else {
        setFormActive(false);
        onBlurOut();
      }
    };

  // const loadFile = useCallback(
  //   async (event) => {
  //     var image = document.getElementById('output');
  //     if (event.target.files[0]) {
  //       image.src = 'https://i.gifer.com/74H8.gif';
  //       await dispatch(uploadFile(event.target.files[0]))
  //         .then(async (response) => {
  //           // item.picture = response;
  //           image.src = response;
  //           // saveChange();
  //           let data = {
  //             applicationId: applicationId,
  //             type: 5,
  //             name: event.target.files[0].name,
  //             url: response,
  //           };
  //           if (documents) {
  //             documents.forEach((el) => {
  //               if (el.type === 5) {
  //                 let obj = { applicationId: applicationId, id: el.id };
  //                 dispatch(deleteDocument(obj));
  //               }
  //             });
  //           }

  //           await dispatch(createDocument(data))
  //             .then(() => {
  //               props.fetchDocuments();
  //             })
  //             .catch((e) => {
  //               console.log(e);
  //             });
  //         })
  //         .catch(() => {});
  //     } else {
  //       // image.src = item.picture;
  //     }
  //   },
  //   [applicationId, documents],
  // );
  const loadFile = 
    async (event) => {
      var image = document.getElementById('output');
      if (event.target.files[0]) {
        image.src = 'https://i.gifer.com/74H8.gif';
        await dispatch(uploadFile(event.target.files[0]))
          .then(async (response) => {
            // item.picture = response;
            image.src = response;
            // saveChange();
            let data = {
              applicationId: applicationId,
              type: 5,
              name: event.target.files[0].name,
              url: response,
            };
            if (documents) {
              documents.forEach((el) => {
                if (el.type === 5) {
                  let obj = { applicationId: applicationId, id: el.id };
                  dispatch(deleteDocument(obj));
                }
              });
            }

            await dispatch(createDocument(data))
              .then(() => {
                props.fetchDocuments();
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch(() => {});
      } else {
        // image.src = item.picture;
      }
    };

  // const onBlurOut = useCallback(
  //   async (e) => {
  //     const hasKeys = !!Object.keys(props.error).length;
  //     if (props.error && hasKeys) {
  //       props.validateApplication();
  //     }
  //     if (e && (e.status || e.status === 0)) {
  //       await ApplicationService.updateApplication(e);
  //     } else {
  //       await ApplicationService.updateApplication(props.form);
  //     }
  //     props.fetchApplication();
  //   },
  //   [props.form],
  // );

  const onBlurOut =
    async (e) => {
      const hasKeys = !!Object.keys(props.error).length;
      if (props.error && hasKeys) {
        props.validateApplication();
      }
      if (e && (e.status || e.status === 0)) {
        await ApplicationService.updateApplication(e);
      } else {
        await ApplicationService.updateApplication(props.form);
      }
      // props.fetchApplication();
    };

  const changeHandler = (event) => {
      if(event.target.name=='code'&& event.target.value?.length==8 && props.form?.code?.length!==9 && props.form.codeType=='0'){
        event.target.value=event.target.value+'-';
      }
      if(event.target.name=='code'&& event.target.value?.length==2 && props.form?.code?.length!==3 && props.form.codeType=='2'){
        event.target.value=event.target.value+' ';
      }
      if (event.target.name.includes('LastName')) {
        event.target.value = event.target.value.toUpperCase();
      }
      props.setForm({ ...props.form, [event.target.name]: event.target.value });
  };

  const changeHandlerCheck =(event)=>{
    if(props.form.codeType=='1' && event.target.id=='codeType'){
      let data= props.form;
      data.codeType='2';
      data.code='';
      props.setForm({...data});
    }
    else{
      props.setForm({ ...props.form, [event.target.id]: event.target.checked});
    }
  }

  const sportTypes =
    (fullList && fullList[1] && fullList[1].filter((i) => i.id === props.form.sportCategoryId)) ||
    [];
  
  return (
    <div className="create-application-container">
      <div className="create-application-cover">
        <div className="create-application">
          <h2>Основні дані</h2>
          <label className="create-label" htmlFor="">
            <span>№ реєстрації заявки</span>
            <input
              disabled={applicationReady}
              type="text"
              value={props.form.officeCode ? props.form.officeCode : 'заповнюється автоматично'}
              onChange={changeHandler}
            />
          </label>
          <label htmlFor="">
            <span className="imp">Категорія виду спорту</span>
            <select
              name="sportCategory"
              disabled={applicationReady}
              onChange={sportCategoryChange}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && props.form.sportCategoryType === null ? 'red' : ''
                }`,
              }}
            >
              <option value={null}></option>
              {fullList &&
                fullList[1] &&
                fullList[1].map((sportCategory) => {
                  if (sportCategory.type < 2) {
                    return (
                      <option
                        selected={props.form.sportCategoryType === sportCategory.type}
                        key={sportCategory.id}
                        value={sportCategory.id}
                        data-value={sportCategory.type}
                        data-id={sportCategory.id}
                        data-title={sportCategory.title}
                      >
                        {sportCategory.title}
                      </option>
                    );
                  }
                })}
              <option
                selected={props.form.sportCategoryType > 1}
                value={SPORT_CATEGORY_INVASPORT}
                data-value={SPORT_CATEGORY_INVASPORT}
              >
                Укр центр "Інваспорт"
              </option>
            </select>
          </label>

          {props.form.sportCategoryType > 1 && (
            <label htmlFor="">
              <span>Нозологія</span>
              <select
                name="sportCategory"
                disabled={applicationReady}
                onChange={sportCategoryChange}
                onBlur={onBlurOut}
                title={props.form.sportCategoryTitle}
                style={{
                  background: `${
                    props.error.input && props.form.sportCategoryType === SPORT_CATEGORY_INVASPORT
                      ? 'red'
                      : ''
                  }`,
                }}
              >
                <option></option>
                {fullList &&
                  fullList[1] &&
                  fullList[1].map((elem, index) => {
                    if (elem.type === 2) {
                      return (
                        <option
                          selected={props.form && props.form.sportCategoryId === elem.id}
                          key={elem.id}
                          value={elem.id}
                          data-id={elem.id}
                          data-value={elem.type}
                          data-title={elem.title}
                        >
                          {elem.title}
                        </option>
                      );
                    }
                  })}
              </select>
            </label>
          )}

          {props.form.sportCategoryType !== null &&
            props.form.sportCategoryType !== SPORT_CATEGORY_INVASPORT && (
              <label htmlFor="">
                <span className="imp">Вид спорту</span>
                <select
                  name="sportId"
                  disabled={applicationReady}
                  onChange={sportChange}
                  onBlur={onBlurOut}
                  value={props.form && props.form.sportId}
                  style={{
                    background: `${props.error.input && !props.form.sportId ? 'red' : ''}`,
                  }}
                >
                  <option></option>
                  {sportTypes[0] &&
                    sportTypes[0].sports.map((elem) => {
                      return (
                        <option title={elem.title} value={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    })}
                </select>
              </label>
            )}
          {typeof props.form.sportCategoryType !== 'number' && (
            <label htmlFor="">
              <span className="imp">Вид спорту</span>
              <select
                disabled={true}
                style={{
                  background: `${props.error.input && !props.form.sportId ? 'red' : ''}`,
                }}
              >
                <option></option>
              </select>
            </label>
          )}
          <label className="imp" htmlFor="">
            <span>Спортивне звання</span>
            <select
              disabled={applicationReady}
              name="athleteCategoryTitle"
              onChange={selectChangeItem}
              onBlur={onBlurOut}
              value={props.form && props.form.athleteCategoryTitle}
              style={{
                background: `${props.error.input && !props.form.athleteCategoryTitle ? 'red' : ''}`,
              }}
            >
              <option value=""></option>
              {fullList &&
                fullList[2] &&
                fullList[2].map((elem, index) => {
                  return (
                    <option
                      value={elem.title + '/' + elem.description}
                      id={elem.id}
                      key={elem.id}
                      slot={elem.title + '/' + elem.description}
                      accessKey={index}
                      title={elem.type}
                    >
                      {elem.title}
                    </option>
                  );
                })}
            </select>
          </label>
          {props.form &&
            fullList[2] &&
            fullList[2][1] &&
            props.form.athleteCategoryTitle ===
              fullList[2][1].title + '/' + fullList[2][1].description && (
              <>
                <label htmlFor="" className={applicationReady ? 'imp create-label' : 'imp'}>
                  <span>Строк тренерської роботи з</span>
                  <input
                    max={new Date().toISOString().split('T')[0]}
                    disabled={applicationReady}
                    type="date"
                    onChange={changeHandler}
                    value={props.form.athleteTrainerExperienceFrom}
                    onBlur={onBlurOut}
                    style={{
                      background: `${props.error.input && !props.form.athleteTrainerExperienceFrom ? 'red' : ''}`,
                    }}
                    name="athleteTrainerExperienceFrom"
                  />
                </label>
                <label htmlFor="" className={applicationReady ? 'imp create-label' : 'imp'}>
                  <span>Строк тренерської роботи по</span>
                  <input
                    max={new Date().toISOString().split('T')[0]}
                    disabled={applicationReady}
                    type="date"
                    onChange={changeHandler}
                    value={props.form.athleteTrainerExperienceTo}
                    onBlur={onBlurOut}
                    style={{
                      background: `${props.error.input && !props.form.athleteTrainerExperienceTo ? 'red' : ''}`,
                    }}
                    name="athleteTrainerExperienceTo"
                  />
                </label>
                <label className="form-switch labelText" style={{flexDirection:'row', marginTop:'0px'}}>
                  <input
                    id={'isFirstTrainer'}
                    type="checkbox"
                    checked={props.form.isFirstTrainer}
                    onChange={changeHandlerCheck}
                    onBlur={onBlurOut}
                  />
                  <i style={{ position: 'inline-block', marginTop: '4px' }}></i>
                  <span style={{ display: 'inline-block',color:'black' }}>
                    Перший тренер
                  </span>
                </label>
              </>
            )}


          {currentOrganisation.regionId =='d65e27eb-ed27-43db-b78a-6bdefeb44e1b'&&
           <label className="imp" htmlFor="regionId">
           <span>Територіальна приналежність</span>
           <select
             disabled={applicationReady}
             name="regionId"
             onChange={selectChangeItemRegion}
             onBlur={onBlurOut}
             value={props.form &&list[props.form.regionId]}
             style={{
               background: `${props.error.input && !props.form.regionId ? 'red' : ''}`,
             }}
           >
             <option value=""></option>
             {fullList &&
               fullList[0] &&
               fullList[0].map((elem, index) => {
                 return (
                   <option
                     value={elem.title}
                     id={elem.id}
                     key={elem.id}
                     slot={elem.title}
                     accessKey={elem.id}
                     title={elem.title}
                   >
                     {elem.title}
                   </option>
                 );
               })}
           </select>
         </label>
          }
          <label className={applicationReady ? 'imp create-label' : 'imp'} htmlFor="">
            <span>ПРІЗВИЩЕ</span>
            <input
              disabled={applicationReady}
              type="text"
              name="athleteLastName"
              onChange={changeHandler}
              onBlur={onBlurOut}
              style={{
                background: `${props.error.input && !props.form.athleteLastName ? 'red' : ''}`,
              }}
              value={props.form.athleteLastName ? props.form.athleteLastName : ''}
            />
          </label>
          <label className={applicationReady ? 'imp create-label' : 'imp'} htmlFor="">
            <span>Ім’я</span>
            <input
              disabled={applicationReady}
              type="text"
              name="athleteFirstName"
              onChange={changeHandler}
              onBlur={onBlurOut}
              style={{
                background: `${props.error.input && !props.form.athleteFirstName ? 'red' : ''}`,
              }}
              value={props.form.athleteFirstName ? props.form.athleteFirstName : ''}
            />
          </label>
          <label className={applicationReady ? 'imp create-label' : 'imp'} htmlFor="">
            <span>По батькові</span>
            <input
              disabled={applicationReady}
              type="text"
              name="athleteSecondName"
              onChange={changeHandler}
              onBlur={onBlurOut}
              value={props.form.athleteSecondName ? props.form.athleteSecondName : ''}
            />
          </label>

          <label className={applicationReady ? 'imp create-label' : 'imp'} htmlFor="">
            <span>ПРІЗВИЩЕ у давальному відмінку</span>
            <input
              disabled={applicationReady}
              type="text"
              name="athleteLastNameDative"
              onChange={changeHandler}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && !props.form.athleteLastNameDative ? 'red' : ''
                }`,
              }}
              value={props.form.athleteLastNameDative || ''}
            />
          </label>
          <label className={applicationReady ? 'imp create-label' : 'imp'} htmlFor="">
            <span>Ім’я у давальному відмінку</span>
            <input
              disabled={applicationReady}
              type="text"
              name="athleteFirstNameDative"
              onChange={changeHandler}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && !props.form.athleteFirstNameDative ? 'red' : ''
                }`,
              }}
              value={props.form.athleteFirstNameDative || ''}
            />
          </label>
          <label className={applicationReady ? 'imp create-label' : 'imp'} htmlFor="">
            <span>По батькові у давальному відмінку</span>
            <input
              disabled={applicationReady}
              type="text"
              name="athleteSecondNameDative"
              onChange={changeHandler}
              onBlur={onBlurOut}
              value={props.form.athleteSecondNameDative || ''}
            />
          </label>
          <label className={applicationReady ? 'create-label' : ''} htmlFor="">
            <span>Стать</span>
            <select
              name="sex"
              disabled={applicationReady}
              onChange={sexChange}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && props.form.sex === null ? 'red' : ''
                }`,
              }}
            >
              <option value={null}></option>
              {fullList &&
                fullList[9] &&
                fullList[9].map((sex) => {
                    return (
                      <option
                        selected={props.form.sex === sex.id}
                        key={sex.id}
                        value={sex.id}
                        data-value={sex.id}
                        data-id={sex.id}
                        data-title={sex.title}
                      >
                        {sex.title}
                      </option>
                    );
                })}
            </select>
          </label>
          <label className="form-switch labelText" style={{flexDirection:'row', marginTop:'0px'}}>
              {props.form.codeType=='0'&& <>УНЗР 
              <input
                    id={'codeType'}
                    type="checkbox"
                    checked={props.form.codeType=='1'}
                    onChange={changeHandlerCheck}
                    onBlur={onBlurOut}
              />
              <i style={{ position: 'inline-block', marginTop: '4px',marginLeft:'30px' }}></i>
              <span style={{ display: 'inline-block',color:'black' }}>
                Не має
              </span>
              </>}
              {props.form.codeType=='1'&& <>РНОКПП 
              <input
                    id={'codeType'}
                    type="checkbox"
                    checked={props.form.codeType=='2'}
                    onChange={changeHandlerCheck}
                    onBlur={onBlurOut}
              />
              <i style={{ position: 'inline-block', marginTop: '4px',marginLeft:'30px' }}></i>
              <span style={{ display: 'inline-block',color:'black' }}>
                Не має
              </span>
              </>}
              {props.form.codeType=='2'&& <>  УНЗР
              <input
                    id={'codeType'}
                    type="checkbox"
                    checked={props.form.codeType=='2'}
                    onChange={changeHandlerCheck}
                    onBlur={onBlurOut}
              />
              <i style={{ position: 'inline-block', marginTop: '4px',marginLeft:'30px' }}></i>
              <span style={{ display: 'inline-block',color:'black' }}>
                Не має
              </span>
              </>}

          </label> 
          <label className={applicationReady ? 'create-label selectSpan' : 'selectSpan'} htmlFor="">
           {props.form.codeType == '0' && <span>УНЗР</span>}
           {props.form.codeType == '1' && <span>РНОКПП</span>}
           {props.form.codeType == '2' && <span>СЕРІЯ та НОМЕР ПАСПОРТА</span>}

            {/* <select
              name="codeType"
              disabled={true}
              onChange={sexChange}
              onBlur={onBlurOut}
            >
              <option
                selected={props.form.codeType == '0'}
                key={'0'}
                value={'0'}
                data-value={'0'}
                data-id={'0'}
                data-title={'0'}
              >
                УНЗР
              </option>
              <option
                selected={props.form.codeType == '1'}
                key={'1'}
                value={'1'}
                data-value={'1'}
                data-id={'1'}
                data-title={'1'}
              >
                РНОКПП
              </option>
              <option
                selected={props.form.codeType == '2'}
                key={'2'}
                value={'2'}
                data-value={'2'}
                data-id={'2'}
                data-title={'2'}
              >
                СЕРІЯ та НОМЕР ПАСПОРТА
              </option>
            </select> */}


            {props.form.codeType == '0' && <input
              type="text"
              name="code"
              onChange={changeHandler}
              onBlur={onBlurOut}
              maxLength='14'
              minLength='14'
              placeholder='XXXXXXXX-XXXXX'
              value={props.form.code}
              style={{
                background: `${
                  props.error.input && props.form.code.length !== 14 ? 'red' : ''
                }`,
              }}
            />}
            {props.form.codeType == '1' && <input
              type="text"
              name="code"
              onChange={changeHandler}
              onBlur={onBlurOut}
              maxLength='10'
              minLength='10'
              placeholder='XXXXXXXXXX'
              value={props.form.code}
              style={{
                background: `${
                  props.error.input && props.form.code.length !== 10 ? 'red' : ''
                }`,
              }}
            />}
            {props.form.codeType == '2' && <input
              type="text"
              name="code"
              onChange={changeHandler}
              onBlur={onBlurOut}
              maxLength='9'
              minLength='9'
              placeholder='АА XXXXXX'
              value={props.form.code}
              style={{
                background: `${
                  props.error.input && props.form.code.length !== 8 ? 'red' : ''
                }`,
              }}
            />}
          </label>
          <label className={applicationReady ? 'create-label' : 'imp'} htmlFor="">
            <span>Дата народження</span>
            <input
              disabled={applicationReady}
              max={new Date().toISOString().split('T')[0]}
              type="date"
              onChange={changeHandler}
              value={props.form.birthday}
              onBlur={onBlurOut}
              name="birthday"
              style={{
                background: `${
                  props.error.input && props.form.birthday === null ? 'red' : ''
                }`,
              }}
              lang="en"
            />
          </label>
          <label className={applicationReady ? 'create-label' : 'imp'} htmlFor="">
            <span>Місце проживання на час подання</span>
            <select
              name="address"
              disabled={applicationReady}
              onChange={sexChange}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && props.form.address.length<1 ? 'red' : ''
                }`,
              }}
            >
              <option value={null}></option>
              {fullList &&
                fullList[0] &&
                fullList[0].map((company) => {
                    return (
                      <option
                        selected={props.form.address === company.id}
                        key={company.id}
                        value={company.id}
                        data-value={company.id}
                        data-id={company.id}
                        data-title={company.title}
                      >
                        {company.title}
                      </option>
                    );
                })}
            </select>
          </label>


          <label className={applicationReady ? 'create-label' : ''} htmlFor="">
            <span> Фізкультурно-спортивне товариство, інший суб’єкт сфери фізичної культури і спорту</span>
            <select
              name="company"
              disabled={applicationReady}
              onChange={sexChange}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && props.form.company === null ? 'red' : ''
                }`,
              }}
            >
              <option value={null}></option>
              {fullList &&
                fullList[13] &&
                fullList[13].map((company) => {
                    return (
                      <option
                        selected={props.form.company === company.id}
                        key={company.id}
                        value={company.id}
                        data-value={company.id}
                        data-id={company.id}
                        data-title={company.title}
                      >
                        {company.title}
                      </option>
                    );
                })}
            </select>
          </label>


          <label className={applicationReady ? 'create-label' : 'imp'} htmlFor="">
            <span>Освіта</span>
            <input
              disabled={applicationReady}
              type="text"
              name="education"
              onChange={changeHandler}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && props.form.education.length<1 ? 'red' : ''
                }`,
              }}
              value={props.form.education}
            />
          </label>
          <label className={applicationReady ? 'create-label' : 'imp'} htmlFor="">
            <span>Місце роботи (навчання), посада</span>
            <input
              disabled={applicationReady}
              type="text"
              name="workPlace"
              onChange={changeHandler}
              onBlur={onBlurOut}
              style={{
                background: `${
                  props.error.input && props.form.workPlace.length<1? 'red' : ''
                }`,
              }}
              value={props.form.workPlace}
            />
          </label>
          <label className={applicationReady ? 'create-label' : 'imp'} htmlFor="">
            <span>Попереднє звання</span>
            <input
              disabled={applicationReady}
              type="text"
              name="previousSportCategoryTitle"
              onChange={changeHandler}
              onBlur={onBlurOut}
              value={props.form.previousSportCategoryTitle}
            />
          </label>
          <label className={applicationReady ? 'create-label' : 'imp'} htmlFor="">
            <span>Дата присвоєння</span>
            <input
              disabled={applicationReady}
              max={new Date().toISOString().split('T')[0]}
              type="date"
              name="previousSportCategoryDate"
              onChange={changeHandler}
              onBlur={onBlurOut}
              value={props.form.previousSportCategoryDate}
            />
          </label>
          {props.form &&
            fullList[2] &&
            fullList[2][1] &&
            props.form.athleteCategoryTitle !==
              fullList[2][1].title + '/' + fullList[2][1].description && (
              <>
                <h4 className="imp">Тренери, що підготували спортсмена</h4>

                <label
                  className={applicationReady ? 'create-label' : ''}
                  htmlFor="trainerLastNameFirst"
                >
                  <span>ПРІЗВИЩЕ *</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    id="trainerLastNameFirst"
                    name="trainerLastNameFirst"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    style={{
                      background: `${
                        props.error.input && !props.form.trainerLastNameFirst ? 'red' : ''
                      }`,
                    }}
                    value={props.form.trainerLastNameFirst}
                  />
                </label>
                <label
                  className={applicationReady ? 'create-label' : ''}
                  htmlFor="trainerFirstNameFirst"
                >
                  <span>Ім’я *</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    id="trainerFirstNameFirst"
                    name="trainerFirstNameFirst"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    style={{
                      background: `${
                        props.error.input && !props.form.trainerFirstNameFirst ? 'red' : ''
                      }`,
                    }}
                    value={props.form.trainerFirstNameFirst}
                  />
                </label>
                <label
                  className={applicationReady ? 'create-label' : ''}
                  htmlFor="trainerSecondNameFirst"
                >
                  <span>По батькові *</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    id="trainerSecondNameFirst"
                    name="trainerSecondNameFirst"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    style={{
                      background: `${
                        props.error.input && !props.form.trainerSecondNameFirst ? 'red' : ''
                      }`,
                    }}
                    value={props.form.trainerSecondNameFirst}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="trainerSexFirst">
                  <span>Стать *</span>
                  <select
                    name="trainerSexFirst"
                    disabled={applicationReady}
                    onChange={sexChange}
                    onBlur={onBlurOut}
                    style={{
                      background: `${
                        props.error.input && !props.form.trainerSexFirst ? 'red' : ''
                      }`,
                    }}
                  >
                    <option value={null}></option>
                    {fullList &&
                      fullList[9] &&
                      fullList[9].map((sex) => {
                          return (
                            <option
                              selected={props.form.trainerSexFirst === sex.id}
                              key={sex.id}
                              value={sex.id}
                              data-value={sex.id}
                              data-id={sex.id}
                              data-title={sex.title}
                            >
                              {sex.title}
                            </option>
                          );
                      })}
                  </select>
                </label>
                <label style={{ borderBottom: '3px solid' }} htmlFor="trainerCategoryFirst">
                  <span>Тренерська категорія *</span>
                  <select
                    disabled={applicationReady}
                    name="trainerCategoryFirst"
                    id="trainerCategoryFirst"
                    onChange={selectChangeItem}
                    onBlur={onBlurOut}
                    style={{
                      background: `${
                        props.error.input && !props.form.trainerCategoryFirst ? 'red' : ''
                      }`,
                    }}
                  >
                    <option value=""></option>
                    {fullList &&
                      fullList[3] &&
                      fullList[3].map((elem, index) => {
                        return (
                          <option
                            selected={props.form.trainerCategoryFirst === elem.title}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                            accessKey={index}
                            title={elem.type}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>

                <label className={applicationReady ? 'create-label' : ''} htmlFor="">
                  <span>ПРІЗВИЩЕ</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    name="trainerLastNameSecond"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    value={props.form.trainerLastNameSecond}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="">
                  <span>Ім’я</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    name="trainerFirstNameSecond"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    value={props.form.trainerFirstNameSecond}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="">
                  <span>По батькові</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    name="trainerSecondNameSecond"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    value={props.form.trainerSecondNameSecond}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="trainerSexSecond">
                  <span>Стать</span>
                  <select
                    name="trainerSexSecond"
                    disabled={applicationReady}
                    onChange={sexChange}
                    onBlur={onBlurOut}
                  >
                    <option value={null}></option>
                    {fullList &&
                      fullList[9] &&
                      fullList[9].map((sex) => {
                          return (
                            <option
                              selected={props.form.trainerSexSecond === sex.id}
                              key={sex.id}
                              value={sex.id}
                              data-value={sex.id}
                              data-id={sex.id}
                              data-title={sex.title}
                            >
                              {sex.title}
                            </option>
                          );
                      })}
                  </select>
                </label>
                <label style={{ borderBottom: '3px solid' }} htmlFor="">
                  <span>Тренерська категорія</span>
                  <select
                    disabled={applicationReady}
                    name="trainerCategorySecond"
                    onChange={selectChangeItem}
                    onBlur={onBlurOut}
                  >
                    <option value=""></option>
                    {fullList &&
                      fullList[3] &&
                      fullList[3].map((elem, index) => {
                        return (
                          <option
                            selected={props.form.trainerCategorySecond === elem.title}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                            accessKey={index}
                            title={elem.type}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>

                <label className={applicationReady ? 'create-label' : ''} htmlFor="">
                  <span>ПРІЗВИЩЕ</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    name="trainerLastNameThird"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    value={props.form.trainerLastNameThird}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="">
                  <span>Ім’я</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    name="trainerFirstNameThird"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    value={props.form.trainerFirstNameThird}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="">
                  <span>По батькові</span>
                  <input
                    disabled={applicationReady}
                    type="text"
                    name="trainerSecondNameThird"
                    onChange={changeHandler}
                    onBlur={onBlurOut}
                    value={props.form.trainerSecondNameThird}
                  />
                </label>
                <label className={applicationReady ? 'create-label' : ''} htmlFor="trainerSexThird">
                  <span>Стать</span>
                  <select
                    name="trainerSexThird"
                    disabled={applicationReady}
                    onChange={sexChange}
                    onBlur={onBlurOut}
                  >
                    <option value={null}></option>
                    {fullList &&
                      fullList[9] &&
                      fullList[9].map((sex) => {
                          return (
                            <option
                              selected={props.form.trainerSexThird === sex.id}
                              key={sex.id}
                              value={sex.id}
                              data-value={sex.id}
                              data-id={sex.id}
                              data-title={sex.title}
                            >
                              {sex.title}
                            </option>
                          );
                      })}
                  </select>
                </label>
                <label htmlFor="">
                  <span>Тренерська категорія</span>
                  <select
                    name="trainerCategoryThird"
                    disabled={applicationReady}
                    onChange={selectChangeItem}
                    onBlur={onBlurOut}
                  >
                    <option value=""></option>
                    {fullList &&
                      fullList[3] &&
                      fullList[3].map((elem, index) => {
                        return (
                          <option
                            selected={props.form.trainerCategoryThird === elem.title}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                            accessKey={index}
                            title={elem.type}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </>
            )}
        </div>
        <div></div>
        <div className="application-image-block">
          <div className="image-block">
            {props.doc && props.doc[5] && props.doc[5].url.length > 0 ? (
              <img id="output" src={props.doc[5].url} />
            ) : (
              <img id="output" />
            )}
            {props.doc &&
              props.doc[5] &&
              props.doc[5].url.length > 0 &&
              ((props.application.status == 1 && props.form) || props.application.status == 0) && (
                <label htmlFor="image_uploads">Замінити фото</label>
              )}
            {props.doc &&
              !props.doc[5] &&
              ((props.application.status == 1 && props.form) || props.application.status == 0) && (
                <label
                  htmlFor="image_uploads"
                  style={{ color: `${props.error.doc && !props.doc[5] ? 'red' : ''}` }}
                >
                  Завантажити фото *
                </label>
              )}

            {props.doc && props.doc[5] && props.doc[5].url && props.doc[5].url.length > 0 && (
              <a download target="blank" className="document-link" href={props.doc[5].url}>
                Скачати фото
              </a>
            )}
            <input
              accept="image/*"
              type="file"
              id="image_uploads"
              name="picture"
              className="image-upload"
              onChange={loadFile}
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className="section-head">Підстава присвоєння *</h2>
        <table>
          <thead>
            <tr>
              <th style={{'text-align':'left'}}>Дата виконання</th>
              <th style={{'text-align':'left'}}>Назва змагань</th>
              {props.form.athleteCategoryTitleId=='cb4b06a9-d396-4791-b646-4659a068a5df' && <th style={{'text-align':'left'}}>ПІБ спортсмена</th>}
              {/* <th style={{'text-align':'left'}}>Ранг, категорія змагань</th> */}
              <th style={{'text-align':'left'}}>Дисципліна</th>
              <th style={{'text-align':'left'}}>Виконаний результат</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.form &&
              props.form.achievements &&
              props.form.achievements.map((el, index) => {
                return (
                  <>
                  <tr key={el.eventTitle}>
                    <td>
                      {el.eventDateStart} {el.eventDateEnd}
                    </td>
                    <td>{el.eventTitleIdTitle?el.eventTitleIdTitle:el.eventTitle}</td>
                    {props.form.athleteCategoryTitleId=='cb4b06a9-d396-4791-b646-4659a068a5df' &&  <td>{el.eventAthlet}</td>}
                    {/* <td>
                      {fullList &&
                        fullList[6] &&
                        fullList[6].map((elem) => {
                          if (elem.id === el.eventCategory) return elem.title;
                        })}
                    </td> */}
                    <td>
                      {el.eventDesciplineTitle} {el.eventResultAdded || ''}
                    </td>

                    <td>{el.eventResultIdTitle?el.eventResultIdTitle:el.eventResult}</td>
                    <td className="judge-buttons">
                      {((props.application.status == 1) ||
                        props.application.status == 0) && (
                        <>
                          <i
                            id="event"
                            accessKey={index}
                            className="icon edit-icon"
                            onClick={editClickAchievements}
                          ></i>
                          <i
                            id="event"
                            className="icon delete-icon"
                            onClick={() => deleteClickAchievements(index)}
                          ></i>
                        </>
                      )}
                    </td>
                  </tr>
                   {/* <tr>
                 <td colspan='6' style={{padding:'0'}}>
                  <table>
                    <thead>
                      <tr>
                        <th>Суддівська колегія</th>
                        <th>Прізвище, Власне ім'я</th>
                        <th>Країна, місто</th>
                        <th>Суддівська категорія</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Головний суддя</td>
                        <td>
                          {el.mainJudge}
                        </td>
                        <td>{el.eventMainJudgeCity}</td>
                        <td>
                          {fullList &&
                            fullList[5] &&
                            fullList[5].map((elem) => {
                              if (elem.id === el.eventMainJudgeCategory) return elem.title;
                            })}
                        </td>
                      </tr>
                      <tr>
                        <td>Заступник головного судді</td>
                        <td>
                          {el.mainSubJudge}
                        </td>
                        <td>{el.eventMainSubJudgeCity}</td>
                        <td>
                          {fullList &&
                            fullList[5] &&
                            fullList[5].map((elem) => {
                              if (elem.id === el.eventMainSubJudgeCategory) return elem.title;
                            })}
                        </td>
                      </tr>
                      <tr>
                        <td>Суддя (секретар)</td>
                        <td>
                          {el.judge}
                        </td>
                        <td>{el.judgeCity}</td>
                        <td>
                          {fullList &&
                            fullList[5] &&
                            fullList[5].map((elem) => {
                              if (elem.id === el.judgeCategory) return elem.title;
                            })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </td> 
                  </tr>*/}
                  </>
                );
              })}
            {((props.application.status == 1) ||
              props.application.status == 0) && (
              <tr
                style={{
                  background: `${
                    props.error.input && props.form.achievements.length === 0 ? 'red' : ''
                  }`,
                }}
              >
                <td colSpan="5" className="judge-buttons">
                  <button
                    className="icon-text-btn-secondary"
                    id="event"
                    accessKey={props.form.achievements && props.form.achievements.length}
                    onClick={editClickAchievements}
                  >
                    Додати рядок
                  </button>{' '}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        {/* <h2 className="section-head">Суддівська колегія</h2> */}
        {/* <table>
          <thead>
            <tr>
              <th>Суддівська колегія</th>
              <th>Прізвище, Власне ім'я</th>
              <th>Країна, місто</th>
              <th>Суддівська категорія</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Головний суддя</td>
              <td>
                {props.form.eventMainJudgeLastName} {props.form.eventMainJudgeFirstName}{' '}
                {props.form.eventMainJudgeSecondName}
              </td>
              <td>{props.form.eventMainJudgeCity}</td>
              <td>
                {fullList &&
                  fullList[5] &&
                  fullList[5].map((elem) => {
                    if (elem.id === props.form.eventMainJudgeCategory) return elem.title;
                  })}
              </td>
              <td className="judge-buttons">
                {((props.application.status == 1 && props.form && !props.form.officeCode) ||
                  props.application.status == 0) && (
                  <i id="eventMainJudge" className="icon edit-icon" onClick={editClick}></i>
                )}
              </td>
            </tr>
            <tr>
              <td>Заступник головного судді</td>
              <td>
                {props.form.eventViceMainJudgeLastName} {props.form.eventViceMainJudgeFirstName}{' '}
                {props.form.eventViceMainJudgeSecondName}
              </td>
              <td>{props.form.eventViceMainJudgeCity}</td>
              <td>
                {fullList &&
                  fullList[5] &&
                  fullList[5].map((elem) => {
                    if (elem.id === props.form.eventViceMainJudgeCategory) return elem.title;
                  })}
              </td>
              <td className="judge-buttons">
                {' '}
                {((props.application.status == 1 && props.form && !props.form.officeCode) ||
                  props.application.status == 0) && (
                  <i id="eventViceMainJudge" className="icon edit-icon" onClick={editClick}></i>
                )}
              </td>
            </tr>
            <tr>
              <td>Суддя (секретар)</td>
              <td>
                {props.form.eventJudgeLastName} {props.form.eventJudgeFirstName}{' '}
                {props.form.eventJudgeSecondName}
              </td>
              <td>{props.form.eventJudgeCity}</td>
              <td>
                {fullList &&
                  fullList[5] &&
                  fullList[5].map((elem) => {
                    if (elem.id === props.form.eventJudgeCategory) return elem.title;
                  })}
              </td>
              <td className="judge-buttons">
                {((props.application.status == 1 && props.form && !props.form.officeCode) ||
                  props.application.status == 0) && (
                  <i id="eventJudge" className="icon edit-icon" onClick={editClick}></i>
                )}
              </td>
            </tr>
          </tbody>
        </table> */}
        {formActive && (
          <Form
            form={props.form}
            saveAddChange={saveAddChange}
            element={value}
            setForm={props.setForm}
            setFormActive={setFormActive}
            judgeEdit={judgeEdit}
          />
        )}
      </div>
    </div>
  );
};

export default Application;
