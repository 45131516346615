import axios from 'axios';
import authHeader from './auth-header';

import constants from '../../constants/constant.json';

const getSportCategories = () => {
  return axios
    .get(constants.API_URL + '/dictionaries/sport-categories?sort[type]=asc')
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getSportCategories,
};
