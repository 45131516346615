import { CATEGORIES_SUCCESS, CATEGORIES_FAIL } from './types';

import CategoriesService from '../services/categories.service';

export const getSportCategories = () => (dispatch) => {
  return CategoriesService.getSportCategories().then(
    (response) => {
      dispatch({
        type: CATEGORIES_SUCCESS,
        categories: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: CATEGORIES_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
