import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Item from '../Item/Item';
import { selectCurrentOrganisation } from '../../../../redux/selectors/organisations';
import {
  selectCurrentOrganisationId,
  selectCurrentUserId,
  selectIsAdmin,
  selectIsHeadOrTrustedEmployee,
} from '../../../../redux/selectors/auth';
import NewLoader from '../../../../components/NewLoader';

const Done = (props) => {
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const { applications } = useSelector((state) => state);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentOrganisationId = useSelector(selectCurrentOrganisationId);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const isAdmin = useSelector(selectIsAdmin);
  const isHeadOrTrustedEmployee = useSelector(selectIsHeadOrTrustedEmployee);

  const getList = useCallback(async () => {
    let data = [];

    if (isAdmin) {
      data = [{ name: 'status', value: '0' }];
    } else if (!currentOrganisationId) {
      data = [{ name: 'status', value: '0' }];
    } else if (currentOrganisation && currentOrganisation.type === 0) {
      data = [
        { name: 'status', value: '2' },
        { name: 'status', value: '3' },
        { name: 'status', value: '4' },
        { name: 'status', value: '5' },
        { name: 'status', value: '6' },
        { name: 'status', value: '7' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '10' },
        { name: 'status', value: '11' },
        { name: 'status', value: '12' },
        { name: 'status', value: '13' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'createdByOrganisationId', value: currentOrganisationId },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 1) {
      data = [
        { name: 'status', value: '3' },
        { name: 'status', value: '4' },
        { name: 'status', value: '5' },
        { name: 'status', value: '6' },
        { name: 'status', value: '7' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '10' },
        { name: 'status', value: '11' },
        { name: 'status', value: '12' },
        { name: 'status', value: '13' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'regionId', value: currentOrganisation.regionId },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 2) {
      data = [
        { name: 'status', value: '5' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'isTrainerReview', value:'1'},
      ];
    } else if (currentOrganisation && currentOrganisation.type === 3) {
      data = [
        // { name: 'status', value: '5' },
        // { name: 'status', value: '6' },
        // { name: 'status', value: '7' },
        // { name: 'status', value: '8' },
        // { name: 'status', value: '9' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'sportCategoryType', value: 1 },
        { name: 'sportCategoryType', value: 0 },


      ];
    } else if (currentOrganisation && currentOrganisation.type === 4) {
      data = [
        { name: 'status', value: '5' },
        { name: 'status', value: '6' },
        { name: 'status', value: '7' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'sportCategoryType', value: 1 },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 5 && isHeadOrTrustedEmployee) {
      data = [
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'sportCategoryType', value: 2 },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 5) {
      data = [
        { name: 'status', value: '13' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'sportCategoryType', value: 2 },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 6 && !isHeadOrTrustedEmployee) {
      data = [
        { name: 'status', value: '6' },
        { name: 'status', value: '7' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'federationId', value: currentOrganisation.id },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 6 && isHeadOrTrustedEmployee) {
      data = [
        { name: 'status', value: '9' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'federationId', value: currentOrganisation.id },
      ];
    } else if (
      currentOrganisation &&
      (currentOrganisation.type === 7 || currentOrganisation.type === 8)
    ) {
      data = [
        { name: 'status', value: '5' },
        { name: 'status', value: '6' },
        { name: 'status', value: '7' },
        { name: 'status', value: '8' },
        { name: 'status', value: '9' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'trainerId', value: currentUserId },
        // { name: 'isTrainerReview', value: '1'},
      ];
    } else if (currentOrganisation && currentOrganisation.type === 9) {
      data = [
        { name: 'status', value: '10' },
        { name: 'status', value: '13' },
        { name: 'status', value: '14' },
        { name: 'status', value: '15' },
        { name: 'status', value: '17' },
        { name: 'status', value: '18' },
        { name: 'status', value: '19' },
        { name: 'federationId', value: currentOrganisation.id },
      ];
    } else if (currentOrganisation && currentOrganisation.type === 10) {
      data = [
        // {name: 'status',
        // value: '17'},
        { name: 'status', value: '20' },
        { name: 'status', value: '21' },
      ];
    }
    if (props.search) {
      data.push({ name: 'all', value: props.search });
    }
    if (props.sort) {
      data.push({ sortName: props.sort.sortName, sortValue: props.sort.sortValue });
    }

    if (sessionStorage.done) {
      let done = JSON.parse(sessionStorage.done);
      props.setFilterForm(done);
      done.forEach((element) => {
        if (element && element.value.length > 0) {
          data.push(element);
        }
      });
      props.setReload(false);
    }

    props.setData(data);
    await props.getApplications(data, props.currentPage);
    setReady(true);
  }, [applications, currentOrganisation, props.search, props.sort, props.currentPage]);

  useEffect(() => {
    void getList();
  }, [currentOrganisation, props.search, props.reload, props.sort, props.currentPage]);

  return (
    <>
      {(ready &&
        applications &&
        applications.list &&
        applications.list.length > 0) ?
        applications.list.map((item) => {
          return (
            <Item
              item={item}
              type={props.type}
              selectHandler={props.selectHandler}
              selectDep={props.selectDep}
              key={item.id}
              history={history}
            />
          );
        }):
        <tr >
          <td colSpan="9">
          {ready && applications?.list?.length==0?<h2>Жодної заявки</h2>
          : <NewLoader/>}
          </td>
        </tr>
        }
    </>
  );
};

export default Done;
