import React, { useEffect, useState } from 'react';
import KepService from '../../redux/services/kep.service';

export const DigitalSignatureModal = ({ visible, onClose, data, previousSign = null, onSign,urlPrev,isArray=false }) => {
  const [signerData, setSignerData] = useState();
  const [continueDat, setContinueDat] = useState(false);

  const onOverlayClick = (event) => {
    if (event.target.id === 'digital-signature-modal-overlay') {
      onClose();
    }
  };

  const doSign = async () => {
    const signature = await KepService.sign(data, previousSign);
    if (signature) {
      onSign({ signature, ...signerData });
      onClose();
    }
  };

  const doSignNew = async () => {
    data.forEach((el,index)=>{
      data[index]=KepService.base64ToUint8Array(el);
    })

    const signature = await KepService.signNew(data, previousSign);
    if (signature) {
      onSign({ signature, ...signerData });
      onClose();
    }
  };

  const readKey = async () => {
    const signer = await KepService.readKey();
    if (signer) {
      setSignerData({
        serial: signer.serial,
        unzr: signer.subjUNZR,
        tax_code: signer.subjEDRPOUCode || signer.subjDRFOCode,
        name: signer.subjFullName,
      });
    }
  };

  useEffect(() => {
    if (visible && !signerData) {
      readKey();
    }
  }, [visible]);

  useEffect(() => {
    if(isArray && signerData){
      doSignNew()
    }else if (signerData && !isArray) {
      doSign();
    }
  }, [signerData]);

  useEffect(() => {
    KepService.init();
  }, []);

  
  return (
    <>
    {!continueDat && visible && urlPrev?.length>0 && <div className='digital-signature-modal-overlay'>
      <div className="digital-signature-modal withIframe">
        <button className='icon-text-btn-primary btn-primary' onClick={()=>setContinueDat(true)}>Підписати</button>
        <iframe style={{height:'100%'}} src={urlPrev} frameborder="0">
        </iframe>
      </div>
      
    </div>}
    {<div
      id="digital-signature-modal-overlay"
      className="digital-signature-modal-overlay"
      style={{ display: (visible && (continueDat || !urlPrev?.length>0)) ? 'block' : 'none' }}
      onClick={onOverlayClick}
    >
      <div className="digital-signature-modal">
        <div id="sign-widget-parent" />
      </div>
    </div>}
    </>
  );
};
