import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import { selectIsAdmin } from '../../redux/selectors/auth';
import { Users } from './Users/Users';
import Ministry from './Ministry/Ministry';
import Regions from './Regions/Regions';
import { Unmoderated } from './Unmoderated/Unmoderated';
import { Moderated } from './Moderated/Moderated';

const OrganisationsPage = () => {
  const isAdmin = useSelector(selectIsAdmin);

  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState({
    regions: location.pathname.match(/organisations$/),
    ministry: location.pathname.match(/ministry$/),
    unmoderated: location.pathname.match(/unmoderated$/),
    moderated: location.pathname.match(/moderated$/),
    users: location.pathname.match(/users/),
  });

  const clickHandler = (event) => {
    setPage({ [event.target.accessKey]: true });
    history.push(`/organisations/${event.target.accessKey}`);
  };
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }
  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      <h1 className="public-header">
        {isAdmin && 'Організації та користувачі'}
        {!isAdmin && 'Організації'}
      </h1>

      {!isAdmin && (
        <div className="organisation">
          <Moderated />
        </div>
      )}
      {isAdmin && (
        <>
          <div className="auth-tab">
            <button
              className={page.regions ? 'auth-tab-item selected' : 'auth-tab-item'}
              accessKey="regions"
              onClick={clickHandler}
            >
              Регіони
            </button>
            <button
              className={page.ministry ? 'auth-tab-item selected' : 'auth-tab-item'}
              accessKey="ministry"
              onClick={clickHandler}
            >
              Міністерство
            </button>
            <button
              className={page.unmoderated ? 'auth-tab-item selected' : 'auth-tab-item'}
              accessKey="unmoderated"
              onClick={clickHandler}
            >
              Немодеровані
            </button>
            <button
              className={page.moderated ? 'auth-tab-item selected' : 'auth-tab-item'}
              accessKey="moderated"
              onClick={clickHandler}
            >
              Всі модеровані організації
            </button>
            <button
              className={page.users ? 'auth-tab-item selected' : 'auth-tab-item'}
              accessKey="users"
              onClick={clickHandler}
            >
              Всі користувачі
            </button>
          </div>
          <div className="organisation">
            <Switch>
              <Route path="/organisations" exact render={() => <Regions />} />
              <Route path="/organisations/ministry" exact render={() => <Ministry />} />
              <Route path="/organisations/unmoderated" exact render={() => <Unmoderated />} />
              <Route path="/organisations/moderated" exact render={() => <Moderated />} />
              <Route path="/organisations/users" exact render={() => <Users />} />
              <Redirect to="/organisations" />
            </Switch>
          </div>
        </>
      )}
    </div>
  );
};

export default OrganisationsPage;
