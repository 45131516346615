import { ASSIGNGETLIST_SUCCESS, } from '../actions/types';

const initialState = [];

export default function assignReducer(state = initialState, action) {
  const { type, list, offset, total, limit } = action;

  switch (type) {
    case ASSIGNGETLIST_SUCCESS:
      return { list: [...list], offset, total, limit };
    default:
      return state;
  }
}
