import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const addCoverItem = (body) => {
  return axios
    .post(constants.API_URL + `/applications/${body.applicationId}/cover-letters`, body, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getCoverItem = (body) => {
  return axios
    .get(constants.API_URL + `/applications/${body.applicationId}/cover-letters/${body.id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const updateCoverItem = (body) => {
  return axios
    .put(
      constants.API_URL + `/applications/${body.applicationId}/cover-letters/${body.id}`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getCoverList = (applicationId) => {
  return axios
    .get(
      constants.API_URL +
        `/applications/${applicationId}/cover-letters?offset=0&limit=10&sort[status]=desc`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const deleteCoverItem = (body) => {
  return axios
    .delete(constants.API_URL + `/applications/${body.applicationId}/cover-letters/${body.id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getCoverList,
  updateCoverItem,
  getCoverItem,
  addCoverItem,
  deleteCoverItem,
};
