import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const getInstructionsList = (data) => {
  let search = '';
  if (data && data.length >= 3) {
    search = data;
  }

  return axios
    .get(
      constants.API_URL +
        `/instructions?offset=0&limit=150&sort[title]=asc${
          search.length > 2 ? '&filters[all]=' + search : ''
        }`,
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const deleteInstructionsItem = (data) => {
  return axios
    .delete(constants.API_URL + '/instructions/' + data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const editInstructionsItem = (data) => {
  return axios
    .put(constants.API_URL + '/instructions/' + data.id, { ...data }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const addInstructionsItem = (data) => {
  return axios
    .post(constants.API_URL + '/instructions/', { ...data }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const getInstructionsItem = (data) => {
  return axios
    .get(constants.API_URL + '/dictionaries/' + data.id)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getInstructionsList,
  deleteInstructionsItem,
  editInstructionsItem,
  addInstructionsItem,
  getInstructionsItem,
};
