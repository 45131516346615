import React from 'react';
import AuthApplicationsPage from './AuthApplicationsPage/AuthApplicationsPage';
import PublicApplicationsPage from './PublicApplicationsPage/PublicApplicationsPage';

const ApplicationsPage = ({ isLoggedIn }) => {
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }
  return isLoggedIn ? <AuthApplicationsPage /> : <PublicApplicationsPage />;
};

export default ApplicationsPage;
