import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { code, login } from '../../redux/actions/auth';

const LoginPageAdmin = () => {
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [form, setForm] = useState({
    initialState: {
      email: '',
      password: '',
    },
  });
  const [err, setErr] = useState(false);
  const [errKep, setErrKep] = useState(false);
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }
  function handleRegister() {
    history.push('/registration');
  }

  function handleRestore() {
    history.push('/restore');
  }
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const signHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    await dispatch(login(form.email, form.password))
      .then((res) => {
        if (typeof res === 'string') {
          setErr(true);
        } else {
          history.push('/applications');
          // window.location.replace("/applications")
        }
      })
      .catch((e) => {
        setErr(true);
        console.log(e);
        //   setLoading(false);
      });
  };

  //   if ( isLoggedIn) {
  //     history.push("/applications")
  //   }

  const kepLog = () => {
    let state = localStorage.getItem('state');
    let search = window.location.search.split('&');
    let value = search[1].slice(6, search[1].length);
    let codeData = search[0].slice(6, search[0].length);
    if (state == value) {
      let data = { code: codeData };
      dispatch(code({ ...data }))
        .then((res) => {
          if (typeof res === 'string') {
            setErrKep(true);
          } else {
            history.push('/applications');
          }
          localStorage.removeItem('state');
        })
        .catch((e) => {
          setErrKep(true);
          localStorage.removeItem('state');
          console.log(e);
        });
    } else {
      alert('something went wrong');
    }
  };

  useEffect(() => {
    if (window.location.search && localStorage.getItem('state')) {
      kepLog();
    }
  }, []);

  const kepClick = () => {
    let state = '';
    function uuidv4() {
      return (state = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
      ));
    }
    uuidv4();
    localStorage.setItem('state', state);
    // console.log(`https://id.gov.ua/?response_type=code&client_id=40ca6d64d8b82ac0b4988e585c0f5baa&auth_type=dig_sign,bank_id,diia_id&state=${state}&redirect_uri=https://title.mms.gov.ua`)
    window.location = `https://id.gov.ua/?response_type=code&client_id=40ca6d64d8b82ac0b4988e585c0f5baa&auth_type=dig_sign,bank_id,diia_id&state=${state}&redirect_uri=https://title.mms.gov.ua/login`;
  };

  return (
    <div className="auth main">
      <form className="mainBlock">
        <h1 className="loginHeader">Авторизація</h1>
        {/* <div className='loginTextBlock'>
          <div>
            <input className='loginCheck' checked={checked} onClick={()=>setChecked(!checked)} type="checkbox" />
          </div>
          <div>
            <div className='loginText'>Зобов’язання про нерозголошення персональних даних</div>     
            <div className='loginText'>Відповідно до статті 10 Закону України «Про захист персональних даних» зобов’язуюсь не розголошувати у будь-який спосіб персональні дані інших осіб,  що стали відомі мені у процесі роботи в електронній комунікаційній системі «Онлайн сервіс для переведення у публічну площину процесу присвоєння спортивних звань з видів спорту, офіційно визнаних в Україні», зокрема, під час обробки заявок в системі, крім випадків, передбачених законом. </div>     
            <div className='loginText'>Підтверджую, що зобов’язання буде чинним після припинення мною діяльності, пов’язаною з обробкою персональних даних, крім випадків, установлених законом.</div>     
          </div>
        </div> */}
       
        <button
          className="btn-primary text-btn-primary"
          style={{ marginTop: '0' }}
          type="button"
          onClick={kepClick}
        >
          Увійти за допомогою КЕП
        </button>

        <div className="iterator"> або </div>

        <label className="labelText labelText-top">
          <span>Електронна пошта (логін)</span>
          <input
            className=""
            type="text"
            name="email"
            onChange={changeHandler}
            value={form.email}
          />
          {err && <span className="validError">Неправильна пошта і пароль</span>}
          {errKep && (
            <span className="validError">
              Користувач з таким email вже існує. Зверніться до адміністратора
            </span>
          )}
        </label>
        <label className="labelText">
          <span>Пароль</span>
          <div className="btn">
            <input
              className={err ? 'inputInvalid psw-input' : 'psw-input'}
              type={passwordShown ? 'text' : 'password'}
              name="password"
              required
              onChange={changeHandler}
              value={form.password}
            />
            <i
              className={!passwordShown ? 'icon password-icon' : 'icon password-icon-noActive'}
              onClick={togglePasswordVisibility}
            ></i>
          </div>
          {err && <span className="validError">Неправильна пошта і пароль</span>}
          {errKep && (
            <span className="validError">
              Користувач з таким email вже існує. Зверніться до адміністратора
            </span>
          )}
        </label>

        <button className="btn-secondary text-btn-primary" type="submit" onClick={signHandler}>
          Увійти
        </button>
        <a className="link" onClick={handleRestore}>
          Відновити пароль
        </a>
        <a className="link" onClick={handleRegister}>
          Зареєструватись
        </a>
      </form>
    </div>
  );
};

export default LoginPageAdmin;
