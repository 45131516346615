import axios from 'axios';
import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const getMatesList = (data, search, page = 1) => {
  let filter = `?offset=${(page - 1) * 10}&limit=10&sort[isHeadEmployee]=desc&sort[lastName]=asc`;
  search.forEach((element, index) => {
    if (element.name === 'status') {
      filter += '&filters[' + element.name + '][' + index + ']=' + element.value;
    } else if (element.sortName && element.sortName.length > 0) {
      filter += `&sort[${element.sortName}]=${element.sortValue}`;
    } else {
      filter += '&filters[' + element.name + ']=' + element.value;
    }
  });

  let url = '';
  if (data.filter) {
    url = `${constants.API_URL}/organisations/${data}/employees?offset=0&limit=100&filters[${data.filter}]=${data.filterId}&sort[lastName]=asc`;
  } else {
    url = constants.API_URL + `/organisations/${data}/employees${filter}`;
  }
  return axios
    .get(url, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

const getMatesItem = (data) => {
  return axios
    .get(constants.API_URL + `/organisations/${data.id}/employees/${data.mateId}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const deleteMatesItem = (data) => {
  return axios
    .delete(constants.API_URL + `/organisations/${data.id}/employees/${data.mateId}`, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};
const editMatesItem = (data) => {
  let body = {};
  if (data.pos) {
    body = { [data.pos]: data.value };
  } else if (data.sports) {
    body = { sports: data.sports };
  }

  return axios
    .put(
      constants.API_URL + `/organisations/${data.id}/employees/${data.mateId}`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const addMatesItem = (data) => {
  const body = { userId: data.userId };

  return axios
    .post(constants.API_URL + `/organisations/${data.id}/employees`, body, {
      headers: authHeader(),
    })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
    });
};

const getMatesUnconfirmedList = (data, search, page = 1) => {
  let filter = `?offset=${
    (page - 1) * 10
  }&limit=10&sort[isHeadEmployee]=desc&sort[lastName]=asc`;
  search.forEach((element, index) => {
    if (element.name === 'status') {
      filter += '&filters[' + element.name + '][' + index + ']=' + element.value;
    } else if (element.sortName && element.sortName.length > 0) {
      filter += `&sort[${element.sortName}]=${element.sortValue}`;
    } else {
      filter += '&filters[' + element.name + ']=' + element.value;
    }
  });
  return axios
    .get(constants.API_URL + '/users' + filter, { headers: authHeader() })
    .then((response) => response.data)
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

export default {
  getMatesList,
  getMatesItem,
  deleteMatesItem,
  editMatesItem,
  addMatesItem,
  getMatesUnconfirmedList,
};
