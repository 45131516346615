import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader";
import Pagination from "../../components/Pagination/Pagination";
import getDate from "../../helpers/getDate";
import { getOrganisationsSubmissionList } from "../../redux/actions/organisations";

const PAGE_SIZE = 100;

const MateItemsListPage = ()=>{
    const [currentPage, setCurrentPage] = useState(1);
    const [ready,setReady]=useState(false);
    const dispatch = useDispatch();
    const { fullList } = useSelector((state) => state);


    const getOrganisations = useCallback(
        async (page = currentPage) => {
          const limit = PAGE_SIZE;
          const offset = (page - 1) * limit;
          const sort = { region: 'asc' };
          const filter = {};
          const filterValue = sessionStorage.getItem('orgSearch') || '';
          if (filterValue.length > 2) {
            filter.all = filterValue;
          }
          await dispatch(getOrganisationsSubmissionList({ sort, offset, limit, filter })).then(res=>{
            setReady(true);
          });
        },
        [currentPage],
      );


    useEffect(() => {
        if(!ready){
            getOrganisations();
        }
    }, [ready]);

    if(ready){
        return(
            <div className="main public-page">
            <div
              className="public-sub-header"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <h3>Публічний реєстр присвоєння спортивних звань</h3>
            </div>
      
            <h1 className="public-header">
              Список наказів призначення
            </h1>
            <div className="public-table" style={{ overflowX: 'auto' }}>
              <table className="public-table-cust">
              <thead>
          <tr>
            <th></th>
            <th>Посилання</th>
            {/* <th>Дата завантаження</th> */}
          </tr>
        </thead>
        <tbody>
          {fullList[12]?.length &&
            fullList[12].map((elem) => {
              return (
                <tr id={elem.id} key={elem.id}>
                  <td></td>
                  <td> {elem.url && (
                      <a href={elem.url} target="_blank" className="document-link">
                        {elem.urlName || elem.url}
                      </a>
                    )}
                </td>
                  {/* <td>{getDate(elem.updatedAt.replace('T',' '),'dd/mm/yyyy','-')}</td> */}
                </tr>
              );
            })}
        </tbody>
              </table>
            </div>
            <Footer />
          </div>
        )
    }else{
        return(
            <Loader/>
        )
    }
    
    

}

export default MateItemsListPage;