import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const getDictionariesList = (data) => {
  return axios
    .get(
      constants.API_URL +
        '/dictionaries/' +
        data +
        '?offset=0&limit=150&sort[type]=asc&sort[title]=asc',
        {
          headers: authHeader(),
        })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const deleteDictionariesItem = (data) => {
  return axios
    .delete(constants.API_URL + '/dictionaries/' + data.category + '/' + data.id, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const editDictionariesItem = (data) => {
  let body;
  if (data.category === 'ministry-executives') {
    body = {
      firstName: data.firstName,
      lastName: data.lastName,
      secondName: data.secondName,
      position: data.position,
    };
  }else if(data.category==='users-list-permissions'){
    body=data;
  }else if (data.documentNumber) {
    if (data.name.length < 1) {
      data.name = data.prevName;
    }
    body = { title: data.name, documentNumber: data.documentNumber };
  } else if (data.description) {
    body = { title: data.name, description: data.description };
  } 
  else if (data.shortName) {
    body = { title: data.name, shortTitle: data.shortName };
  }else if (data.category.indexOf('/sports') !== -1) {
    body = { title: data.name, titleGenitive: data.titleGenitive, titleDative: data.titleDative };
  } else {
    body = { title: data.name };
  }

  return axios
    .put(constants.API_URL + '/dictionaries/' + data.category + '/' + data.id, body, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};


const editShowDictionaries =(data)=>{
  return axios.put(constants.API_URL + '/dictionaries/users-list-permissions/show/', data, {
    headers: authHeader(),
  })
}

const addDictionariesItem = (data) => {
  let body = {};
  if (data.category === 'ministry-executives') {
    body.firstName = data.firstName;
    body.secondName = data.secondName;
    body.lastName = data.lastName;
    body.position = data.position;
  } 
  else if(data.category==='users-list-permissions'){
    body=data;
  }  
  else {
    body.title = data.name;

    if (data.description) {
      body.description = data.description;
    }
    if (data.titleGenitive) {
      body.titleGenitive = data.titleGenitive;
    }
    if (data.titleDative) {
      body.titleDative = data.titleDative;
    }
  }

  return axios
    .post(
      constants.API_URL + '/dictionaries/' + data.category,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const getDictionariesItem = (data) => {
  return axios
    .get(constants.API_URL + '/dictionaries/' + data.category + '/' + data.id)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getDictionariesList,
  deleteDictionariesItem,
  editDictionariesItem,
  addDictionariesItem,
  getDictionariesItem,
  editShowDictionaries
};
