import { APPLICATIONGETLIST_SUCCESS, APPLICATIONGETITEM_SUCCESS } from './types';

import ApplicationService from '../services/application.service';

export const getApplicationsList = (data, page) => async (dispatch) => {
  try {
    const response = await ApplicationService.getApplicationsList(data, page);
    if (response && response.items) {
      dispatch({
        type: APPLICATIONGETLIST_SUCCESS,
        list: response.items,
        total: response.total,
        offset: response.offset,
      });
      return response.items;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getApplicationItem = (data) => (dispatch) => {
  return ApplicationService.getApplicationItem(data).then(
    (response) => {
      dispatch({
        type: APPLICATIONGETITEM_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    },
  );
};
