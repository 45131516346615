import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { PAGE_SIZE_APPLICATIONS } from "../../constants/const";
import { getOrganisationsSubmissionList } from "../../redux/actions/organisations";
import { selectRegions } from "../../redux/selectors/dictionaries";

const PAGE_SIZE = 100;

const OrgPage = ()=>{
    const [currentPage, setCurrentPage] = useState(1);
    const [ready,setReady]=useState(false);
    const dispatch = useDispatch();
    const { organisations } = useSelector((state) => state);
    const [search, setSearch] = useState('');
    const [filterForm, setFilterForm] = useState([]);
    const [filter, setFilter] = useState(false);
    const [formActive, setFormActive] = useState(false);
    const regions = useSelector(selectRegions);

    const getOrganisations = useCallback(
        async (page = currentPage) => {
          const limit = PAGE_SIZE;
          const offset = (page - 1) * limit;
          const sort = { region: 'asc' };
          const filter = {};
          const filterValue = sessionStorage.getItem('orgSearch') || '';
          if (filterValue.length > 2) {
            filter.all = filterValue;
          }
          await dispatch(getOrganisationsSubmissionList({ sort, offset, limit, filter })).then(res=>{
            setReady(true);
          });
        },
        [currentPage],
      );


    const filterClick = () => {
        setFilter(true);
        setFormActive(true);
      };

      const onSearchChange = (e) => {
        const value = e.target.value || '';
        setSearch(value);
        setCurrentPage(1);
        sessionStorage.setItem('orgSearch', value.trim());
        if (value.trim().length > 2 || !value.length) {
          void getOrganisations(1);
        }
      };

    useEffect(() => {
        if(!ready){
            getOrganisations();
        }
    }, [ready]);

    if(ready){
        return(
            <div className="main public-page">
            <div
              className="public-sub-header"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <h3>Публічний реєстр присвоєння спортивних звань</h3>
              {/* <button
                className={
                  filterForm && filterForm.length > 0
                    ? 'icon-text-btn-secondary filter filter-on'
                    : 'icon-text-btn-secondary filter'
                }
                style={{ marginTop: '30px' }}
                onClick={filterClick}
              >
                Фільтр
              </button> */}
            </div>
      
            <h1 className="public-header">
              Зареєстрованих організацій {!!organisations.total && `(${organisations.total})`}
            </h1>
            <label className="search" style={{ marginBottom: '30px' }}>
            <div className="btn" style={{ float: 'none' }}>
          <input
            className="search-input"
            type="text"
            onInput={onSearchChange}
            defaultValue={search}
            placeholder="Пошук (мінімум 3 символа)"
          />
          <i className="icon search-icon"></i>
        </div>
            </label>
            <div className="public-table" style={{ overflowX: 'auto' }}>
              <table className="public-table-cust">
              <thead>
          <tr>
            <th></th>
            <th>Область</th>
            <th>Коротка назва</th>
            <th>Повна назва</th>
            <th>ЄДРПОУ</th>
            <th>ПІБ керівника</th>
          </tr>
        </thead>
        <tbody>
          {!!organisations.items?.length &&
            organisations.items.map((organisation) => {
              return (
                <tr id={organisation.id} key={organisation.id}>
                  <td></td>
                  <td>
                    {' '}
                    {regions.map((region) => {
                      if (region.id === organisation.regionId) {
                        return region.title;
                      }
                    })}
                  </td>
                  <td>{organisation.shortName}</td>
                  <td>{organisation.name}</td>
                  <td>{organisation.code || '----'}</td>
                  <td>{organisation.requestedByName}</td>
                </tr>
              );
            })}
          {!organisations.length && (
            <tr>
              <td colSpan="9" />
            </tr>
          )}
        </tbody>
                {organisations && organisations.total > PAGE_SIZE && (
                  <tfoot className="">
                    <tr>
                      <td colSpan="8">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={organisations.total}
                        pageSize={PAGE_SIZE}
                        onPageChange={async (page) => {
                            await setCurrentPage(page);
                            await getOrganisations(page);
                        }}
                        />
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
            <Footer />
          </div>
        )
    }else{
        return(
            <Loader/>
        )
    }
    
    

}

export default OrgPage;