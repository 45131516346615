import {
  DICTIONARIESLIST_SUCCESS,
  DICTIONARIESLIST_FAIL,
  DICTIONARIESDELETE_SUCCESS,
  DICTIONARIESDELETE_FAIL,
  DICTIONARIESEDIT_SUCCESS,
  DICTIONARIESEDIT_FAIL,
  DICTIONARIESADD_SUCCESS,
  DICTIONARIESADD_FAIL,
  DICTIONARIESITEM_SUCCESS,
  DICTIONARIESITEM_FAIL,
} from './types';

import DictionariesService from '../services/dictionaries.service';

export const getDictionariesList = (data) => (dispatch) => {
  return DictionariesService.getDictionariesList(data).then(
    (response) => {
      dispatch({
        type: DICTIONARIESLIST_SUCCESS,
        list: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: DICTIONARIESLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getDictionariesListWithout = (data) => (dispatch) => {
  return DictionariesService.getDictionariesList(data).then(
    (response) => {
      return response.items;
    },
    (error) => {
      dispatch({
        type: DICTIONARIESLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const editShowDictionaries = (data) => (dispatch) => {
  return DictionariesService.editShowDictionaries(data).then(
    (response) => {
      return response.items;
    },
    (error) => {
      dispatch({
        type: DICTIONARIESLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};


export const deleteDictionariesItem = (data) => (dispatch) => {
  return DictionariesService.deleteDictionariesItem(data).then(
    (response) => {
      dispatch({
        type: DICTIONARIESDELETE_SUCCESS,
      });
    },
    (error) => {
      dispatch({
        type: DICTIONARIESDELETE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
export const editDictionariesItem = (form) => (dispatch) => {
  return DictionariesService.editDictionariesItem(form).then(
    (response) => {
      dispatch({
        type: DICTIONARIESEDIT_SUCCESS,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: DICTIONARIESEDIT_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const addDictionariesItem = (form) => (dispatch) => {
  return DictionariesService.addDictionariesItem(form).then(
    (response) => {
      dispatch({
        type: DICTIONARIESADD_SUCCESS,
        list: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: DICTIONARIESADD_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getDictionariesItem = (data) => (dispatch) => {
  return DictionariesService.getDictionariesItem(data).then(
    (response) => {
      dispatch({
        type: DICTIONARIESITEM_SUCCESS,
        list: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: DICTIONARIESITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
