import {
  COVERGETLIST_SUCCESS,
  COVERGETLIST_FAIL,
  COVERCREATE_SUCCESS,
  COVERCREATE_FAIL,
  COVERDELETE_SUCCESS,
  COVERDELETE_FAIL,
  COVERUPDATE_SUCCESS,
  COVERUPDATE_FAIL,
  COVERGETITEM_SUCCESS,
  COVERGETITEM_FAIL,
} from './types';

import CoverService from '../services/cover.service';

export const getAppCoverList = (applicationId, pagination) => (dispatch) => {
  return CoverService.getCoverList(applicationId, pagination).then(
    (response) => {
      dispatch({
        type: COVERGETLIST_SUCCESS,
        list: response.items,
        total: response.total,
        offset: response.offset,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERGETLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const createCover = (data) => (dispatch) => {
  return CoverService.addCoverItem(data).then(
    (response) => {
      dispatch({
        type: COVERCREATE_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: COVERCREATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const updateCover = (data) => (dispatch) => {
  return CoverService.updateCoverItem(data).then(
    (response) => {
      dispatch({
        type: COVERUPDATE_SUCCESS,
        list: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERUPDATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getCoverItem = (data) => (dispatch) => {
  return CoverService.getCoverItem(data).then(
    (response) => {
      dispatch({
        type: COVERGETITEM_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: COVERGETITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const deleteCover = (data) => (dispatch) => {
  return CoverService.deleteCoverItem(data).then(
    (response) => {
      dispatch({
        type: COVERDELETE_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: COVERDELETE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
