export const ROLE_HEAD_EMPLOYEE = 'headEmployee';
export const ROLE_HEAD_TRUSTED_EMPLOYEE = 'headTrustedEmployee';
export const ROLE_ADMIN = 'admin';
export const ROLE_REGION_SPORT = 'organisationType-1';

export const PAGE_SIZE_APPLICATIONS = 25;
export const PAGE_SIZE_CERTIFICATES = 25;

export const ORGANISATION_ID_OLYMPIC_TRAINERS = 'f3386f87-8375-4804-bc07-8d267cbd64e1';
export const ORGANISATION_ID_NON_OLYMPIC_TRAINERS = '15609055-7ee9-4cc8-8d3e-7a85aa131c6f';
export const ORGANISATION_ID_INVASPORT = '4549d80b-ebf6-4ba7-a446-fc095fa906b0';

export const OrgType = {
  SubmissionSubject: 0,
  RegionSport: 1,
  MinYouthOffice: 2,
  DepartmentOlympic: 3,
  DepartmentNonOlympic: 4,
  DepartmentInvasport: 5,
  DepartmentSection: 6,
  TrainersFullTime: 7,
  TrainersCurators: 8,
  UkrDisabilityFederation: 9,
  RewardSport: 10,
  Administrators: 99,
};

export const AppStatus = {
  Draft: 0,
  SubmissionSubjectFixing: 1,
  RegionSportReview: 2,
  MinYouthOfficeReview: 3,
  DepartmentReview: 4,
  DepartmentSectionReview: 5,
  TrainerReview: 6,
  TrainerFixing: 7,
  DepartmentSectionHeadEmployeeReview: 8,
  DepartmentHeadEmployeeReview: 9,
  DepartmentInvasportReview: 10,
  DisabilityFederationReview: 11,
  DisabilityFederationFixing: 12,
  DepartmentInvasportHeadEmployeeReview: 13,
  RewardSportReview: 14,
  RewardSportCommission: 15,
  Rejected: 16,
  Accepted: 17,
  OrderProjectReady: 18,
  OrderPassed: 19,
  CategoryAssigned: 20,
  CertificateReady: 21,
  CertificateGiven: 22,
  CommissionReject: 23,
  CommissionApproved: 24,
};

export const SportType = {
  Olympic: 0,
  NonOlympic: 1,
  Disability: 2,
  Other: 3,
};
