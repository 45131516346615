import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CODE_SUCCESS,
  CODE_FAIL,
  SET_MESSAGE,
  RESTORE_SUCCESS,
  RESTORE_FAIL,
  DONE_REFRESHING_TOKEN,
  REFRESHING_TOKEN,
  USER_DATA_LOADED,
  USER_ORGANISATION_LOADED,
} from './types';

import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import OrganisationsService from '../services/organisations.service';

export const register = (form) => (dispatch) => {
  return AuthService.register(form).then(
    (response) => {
      if (response.statusText !== 'OK' || response.data.errors) {
        dispatch({
          type: REGISTER_FAIL,
          payload: response.data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data,
        });
        return response.data;
      } else {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data,
        });
        return response.data;
      }
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.msg) ||
        error.msg ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
export const restore = (data) => (dispatch) => {
  return AuthService.restore(data).then(
    (response) => {
      dispatch({
        type: RESTORE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: RESTORE_FAIL,
        payload: error.response,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return error.response.data;
    },
  );
};
export const getCode = (data) => (dispatch) => {
  return AuthService.getCode(data).then(
    (response) => {
      dispatch({
        type: CODE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CODE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const refreshToken = (dispatch, token) => {
  let freshTokenPromise = AuthService.refresh(token)
    .then((t) => {
      dispatch({
        type: DONE_REFRESHING_TOKEN,
      });

      // dispatch(saveAppToken(t.token));

      return t && t.token ? Promise.resolve(t.token) : logout();
    })
    .catch((e) => {
      console.log('error refreshing token', e);

      dispatch({
        type: DONE_REFRESHING_TOKEN,
      });
      logout();
      return Promise.reject(e);
    });
  dispatch({
    type: REFRESHING_TOKEN,
    freshTokenPromise,
  });
  return freshTokenPromise;
};

export const code = (data) => (dispatch) => {
  return AuthService.code(data).then(
    (data) => {
      if (typeof data !== 'string') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return data;
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    },
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      if (typeof data !== 'string') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return data;
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    },
  );
};
export const loginOrganisation = (body) => (dispatch) => {
  return AuthService.loginOrganisation(body).then(
    (data) => {
      if (typeof data !== 'string') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return data;
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    },
  );
};


export const impersonate = (userId) => (dispatch) => {
  return AuthService.impersonate(userId).then(
    (data) => {
      if (typeof data !== 'string') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return data;
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    },
  );
};

export const fetchUserData = (id) => (dispatch) => {
  return UserService.getById(id).then((data) => {
    dispatch({
      type: USER_DATA_LOADED,
      payload: data,
    });
  });
};

export const fetchUserOrganisation = (id) => (dispatch) => {
  return OrganisationsService.getOrganisationsItem(id).then((data) => {
    dispatch({
      type: USER_ORGANISATION_LOADED,
      payload: data,
    });
  });
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
