import axios from 'axios';
import authHeader from './auth-header';

import constants from '../../constants/constant.json';

const testRosterFile = (body) => {
  return axios
    .post(constants.API_URL + '/register/test', body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('testRosterFile error');
      return e.response.data;
    });
};

const startRosterFile = (body) => {
  return axios
    .post(constants.API_URL + '/register/activate',body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const uploadRoster = (data) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data);

  return axios({
    method: 'post',
    url: constants.API_URL + `/register/upload`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export default {
  testRosterFile,
  uploadRoster,
  startRosterFile,
};
