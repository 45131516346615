import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const addCoverDocument = (body) => {
  return axios
    .post(
      constants.API_URL +
        `/applications/${body.form.applicationId}/cover-letters/${body.form.id}/documents`,
      body.document,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getCoverDocument = (body) => {
  return axios
    .get(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.coverId}/documents/${body.id}`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const deleteCoverDocument = (body) => {
  return axios
    .delete(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.coverId}/documents/${body.id}`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getCoverDocumentsList = (body) => {
  return axios
    .get(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.id}/documents?offset=0&limit=20`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  getCoverDocumentsList,
  deleteCoverDocument,
  getCoverDocument,
  addCoverDocument,
};
