import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisationsUnconfirmedList } from '../../../redux/actions/organisations';
import Form from '../../DirectoriesPage/Form/Form';
import { selectOrganisations } from '../../../redux/selectors/organisations';
import OrganisationsService from '../../../redux/services/organisations.service';

export const Unmoderated = () => {
  const dispatch = useDispatch();
  const organisations = useSelector(selectOrganisations);
  const [form, setForm] = useState({});
  const [formActive, setFormActive] = useState(false);
  const [reas, setReas] = useState(false);
  const { fullList } = useSelector((state) => state);
  const getOrganisations = useCallback(async () => {
    await dispatch(getOrganisationsUnconfirmedList())
      .then(() => {})
      .catch(() => {});
  }, []);

  const rejectClick = useCallback(async (e) => {
    let data = { type: 'reject', id: e.target.parentElement.id, reason: '' };
    setReas(true);
    setForm(data);
    setFormActive(true);
  }, []);

  const rejectConfirm = useCallback(async () => {
    await OrganisationsService.changeStatusUnconfirmedOrganisation(form);
    getOrganisations();
    setFormActive(false);
  }, [form]);

  const acceptClick = useCallback(async (e) => {
    await OrganisationsService.changeStatusUnconfirmedOrganisation({
      type: 'accept',
      id: e.target.parentElement.id,
    });
    getOrganisations();
  }, []);

  useEffect(() => {
    getOrganisations();
  }, []);

  return (
    <>
      <table className="public-table-cust">
        <thead>
          <tr>
            <th></th>
            <th>ЄДРПОУ&nbsp;код</th>
            <th>Область</th>
            <th>Коротка назва</th>
            <th>Повна назва</th>
            <th>Дата</th>
            <th>ПІБ</th>
            {/* <th><span>Коментар (для вказання причини відхилення)</span></th> */}
            <th style={{ width: '100px' }}></th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {organisations && organisations.length > 0 ? (
            organisations.map((item) => {
              return (
                <tr id={item.id} key={item.id}>
                  <td></td>
                  <td>{item.code}</td>
                  <td>
                    {' '}
                    {fullList &&
                      fullList[0] &&
                      fullList[0].map((el) => {
                        if (el.id === item.regionId) {
                          return el.title;
                        }
                      })}
                  </td>
                  <td>{item.shortName}</td>
                  <td>{item.name}</td>
                  <td>{item.createdAt}</td>
                  <td>{item.requestedByName}</td>
                  <td>
                    <a href={item.scan} target="_blank" className="document-link">
                      Cтатут
                    </a>
                  </td>
                  <td id={item.id} className="icon-row">
                    <i className="icon accept-icon" accessKey="accept" onClick={acceptClick}></i>
                    <i className="icon decline-icon" accessKey="reject" onClick={rejectClick}></i>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">
                <h2>Немає організації для модерування</h2>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {formActive && (
        <Form
          reas={reas}
          setFormActive={setFormActive}
          rejectConfirm={rejectConfirm}
          form={form}
          setForm={setForm}
        />
      )}
    </>
  );
};
