import { combineReducers } from 'redux';
import auth from './auth';
import file from './file';
import message from './message';
import users from './users';
import categories from './categories';
import dictionaries from './dictionary';
import newDictionaries from './new-dictionaries';
import applications from './applications';
import organisations from './organisations';
import mates from './mates';
import fullList from './full-list';
import documents from './documents.js';
import departments from './department';
import history from './history';
import applicationMessage from './applicationMessage';
import notification from './notification';
import coverDocuments from './coverDocuments';
import cover from './cover';
import coverHistory from './coverHistory';
import certificate from './certificate';
import uniqCertificate from './uniqCertificate';
import headEmployee from './headEmployeeName';
import configuration from './configuration';
import instructions from './instructions';
import assign from './assign';
import support from './support';
import list from './list';


export default combineReducers({
  auth,
  message,
  users,
  file,
  categories,
  dictionaries,
  newDictionaries,
  instructions,
  applications,
  organisations,
  departments,
  mates,
  fullList,
  list,
  documents,
  history,
  assign,
  support,
  applicationMessage,
  notification,
  cover,
  coverDocuments,
  coverHistory,
  certificate,
  uniqCertificate,
  headEmployee,
  configuration,
});
