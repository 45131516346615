import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createCover, getAppCoverList } from '../../../redux/actions/cover';
import { selectCurrentOrganisation } from '../../../redux/selectors/organisations';

const ApplyList = () => {
  const applicationId = useParams().id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.cover);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const { currentApplication } = useSelector((state) => state.applications);

  const { fullList } = useSelector((state) => state);
  const getCoverList = () => {
    dispatch(getAppCoverList(applicationId));
  };

  const clickHandler = (event) => {
    event.stopPropagation();

    history.push(`/applications/${applicationId}/newList/${event.target.id}`);
  };

  const addClick = useCallback(async () => {
    let data = {
      applicationId: applicationId,
    };
    await dispatch(createCover(data))
      .then((response) => {
        history.push(`/applications/${applicationId}/newList/${response.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    getCoverList();
  }, []);

  return (
    <div className="create-application-container">
      {currentOrganisation &&
        currentOrganisation.type === 0 &&
        currentApplication.officeCode &&
        currentApplication.officeCode.length > 0 && (
          <button
            className="icon-text-btn-secondary"
            style={{ width: '250px', marginBottom: '30px' }}
            onClick={addClick}
          >
            <i className="add-icon"></i> Додати листа
          </button>
        )}
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <span>№ і статус</span>
            </th>
            <th>
              <span>Тема листа</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((el) => {
              if (el.status === 0 && currentOrganisation && currentOrganisation.type === 0) {
                return (
                  <tr id={el.id} onClick={clickHandler}>
                    <td id={el.id}></td>
                    <td id={el.id}>
                      {el.officeCode && el.officeCode.length > 0
                        ? `№ - ${el.officeCode}  `
                        : '№ - не присвоєно  '}
                      {fullList &&
                        fullList[7] &&
                        fullList[7].map((elem) => {
                          if (elem.status == el.status) {
                            return elem.title;
                          }
                        })}
                    </td>
                    <td id={el.id}>{el.subject}</td>
                    <td id={el.id}></td>
                  </tr>
                );
              } else {
                return (
                  <tr id={el.id} onClick={clickHandler}>
                    <td id={el.id}></td>
                    <td style={{ lineHeight: '24px' }} id={el.id}>
                      {el.officeCode && el.officeCode.length > 0
                        ? `№ - ${el.officeCode}  `
                        : '№ - не присвоєно  '}{' '}
                      <br />
                      {fullList &&
                        fullList[7] &&
                        fullList[7].map((elem) => {
                          if (elem.status == el.status) {
                            return elem.title;
                          }
                        })}
                    </td>
                    <td id={el.id}>{el.subject}</td>
                    <td id={el.id}></td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ApplyList;
