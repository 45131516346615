import { UPLOADFILE_SUCCESS, UPLOADFILE_FAIL, SET_MESSAGE } from './types';

import FileService from '../services/file.service';

export const uploadInstruction = (data) => (dispatch) => {
  return FileService.uploadInstruction(data).then((response) => {
    dispatch({
      type: UPLOADFILE_SUCCESS,
    });
    return response.data;
  });
};

export const uploadFile = (data) => (dispatch) => {
  return FileService.uploadFile(data).then(
    (response) => {
      dispatch({
        type: UPLOADFILE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response,
      });

      return response.data;
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPLOADFILE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
