import { RETRIEVE_USERS, DELETE_USERS } from '../actions/types';

const initialState = {};

export default function usersReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_USERS:
      return { ...state, ...payload };
    case DELETE_USERS:
      return state
    default:
      return state;
  }
}
