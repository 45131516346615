import React, { useEffect, useState } from 'react';
import { useMessage } from '../../hooks/message.hook';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FirstPage from './FirstPage/FirstPage';
import SecondPage from './SecondPage/SecondPage';
import { getCode, register } from '../../redux/actions/auth';

const RegistrationPage = () => {
  const { message, errMsg } = useMessage();
  const history = useHistory();
  const dispatch = useDispatch();

  //#endregion

  const [nextPage, setNextPage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [code, setCode] = useState(false);
  const [readyForChange, setReadyForChange] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [form, setForm] = useState({
    email: '',
    password: '',
    code: '',
    firstName: '',
    secondName: '',
    lastName: '',
    unzr: '',
  });

  const [validForm, setValidForm] = useState({
    email: false,
    password: true,
    code: true,
    firstName: true,
    secondName: true,
    lastName: true,
    unzr: true,
  });

  const resiveCode = async (event) => {
    try {
      event.preventDefault();
      setSeconds(60);
      setReadyForChange(true);
      dispatch(getCode({ email: form.email }))
        .then((res) => {})
        .catch((e) => {
          console.log(e);
        });
      setCode(true);
    } catch (e) {
      console.log(e);
    }
  };
  const handleClick = () => {
    history.push('/login');
  };

  const changeHandler = (event) => {
    const emailCheck =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textCheck = /^[бвгґджзклмнпрстфхцчшщйаеєиіїоуюяь\'-`]+$/;
    const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{6,30}$/;
    const unzrCheck = /^\d{8}-\d{5}$/;
    const codeCheck = /^[0-9]{6}$/;
    message(null);

    switch (event.target.name) {
      case 'email':
        if (code) {
          setReadyForChange(true);
        }
        setCode(false);
        if (emailCheck.test(String(event.target.value).toLowerCase())) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case 'firstName':
      case 'secondName':
      case 'lastName':
        const isValid = textCheck.test(String(event.target.value).toLowerCase());
        setValidForm({ ...validForm, [event.target.name]: isValid });
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case 'password':
        if (passwordCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case 'unzr':
        if (unzrCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case 'code':
        if (codeCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      default:
        break;
    }
  };
  const registerHandler = async (event) => {
    event.preventDefault();
    dispatch(register(form))
      .then((res) => {
        if (res && res.errors && res.errors.length > 0) {
          message(res.errors);
        } else {
        }
      })
      .catch((e) => {
        history.push('/login');
      });
  };
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  if (
    document.getElementsByClassName('sidebar') &&
    document.getElementsByClassName('sidebar')[0] &&
    document.getElementsByClassName('sidebar')[0].hidden !== true
  ) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }
  return (
    <div className="auth main">
      <form className="mainBlock" onSubmit={registerHandler}>
        <div>
          <h1>Реєстрація</h1>
          {!nextPage ? <h3>крок 1 із 2</h3> : <h3>крок 2 із 2</h3>}
        </div>
        {!nextPage && (
          <FirstPage
            form={form}
            changeHandler={changeHandler}
            setNextPage={setNextPage}
            validForm={validForm}
            errMsg={errMsg}
            handleClick={handleClick}
          ></FirstPage>
        )}
        {nextPage && (
          <SecondPage
            form={form}
            changeHandler={changeHandler}
            passwordShown={passwordShown}
            setPasswordShown={setPasswordShown}
            resiveCode={resiveCode}
            registerHandler={registerHandler}
            setNextPage={setNextPage}
            validForm={validForm}
            seconds={seconds}
            readyForChange={readyForChange}
            code={code}
            errMsg={errMsg}
          ></SecondPage>
        )}
      </form>
    </div>
  );
};

export default RegistrationPage;
