import {
  COVERHISTORYCREATE_SUCCESS,
  COVERHISTORYCREATE_FAIL,
  COVERHISTORYLIST_SUCCESS,
  COVERHISTORYLIST_FAIL,
  COVERHISTORYITEM_SUCCESS,
  COVERHISTORYITEM_FAIL,
  COVERHISTORYROLLBACK_SUCCESS,
  COVERHISTORYROLLBACK_FAIL,
} from './types';

import coverHistoryService from '../services/coverHistory.service';

export const getCoverHistoryList = (data) => (dispatch) => {
  return coverHistoryService.getHistoryPointList(data).then(
    (response) => {
      dispatch({
        type: COVERHISTORYLIST_SUCCESS,
        list: response.items,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERHISTORYLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const createCoverHistory = (data) => (dispatch) => {
  return coverHistoryService.createHistoryPoint(data).then(
    (response) => {
      dispatch({
        type: COVERHISTORYCREATE_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: COVERHISTORYCREATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const rollbackCoverHistory = (data) => (dispatch) => {
  return coverHistoryService.rollbackHistoryPoint(data).then(
    (response) => {
      dispatch({
        type: COVERHISTORYROLLBACK_SUCCESS,
        item: response,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERHISTORYROLLBACK_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getCoverHistoryItem = (data) => (dispatch) => {
  return coverHistoryService.getHistoryPointItem(data).then(
    (response) => {
      dispatch({
        type: COVERHISTORYITEM_SUCCESS,
        item: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: COVERHISTORYITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
