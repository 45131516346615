import DictionariesService from './dictionaries.service';

const getFullList = async (data) => {
  let resultArr = [];
  let array = [...data];
  for (let i = 0; i < data.length; i++) {
    await DictionariesService.getDictionariesList(data[i])
      .then((res) => {
        resultArr[i] = res.items;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  for (let i = 0; i < resultArr[1].length; i++) {
    let data = `sport-categories/${resultArr[1][i].id}/sports`;
    await DictionariesService.getDictionariesList(data)
      .then((res) => {
        //    resultArr[counter] = res.items;
        resultArr[1][i].sports = [...res.items];
        //    resultArr[1][i][counter] =  res.items
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return resultArr;
};

export default {
  getFullList,
};
