import {
  ORGANISATIONSLIST_SUCCESS,
  ORGANISATIONSITEM_SUCCESS,
  ORGANISATIONSADD_SUCCESS,
} from '../actions/types';

const initialState = [];

export default function organisationsReducer(state = initialState, action) {
  const { type, list, total, item } = action;

  switch (type) {
    case ORGANISATIONSLIST_SUCCESS:
      return {
        items: [...list],
        total,
      };
    case ORGANISATIONSITEM_SUCCESS:
      return { currentOrganisation: item };
    case ORGANISATIONSADD_SUCCESS:
      return {
        items: [...state.items, list],
        total,
      };
    default:
      return state;
  }
}
