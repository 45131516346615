import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import {
  setAllReadedNotification,
  getNotificationList,
  setReadedNotification,
} from '../../redux/actions/notification';

const MessagePage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { notification } = useSelector((state) => state);
  const [select, setSelect] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [ready, setReady] = useState(false);
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }

  const getNotifications = useCallback(async () => {
    if (currentUser && currentUser.payload.id) {
      setForm({ id: currentUser.payload.id });
    }
    await dispatch(getNotificationList({ id: currentUser.payload.id }))
      .then((response) => {})
      .catch(() => {});
    setReady(true);
  }, [currentUser]);

  useEffect(() => {
    getNotifications();
  }, []);

  const openClick = (e) => {
      dispatch(
        setReadedNotification({ id: currentUser.payload.id, itemId: e.currentTarget.id }),
      ).then((res) => {
        history.push(`/applicationsItem/${e.target.accessKey}`);
      });
     
  };

  const readAll=(e)=>{
    dispatch(setAllReadedNotification({ id: currentUser.payload.id, itemId: e.currentTarget.id }),
    ).then((res) => {
      getNotifications();
    });
  }

  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      <h1 className="public-header">Повідомлення</h1>
      <label className="search">
            <button className='simple-button' onClick={readAll}>Прочитати все</button>
      </label>
      <div className="public-table">
        <table className="public-table-cust roster">
          <thead>
            <tr className="roster">
              <th> </th>
              <th>Відправник</th>
              <th>Повідомлення</th>
              <th className="selected">Час отримання</th>
            </tr>
          </thead>
          <tbody>
            {notification &&
              notification.list &&
              notification.list.length > 0 &&
              notification.list.map((el) => {
                return (
                  <React.Fragment key={el.id}>
                    {!el.isRead && el.sender && (
                      <tr
                        id={el.id}
                        className={'selected notification-item'}
                      >
                        <td></td>
                        <td>
                          <span style={{ fontWeight: 'bold', lineHeight: '30px' }}>
                            {el.sender?.lastName} {el.sender?.firstName}
                          </span>
                          <br />
                          <span>
                            {' '}
                            {el.sender.isHeadEmployee && ' Керівник '}
                            {el.sender.isHeadTrustedEmployee && ' Уповноважена особа '}
                            {!el.sender.isHeadEmployee &&
                              !el.sender.isHeadTrustedEmployee &&
                              ' Співробітник '}
                            {el.sender.name}
                          </span>
                        </td>
                        <td className="subject">{el.subject}</td>
                        <td className="subject">{el.createdAt}</td>
                      </tr>
                    )}
                    {el.isRead && el.sender && (
                      <tr
                        id={el.id}
                        className={
                          select == el.id
                            ? 'selected notification-item readed'
                            : 'notification-item readed'
                        }
                        onClick={select == el.id ? () => setSelect('') : () => setSelect(el.id)}
                      >
                        <td> </td>
                        <td className="subject">
                          <span style={{ lineHeight: '30px' }}>
                            {el.sender?.lastName} {el.sender?.firstName}
                          </span>
                          <br />
                          {el.sender.isHeadEmployee && ' Керівник '}
                          {el.sender.isHeadTrustedEmployee && ' Уповноважена особа '}
                          {!el.sender.isHeadEmployee &&
                            !el.sender.isHeadTrustedEmployee &&
                            ' Співробітник '}
                          {el.sender.shortName}
                        </td>
                        <td className="subject">{el.subject}</td>
                        <td className="subject">{el.createdAt}</td>
                      </tr>
                    )}
                    {/* {select == el.id && el.sender && ( */}
                      <tr>
                        <td></td>
                        <td style={{ paddingTop: '20px' }} colSpan="2">
                          {el.message}
                          <br />
                         {el.applicationId &&  <button
                            accessKey={el.applicationId}
                            id={el.id}
                            className="notification-button"
                            onClick={openClick}
                          >
                            Перейти до заявки
                          </button>}
                        </td>
                        <td></td>
                      </tr>
                    {/* )} */}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MessagePage;
