import { HEADEMPLOYEEGETITEM_SUCCESS } from '../actions/types';

const initialState = [];

export default function organisationReducer(state = initialState, action) {
  const { type, item } = action;
  switch (type) {
    case HEADEMPLOYEEGETITEM_SUCCESS:
      return { currentHeadEmployee: item };
    default:
      return state;
  }
}
