import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const createHistoryPoint = (body) => {
  return axios
    .post(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.id}/history-points`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};
const getHistoryPointList = (body) => {
  return axios
    .get(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.id}/history-points?offset=0&limit=100&sort[createdAt]=asc`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const getHistoryPointItem = (body) => {
  return axios
    .get(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.id}/history-points/${body.historyId}`,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const rollbackHistoryPoint = (body) => {
  return axios
    .post(
      constants.API_URL +
        `/applications/${body.applicationId}/cover-letters/${body.id}/history-points/rollback`,
      body,
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  createHistoryPoint,
  getHistoryPointList,
  getHistoryPointItem,
  rollbackHistoryPoint,
};
