import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';


const getAssignList = (data) => {
    return axios
      .get(constants.API_URL + '/applications/' + data.id +'/signature-documents?sort[createdAt]=desc', { headers: authHeader() })
      .then((response) => response.data)
      .catch((e) => {
        console.log(e);
      });
  };

export default {
    getAssignList  
}