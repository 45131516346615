import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginOrganisation, logout } from '../../redux/actions/auth';
import { selectCurrentUser } from '../../redux/selectors/auth';

const Avatar = () => {
  const [isActive, setActive] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const logoutHandler = useCallback(() => {
    history.push('/applications');
    dispatch(logout());
  }, [dispatch]);

  const toggleMenu = () => {
    isActive ? setActive(false) : setActive(true);
  };
  const closeMenu = () => {
    setActive(false);
  };

  const changeOrganisationClick =(e)=>{
      let data = currentUser.userOrganization[e.target.id];
      console.log(data);
      dispatch(loginOrganisation(data)).then(res=>{
        console.log(res)
      })
  }

  return !currentUser ? null : (
    <div className="avatar-position">
      {currentUser.lastName} {currentUser.firstName} | {currentUser.shortName}
      {!currentUser.picture && (
        <button className="avatar" onClick={toggleMenu}>
          {currentUser.lastName && currentUser.lastName.charAt(0)}
          {currentUser.firstName && currentUser.firstName.charAt(0)}
        </button>
      )}
      {currentUser.picture && (
        <img
          src={currentUser.picture}
          className="avatar avatar-picture"
          onClick={toggleMenu}
          alt=""
        />
      )}
      <div className={isActive ? 'logMenu active' : 'logMenu'} onMouseLeave={closeMenu}>
        <div className="avatar-text">
          {`${currentUser.lastName} ${currentUser.firstName} ${currentUser.secondName}`.trim()}
          <br />
          <div className="prompt">{currentUser.email}</div>
          <br/>
          {currentUser.userOrganization.length>1&&
          currentUser.userOrganization.map((el,index)=>{
            if(currentUser.organisation_id==el.organisationId){
              return<></>
            }else{
              return(
                <>
                <div style={{cursor:'pointer', fontWeight:'bold'}} className="prompt" id={index} onClick={changeOrganisationClick}>
                  {el.shortName}
                </div>
                <br/>
                </>
              )
            }
          })
          }

        </div>
        <button className="icon-text-btn-secondary exit" onClick={logoutHandler}>
          Вийти
        </button>
      </div>
    </div>
  );
};

export default Avatar;
