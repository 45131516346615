import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addDepartmentsItem,
  deleteDepartmentsItem,
  editDepartmentsItem,
} from '../../../redux/actions/department';
import { getOrganisationsList } from '../../../redux/actions/organisations';
import Form from '../../DirectoriesPage/Form/Form';
import OrganisationsService from '../../../redux/services/organisations.service';

const Ministry = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [formActive, setFormActive] = useState(false);
  const history = useHistory();
  const [sport, setSport] = useState(false);
  const [form, setForm] = useState({});
  const [editOrg, setEditOrg] = useState(false);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [alertActive, setAlertActive] = useState(false);
  const [olimp, setOlimp] = useState(false);
  const [noolimp, setNoolimp] = useState(false);

  const mateClick = (event) => {
    history.push(`/mates/${event.target.parentElement.id}`);
    sessionStorage.setItem('backLink', '/organisations/ministry');
  };

  const getOrganisations = useCallback(async () => {
    const sort = { type: 'desc' };
    await dispatch(getOrganisationsList({ sort }))
      .then((response) => {
        let arr = [[], [], [], [], [], [], [[], []], [], [], [], [], []];
        arr.length = 12;
        response.forEach((element) => {
          if (element.type == 99) {
            arr[11].push(element);
          } else if (
            element.type == 6 &&
            element.parentOrganisationId == '4182ffda-6187-4243-8555-12cd88bbc8cb'
          ) {
            arr[element.type][0].push(element);
          } else if (element.type == 6) {
            arr[element.type][1].push(element);
          } else {
            arr[element.type].push(element);
          }
        });
        console.log(arr);
        setList(arr);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSport = (event) => {
    let data = {
      sports: list[6][event.target.id][event.target.accessKey].sports,
      id: event.target.parentElement.id,
      organisationsId: event.target.parentElement.accessKey,
    };

    if (event.target.id == 0) {
      setNoolimp(false);
      setOlimp(true);
    } else if (event.target.id == 1) {
      setOlimp(false);
      setNoolimp(true);
    }
    setForm(data);
    setSport(true);
    setFormActive(true);
  };

  const handleSportSave = useCallback(
    (event) => {
      dispatch(editDepartmentsItem(form))
        .then((response) => {
          setSport(false);
          setOlimp(false);
          setNoolimp(false);
          getOrganisations();
          setFormActive(false);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [form],
  );
  const handleDelete = useCallback(() => {
    let event = deleteItem;
    let data = {
      id: event.target.parentElement.id,
      organisationsId: event.target.parentElement.accessKey,
    };
    dispatch(deleteDepartmentsItem(data))
      .then((response) => {
        getOrganisations();

        setAlertActive(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [deleteItem]);
  const handleDeleteClick = (event) => {
    setDeleteItem(event);
    setAlertActive(true);
  };
  const addClickDepartmentHandler = useCallback((event) => {
    let data = { organisationsId: event.target.accessKey, type: 6 };
    setSport(false);
    setEdit(false);
    setForm(data);
    setAdd(true);
    setFormActive(true);
  }, []);

  const editClickHandler = useCallback((event) => {
    let data = {
      prevName: event.target.parentElement.outerText,
      prevShortName: event.target.accessKey,
      id: event.target.parentElement.id,
    };
    setForm(data);
    setSport(false);
    setAdd(false);

    setEdit(true);
    setEditOrg(true);
    setFormActive(true);
  }, []);

  const saveEditChangeOrg = useCallback(() => {
    OrganisationsService.updateOrganisation(form).then(() => {
      getOrganisations();

      setEdit(false);
      setFormActive(false);
    });
  }, [form]);

  const saveAddChange = useCallback(() => {
    dispatch(addDepartmentsItem(form))
      .then((response) => {
        getOrganisations();

        setAdd(false);
        setFormActive(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [form]);

  useEffect(() => {
    getOrganisations();
  }, []);

  return (
    <>
      <h2>Державні тренери</h2>

      <div className="organisation-subheader">Підрозділи</div>
      {list && list[2] && list[2][0] && (
        <div className="organisation-subheader-list" id={list[2][0].id}>
          {list[2][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[2][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}

      <h2>Департаменти</h2>

      <div className="organisation-subheader">Підрозділи</div>
      {list && list[3] && list[3][0] && (
        <div className="organisation-subheader-list" id={list[3][0].id}>
          {list[3][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[3][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}
      <div className="organisation-subheader-list-item">
        <div className="organisation-subheader-list first-line">
          <button
            className="icon-text-btn-small "
            accessKey={list && list[3] && list[3][0].id}
            onClick={addClickDepartmentHandler}
          >
            {' '}
            <i className="add-icon"></i>Додати відділ
          </button>
        </div>

        {list &&
          list[6] &&
          list[6][0] &&
          list[6][0].map((el, index) => {
            return (
              <div
                className="directory-subheader-list "
                id={el.id}
                key={el.id}
                accessKey={el.parentOrganisationId}
              >
                {el.name}
                <i className="icon mates-icon" onClick={mateClick}></i>
                <i
                  className="icon edit-icon"
                  accessKey={el.shortName}
                  onClick={editClickHandler}
                ></i>
                <i className="icon sports-icon" accessKey={index} id="0" onClick={handleSport}></i>
                <i className="icon delete-icon" onClick={handleDeleteClick}></i>
              </div>
            );
          })}
          {list &&
          list[6] &&
          list[6][1] &&
          list[6][1].map((el, index) => {
            return (
              <div
                className="directory-subheader-list"
                id={el.id}
                key={el.id}
                accessKey={el.parentOrganisationId}
              >
                {el.name}
                <i className="icon mates-icon" onClick={mateClick}></i>
                <i
                  className="icon edit-icon"
                  accessKey={el.shortName}
                  onClick={editClickHandler}
                ></i>
                <i className="icon sports-icon" accessKey={index} id="1" onClick={handleSport}></i>
                <i className="icon delete-icon" onClick={handleDeleteClick}></i>
              </div>
            );
          })}
      </div>
      {/* {list && list[4] && list[4][0] && (
        <div className="organisation-subheader-list" id={list[4][0].id}>
          {list[4][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[4][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )} */}
      {/* <div className="organisation-subheader-list-item"> */}
        {/* <div className="organisation-subheader-list first-line">
          <button
            className="icon-text-btn-small"
            accessKey={list && list[4] && list[4][0].id}
            onClick={addClickDepartmentHandler}
          >
            {' '}
            <i className="add-icon"></i>Додати відділ
          </button>
        </div> */}
        
      {/* </div> */}
      <h2>Інвaспорт</h2>
      {list && list[5] && list[5][0] && (
        <div className="organisation-subheader-list" id={list[5][0].id}>
          {list[5][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[5][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}
      <div className="organisation-subheader-list-item">
        {list &&
          list[9] &&
          list[9][0] &&
          list[9].map((el) => {
            return (
              <div
                className="directory-subheader-list"
                accessKey={list[5][0].id}
                id={el.id}
                key={el.id}
              >
                {el.name}
                <i className="icon mates-icon" onClick={mateClick}></i>
                <i
                  className="icon edit-icon"
                  accessKey={el.shortName}
                  onClick={editClickHandler}
                ></i>
              </div>
            );
          })}
      </div>

      <h2>Тренери</h2>
      <div className="organisation-subheader">Підрозділи</div>
      {list && list[7] && list[7][0] && (
        <div className="organisation-subheader-list" id={list[7][0].id}>
          {list[7][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[7][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}
      {list && list[8] && list[8][0] && (
        <div className="organisation-subheader-list" id={list[8][0].id}>
          {list[8][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[8][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}

      <h2>Управління роботи з персоналом</h2>
      <div className="organisation-subheader">Підрозділи</div>
      {list && list[10] && list[10][0] && (
        <div className="organisation-subheader-list" id={list[10][0].id}>
          {list[10][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[10][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}

      <h2>Адміністрація сайту</h2>
      <div className="organisation-subheader">Підрозділи</div>
      {list && list[11] && list[11][0] && (
        <div className="organisation-subheader-list" id={list[11][0].id}>
          {list[11][0].name}
          <i className="icon mates-icon" onClick={mateClick}></i>
          <i
            className="icon edit-icon"
            accessKey={list[11][0].shortName}
            onClick={editClickHandler}
          ></i>
        </div>
      )}

      {formActive && (
        <Form
          saveEditChangeOrg={saveEditChangeOrg}
          edit={edit}
          setFormActive={setFormActive}
          saveAddChange={saveAddChange}
          add={add}
          editOrg={editOrg}
          form={form}
          setForm={setForm}
        />
      )}
      {/* setFormActive={setFormActive} saveAddChange={saveAddChange} edit={edit} editOrg={editOrg} add={add} setEdit={setEdit} saveEditChange={saveEditChange} saveEditChangeOrg={saveEditChangeOrg} form={form} setForm={setForm} */}
      {formActive && sport && (
        <Form
          sport={sport}
          olimp={olimp}
          noolimp={noolimp}
          setFormActive={setFormActive}
          saveAddChange={handleSportSave}
          form={form}
          setForm={setForm}
        />
      )}
      {alertActive && (
        <>
          <div className="alert-label">
            <div>Ви впевнені, що хочете видалити?</div>
            <div className="alert-label-buttons">
              <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
                Видалити
              </button>
              <button
                className="round-btn-secondary close"
                onClick={() => setAlertActive(false)}
              ></button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Ministry;
