import {
  APPLICATIONMESSAGEGETLIST_SUCCESS,
  APPLICATIONMESSAGEGETLIST_FAIL,
  APPLICATIONMESSAGECREATE_SUCCESS,
  APPLICATIONMESSAGECREATE_FAIL,
  APPLICATIONMESSAGEDELETE_SUCCESS,
  APPLICATIONMESSAGEDELETE_FAIL,
  APPLICATIONMESSAGEGETITEM_SUCCESS,
  APPLICATIONMESSAGEGETITEM_FAIL,
} from '../actions/types';

const initialState = [];

export default function applicationsMessageReducer(applicationMessage = initialState, action) {
  const { type, list, item, offset, total } = action;
  switch (type) {
    case APPLICATIONMESSAGEGETITEM_SUCCESS:
      return { currentApplication: item };
    case APPLICATIONMESSAGEGETITEM_FAIL:
      return applicationMessage;
    case APPLICATIONMESSAGEGETLIST_FAIL:
      return applicationMessage;
    case APPLICATIONMESSAGEGETLIST_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    case APPLICATIONMESSAGECREATE_FAIL:
      return applicationMessage;
    case APPLICATIONMESSAGEDELETE_SUCCESS:
      return applicationMessage;
    case APPLICATIONMESSAGEDELETE_FAIL:
      return applicationMessage;
    case APPLICATIONMESSAGECREATE_SUCCESS:
      return [{ ...applicationMessage, item }];
    default:
      return applicationMessage;
  }
}
