import {
  INSTRUCTIONSLIST_SUCCESS,
  INSTRUCTIONSLIST_FAIL,
  INSTRUCTIONSDELETE_SUCCESS,
  INSTRUCTIONSDELETE_FAIL,
  INSTRUCTIONSEDIT_SUCCESS,
  INSTRUCTIONSEDIT_FAIL,
  INSTRUCTIONSADD_SUCCESS,
  INSTRUCTIONSADD_FAIL,
} from '../actions/types';

const initialState = [];

export default function dictionariesReducer(instructions = initialState, action) {
  const { type, list } = action;

  switch (type) {
    case INSTRUCTIONSLIST_FAIL:
      return instructions;
    case INSTRUCTIONSLIST_SUCCESS:
      return [...list];
    case INSTRUCTIONSEDIT_SUCCESS:
      return instructions;
    case INSTRUCTIONSDELETE_FAIL:
      return instructions;
    case INSTRUCTIONSDELETE_SUCCESS:
      return instructions;
    case INSTRUCTIONSEDIT_FAIL:
      return instructions;
    case INSTRUCTIONSADD_FAIL:
      return instructions;
    case INSTRUCTIONSADD_SUCCESS:
      return [{ ...instructions, list }];
    default:
      return instructions;
  }
}
