import React, { useEffect } from 'react';
const TestPage = () => {
  const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
  /*
        Ідентифікатор iframe, який завантажує сторінку SignWidget
    */
  const SIGN_WIDGET_ID = 'sign-widget';
  /*
        URI з адресою за якою розташована сторінка SignWidget
    */
  const SIGN_WIDGET_URI = 'https://id.gov.ua/sign-widget/v20220527/';
  // const SIGN_WIDGET_URI = 'https://test.id.gov.ua/sign-widget/v2022testnew/'


  //=============================================================================
  var formParams = new window.EndUser.FormParams();
  formParams.showSignTip = false;

  /*
        Створення об'єкту типу EndUser для взаємодії з iframe, 
        який завантажує сторінку SignWidget
    */

  useEffect(() => {
    var euSign = new window.EndUser(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      window.EndUser.FormType.SignFile,
      formParams,
    );
    // someFunc();

    //  new someFunc.EndUser(
    //     "sign-widget-parent", /* Ідентифікатор батківського елементу */ "sign-widget", /* Ідентифікатор елементу iframe */ "https://id.gov.ua/sign-widget/v20220527/",
    //     /* URI для завантаження iframe */ EndUser.FormType.SignFile /* Тип форми iframe */
    // );
  }, []);

  return (
    <div className="main public-page">
      <div id="sign-widget-parent" style={{ width: '700px', minHeight: '500px' }}></div>
      {/* <div id="sign-widget-parent" style="width:700px;height:500px"></div> */}

      {/* <div id="sign-widget-parent" style="width:700px;height:500px"></div> */}
    </div>
  );
};

export default TestPage;
