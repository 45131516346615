import React from 'react';
import { useHistory } from 'react-router-dom';

const Page404 = () => {
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }
  const history = useHistory();

  function handleClick() {
    history.push('/applications');
  }
  return (
    <section className="auth main">
      <div className="mainBlock ">
        <p className="hugeText">404</p>
        <h1>Оновити сторінку</h1>
        <button className="btn-primary text-btn-primary" onClick={handleClick}>
          Повернутись на головну
        </button>
      </div>
    </section>
  );
};

export default Page404;
