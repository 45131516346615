import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getOrganisationsList } from '../../../redux/actions/organisations';
import Form from '../../DirectoriesPage/Form/Form';
import { selectRegions } from '../../../redux/selectors/dictionaries';
import {
  selectOrganisations,
  selectOrganisationsTotal,
} from '../../../redux/selectors/organisations';
import Pagination from '../../../components/Pagination/Pagination';
import { selectIsAdmin } from '../../../redux/selectors/auth';
import OrganisationsService from '../../../redux/services/organisations.service';

const PAGE_SIZE = 100;

export const Moderated = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const organisations = useSelector(selectOrganisations);
  const organisationsTotal = useSelector(selectOrganisationsTotal);
  const regions = useSelector(selectRegions);
  const isAdmin = useSelector(selectIsAdmin);

  const [form, setForm] = useState({});
  const [search, setSearch] = useState(sessionStorage.getItem('orgSearch') || '');
  const [formActive, setFormActive] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editOrg, setEditOrg] = useState(false);
  const [alertActive, setAlertActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getOrganisations = useCallback(
    async (page = currentPage) => {
      const limit = PAGE_SIZE;
      const offset = (page - 1) * limit;
      const sort = { createdAt: 'desc' };
      const filter = {};
      const filterValue = sessionStorage.getItem('orgSearch') || '';
      if (filterValue.length > 2) {
        filter.all = filterValue;
      }
      await dispatch(getOrganisationsList({ sort, offset, limit, filter }));
    },
    [currentPage],
  );

  const saveEditChangeOrg = () => {
    setForm({ category: '/organisations' });
    form.category = '/organisations';
    OrganisationsService.updateOrganisation(form).then(() => {
      setFormActive(false);
      void getOrganisations();
    });
    setEdit(false);
    setEditOrg(false);
  };

  const editClickOrg = (organisation) => {
    const data = {
      prevName: organisation.name,
      prevShortName: organisation.shortName,
      name: '',
      id: organisation.id,
    };
    setForm(data);
    setEdit(true);
    setEditOrg(true);
    setFormActive(true);
  };

  const mateClick = (id) => {
    history.push(`/mates/${id}`);
    sessionStorage.setItem('backLink', '/organisations/moderated');
  };

  const handleDelete = useCallback(() => {
    OrganisationsService.deleteOrganisation(deleteId)
      .then(() => {
        setAlertActive(false);
        void getOrganisations();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [deleteId]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setAlertActive(true);
  };

  const onSearchChange = (e) => {
    const value = e.target.value || '';
    setSearch(value);
    setCurrentPage(1);
    sessionStorage.setItem('orgSearch', value.trim());
    if (value.trim().length > 2 || !value.length) {
      void getOrganisations(1);
    }
  };

  useEffect(() => {
    void getOrganisations();
  }, []);

  return (
    <>
      <label className="search" style={{ margin: '-20px 0 20px 0', display: 'block' }}>
        <div className="btn" style={{ float: 'none' }}>
          <input
            className="search-input"
            type="text"
            onInput={onSearchChange}
            defaultValue={search}
            placeholder="Пошук (мінімум 3 символа)"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>
      <table className="public-table-cust">
        <thead>
          <tr>
            <th></th>
            <th>ЄДРПОУ&nbsp;код</th>
            <th>Область</th>
            <th>Коротка назва</th>
            <th>Повна назва</th>
            <th>Дата</th>
            <th>ПІБ</th>
            {/* <th><span>Коментар (для вказання причини відхилення)</span></th> */}
            <th style={{ width: '100px' }}></th>
            <th style={{ width: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {!!organisations.length &&
            organisations.map((organisation) => {
              return (
                <tr id={organisation.id} key={organisation.id}>
                  <td></td>
                  <td>{organisation.code}</td>
                  <td>
                    {' '}
                    {regions.map((region) => {
                      if (region.id === organisation.regionId) {
                        return region.title;
                      }
                    })}
                  </td>
                  <td>{organisation.shortName}</td>
                  <td>{organisation.name}</td>
                  <td>{organisation.createdAt}</td>
                  <td>{organisation.requestedByName}</td>
                  <td>
                    {isAdmin && (
                      <a href={organisation.scan} target="_blank" className="document-link">
                        Cтатут
                      </a>
                    )}
                  </td>
                  <td id={organisation.id} className="icon-row">
                    <i className="icon mates-icon" onClick={() => mateClick(organisation.id)}></i>
                    {isAdmin && (
                      <>
                        <i
                          className="icon edit-icon"
                          accessKey={organisation.shortName}
                          onClick={() => editClickOrg(organisation)}
                        ></i>
                        {organisation.type === 0 && (
                          <i
                            className="icon delete-icon"
                            onClick={() => handleDeleteClick(organisation.id)}
                          ></i>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          {!organisations.length && (
            <tr>
              <td colSpan="9" />
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="9">
              {!!organisationsTotal && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={organisationsTotal}
                  pageSize={PAGE_SIZE}
                  onPageChange={async (page) => {
                    await setCurrentPage(page);
                    await getOrganisations(page);
                  }}
                />
              )}{' '}
            </td>
          </tr>
        </tfoot>
      </table>

      {formActive && (
        <Form
          setFormActive={setFormActive}
          edit={edit}
          editOrg={editOrg}
          setEdit={setEdit}
          saveEditChangeOrg={saveEditChangeOrg}
          form={form}
          setForm={setForm}
        />
      )}
      {alertActive && (
        <div className="alert-label">
          <div>Ви впевнені, що хочете видалити?</div>
          <div className="alert-label-buttons">
            <button className="icon-text-btn-secondary garbage" onClick={handleDelete}>
              Видалити
            </button>
            <button
              className="round-btn-secondary close"
              onClick={() => setAlertActive(false)}
            ></button>
          </div>
        </div>
      )}
    </>
  );
};
