import {
  DEPARTMENTSLIST_FAIL,
  DEPARTMENTSLIST_SUCCESS,
  DEPARTMENTSITEM_FAIL,
  DEPARTMENTSITEM_SUCCESS,
  DEPARTMENTSEDIT_SUCCESS,
  DEPARTMENTSDELETE_FAIL,
  DEPARTMENTSDELETE_SUCCESS,
  DEPARTMENTSEDIT_FAIL,
  DEPARTMENTSADD_FAIL,
  DEPARTMENTSADD_SUCCESS,
} from './types';

import DepartmentService from '../services/department.service';

export const getDepartmentsList = (data) => (dispatch) => {
  return DepartmentService.getDepartmentsList(data).then(
    (response) => {
      dispatch({
        type: DEPARTMENTSLIST_SUCCESS,
        list: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: DEPARTMENTSLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
export const getDepartmentsItem = (data) => (dispatch) => {
  return DepartmentService.getDepartmentsItem(data).then(
    (response) => {
      dispatch({
        type: DEPARTMENTSITEM_SUCCESS,
        item: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: DEPARTMENTSITEM_FAIL,
      });
      console.log(error);
      return Promise.reject();
    },
  );
};
export const deleteDepartmentsItem = (data) => (dispatch) => {
  return DepartmentService.deleteDepartmentsItem(data).then(
    (response) => {
      dispatch({
        type: DEPARTMENTSDELETE_SUCCESS,
      });
    },
    (error) => {
      dispatch({
        type: DEPARTMENTSDELETE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
export const editDepartmentsItem = (form) => (dispatch) => {
  return DepartmentService.editDepartmentsItem(form).then(
    (response) => {
      dispatch({
        type: DEPARTMENTSEDIT_SUCCESS,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: DEPARTMENTSEDIT_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const addDepartmentsItem = (form) => (dispatch) => {
  return DepartmentService.addDepartmentsItem(form).then(
    (response) => {
      dispatch({
        type: DEPARTMENTSADD_SUCCESS,
        list: response,
      });

      return response;
    },
    (error) => {
      dispatch({
        type: DEPARTMENTSADD_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
