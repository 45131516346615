import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "../../../components/Avatar/Avatar";
import Footer from "../../../components/Footer/Footer";
import Pagination from "../../../components/Pagination/Pagination";
import { createSupportRoom, getSupportList } from "../../../redux/actions/support";
import { selectCurrentUserId, selectIsAdmin } from '../../../redux/selectors/auth';
import Form from "../../DirectoriesPage/Form/Form";


const SupportListPage=()=>{
    if (document.getElementsByClassName('sidebar')[0]) {
      document.getElementsByClassName('sidebar')[0].hidden = false;
    }
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const {list} = useSelector(state=>state);
    const currentUserId = useSelector(selectCurrentUserId);
    const [search, setSearch] = useState('');
    const [ready, setReady] = useState(false);
    const [create, setCreate] = useState(false);
    const [form, setForm] = useState(false);
    const [formMessage, setFormMessage] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const history = useHistory();
    const { support } = useSelector((state) => state);

    const createApprove = () => {
        dispatch(createSupportRoom(formMessage)).then(res=>{
            history.push(`/support/${res.id}`);
        }).catch(e=>{
          setErrorMessage('Під час створення запиту до тех. підтримки сталася технічна помилка. Спробуйте ще раз.');
        })
      };
      const createClick = () => {
        if(isAdmin){
          history.push(`/organisations/users`);
        }else{
          setCreate(true);
        }
      };

    const initialFunction = (page) => {
        setReady(true);
        dispatch(getSupportList({},page))
          .then((res) => {
            setForm(res);
            setReady(true);
          })
          .catch((e) => {
            setReady(true);
            setErrorMessage('Під час створення запиту до тех. підтримки сталася технічна помилка. Спробуйте ще раз.');
          });
      };

    const openClick =(id)=>{
      history.push(`/support/${id}`);

    }


    const changeSearch = (e) => {
        setSearch(e.target.value);
        if (e.target.value.length < 3 && search >= 3) {
          initialFunction();
        } else {
          initialFunction(e.target.value);
        }
      };

      useEffect(() => {
        if (!ready) {
          initialFunction();
        }
      }, [ready]);

    return(
        <div className="main auth-page">
         <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
            <div className="auth-menu">                
                <Avatar />
            </div>
  
        <h1 className="public-header">
          Технічна підтримка
        </h1>
        <label className="search" style={{ marginBottom: '30px' }}>
          <div className="btn">
            <input
              className="search-input"
              type="text"
              onChange={changeSearch}
              value={search}
              placeholder="Пошук (мінімум 3 символа)"
            />
            <i className="icon search-icon"></i>
          </div>
        </label>

       {<button className="create-app icon-text-btn-primary btn-primary" onClick={createClick}>
          Створити&nbsp;запит&nbsp;до&nbsp;підтримки
        </button>}
        <div className="public-table" style={{ overflowX: 'auto' }}>
          <table className="public-table-cust">
            <thead>
              <tr>
                <th>Тема</th>
                <th>Користувач</th>
                <th>Дата</th>
                <th>Статус</th>
              </tr>
            </thead>
            {form.length>0&&form?.map((el,index)=>{
              return(
                <>
                  <tr key={index} style={{cursor:'pointer',fontSize:'20px'}} onClick={()=>openClick(el.id)}>
                    <td>{list[el.topic] || 'Інше'}</td>
                    <td>{el.userRoom?.lastName} {el.userRoom?.firstName}</td>
                    <td>{el.createdAt}</td>
                    <td>{list[el.statuses]}</td>
                  </tr>
                </>
              )
            })}
           {support && support.total>50&& <tfoot className="">
              <tr>
                <td colSpan="4">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={support.total}
                    pageSize={50}
                    onPageChange={async (page) => {
                      setReady(false);
                      await setCurrentPage(page);
                      await initialFunction(page);
                    }}
                  />
                </td>
              </tr>
            </tfoot>}
          </table>
        </div>

        {create && <>
          <Form
            newSupportTicket={true}
            form={formMessage}
            setForm={setFormMessage}
            setFormActive={setCreate}
            saveAddChange={createApprove}
            closeClick={()=>setCreate(false)}
          />
        </>}


        {errorMessage && (
        <>
          <div className="alert-label">
            <div>{errorMessage}</div>
            <button
              className="round-btn-secondary close"
              onClick={() => setErrorMessage(null)}
            ></button>
          </div>
        </>
      )}
       
      </div>
    )
}

export default SupportListPage;