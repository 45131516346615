import React from 'react';
import { Redirect } from 'react-router-dom';

class Comment extends React.Component {
  constructor(props) {
    super(props);
  }
  state = { redirect: '/applications' };
  render() {
    if (
      document.getElementsByClassName('sidebar') &&
      document.getElementsByClassName('sidebar')[0] &&
      document.getElementsByClassName('sidebar')[0].hidden !== true
    ) {
      document.getElementsByClassName('sidebar')[0].hidden = true;
    }
    // if(!this.props.сomment){
    //     return <Redirect to={this.state.redirect} />
    // }
    return (
      <div
        style={{
          margin: '40px 0 0 30px',
          fontFamily: '"Times New Roman"',
          gridArea: 'article',
          width: '794px',
        }}
      >
        {this.props.comment &&
          this.props.comment.list &&
          this.props.comment.list.length > 0 &&
          this.props.comment.list.map((el) => {
            return (
              <div className="comment-item" id={el.id}>
                {el.sender && !el.sender.picture && (
                  <button className="avatar">
                    {el.sender?.lastName.charAt(0)}
                    {el.sender?.firstName.charAt(0)}
                  </button>
                )}
                {el.sender && el.sender.picture && (
                  <img src={el.sender.picture} className="avatar avatar-picture"></img>
                )}
                <div className="comment-item-text" style={{ position: 'relative' }}>
                  <h5>
                    <span>
                      {el.sender?.lastName} {el.sender?.firstName}{' '}
                    </span>
                    {el.createdAt}
                    {el.isRead && (
                      <i className="readed-icon icon" style={{ top: '-10px', right: '-45px' }}></i>
                    )}
                  </h5>
                  <label>{el.sender.name}</label>
                  <div>
                    {' '}
                    <p>{el.message}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default Comment;
