import axios from 'axios';

import constants from '../../constants/constant.json';
import authHeader from './auth-header';

const getDepartmentsList = (data) => {
  if (data.organisationsId) {
    return axios
      .get(
        constants.API_URL +
          `/organisations/${data.organisationsId}/departments?offset=0&limit=100&sort[type]=desc`,
        { headers: authHeader() },
      )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    return axios
      .get(constants.API_URL + `/organisations/${data}/departments?offset=0&limit=100`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
};
const getDepartmentsItem = (data) => {
  return axios
    .get(constants.API_URL + `/organisations/${data.organisationsId}/departments/${data.id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const deleteDepartmentsItem = (data) => {
  return axios
    .delete(constants.API_URL + `/organisations/${data.organisationsId}/departments/${data.id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
const editDepartmentsItem = (data) => {
  let body = { ...data, title: data.name };

  return axios
    .put(
      constants.API_URL + `/organisations/${data.organisationsId}/departments/${data.id}`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

const addDepartmentsItem = (data) => {
  let body = { ...data };

  return axios
    .post(
      constants.API_URL + `/organisations/${data.organisationsId}/departments`,
      { ...body },
      { headers: authHeader() },
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export default {
  addDepartmentsItem,
  editDepartmentsItem,
  deleteDepartmentsItem,
  getDepartmentsItem,
  getDepartmentsList,
};
