import { CERTIFICATEUNIQUEGETITEM_SUCCESS } from '../actions/types';

const initialState = [];

export default function certificateReducer(state = initialState, action) {
  const { type, list, offset, total } = action;
  switch (type) {
    case CERTIFICATEUNIQUEGETITEM_SUCCESS:
      return { list: [...list], offset: offset, total: total };
    default:
      return state;
  }
}
