import { LISTID_SUCCESS } from '../actions/types';

const initialState = [];

export default function listReducer(listFull = initialState, action) {
  const { type, list } = action;
  switch (type) {
    case LISTID_SUCCESS:
      return list;
    default:
      return listFull;
  }
}
