import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMatesList } from '../../redux/actions/mates';

import Pagination from '../../components/Pagination/Pagination';
import Avatar from '../../components/Avatar/Avatar';
import { createHistory } from '../../redux/actions/history';
import {
  ORGANISATION_ID_INVASPORT,
  ORGANISATION_ID_NON_OLYMPIC_TRAINERS,
  ORGANISATION_ID_OLYMPIC_TRAINERS,
} from '../../constants/const';
import { isUUID } from '../../utils';

const AddTrainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const { mates } = useSelector((state) => state);
  const { currentApplication } = useSelector((state) => state.applications);
  const [selected, setSelected] = useState('');
  const history = useHistory();
  const applicationId = useParams().id;
  const type = useParams().type;
  const isAddSpecialist = isUUID(type);

  const getItem = useCallback(
    async (search, page) => {
      let data = [];
      if (search && search.value.length > 2) {
        data.push({ name: 'all', value: search.value });
      }
      let element = '';
      if (type == 0) {
        element = ORGANISATION_ID_OLYMPIC_TRAINERS;
      } else if (type == 1) {
        element = ORGANISATION_ID_NON_OLYMPIC_TRAINERS;
      } else if (type == 2) {
        element = ORGANISATION_ID_INVASPORT;
      } else if (isAddSpecialist) {
        element = type;
      }

      await dispatch(getMatesList(element, data, page)).then((response) => {
        setReady(true);
      });
    },
    [type, search],
  );
  const searchHandler = (e) => {
    setCurrentPage(1);
    setSelected('');
    if (e.target.value.length > 2) {
      setSearch(e.target.value);
      let data = { name: 'all', value: e.target.value };
      getItem(data);
    } else {
      setSearch('');
      getItem();
    }
  };

  const addHandler = useCallback(async () => {
    let data = {};

    if (isAddSpecialist) {
      data = {
        id: applicationId,
        status: 5,
        employeeId: selected,
      };
    } else if (currentApplication.sportCategoryType === 2) {
      if (currentApplication && currentApplication.employeeId) {
        data = {
          id: applicationId,
          status: 12,
        };
      } else {
        data = {
          id: applicationId,
          status: 11,
          employeeId: selected,
        };
      }
    } else {
      if (currentApplication && currentApplication.trainerId) {
        data = {
          id: applicationId,
          status: 7,
        };
      } else {
        data = {
          id: applicationId,
          status: 6,
          trainerId: selected,
        };
      }
    }

    dispatch(createHistory(data)).then(() => {
      if (currentApplication.sportCategoryType === 2 || isAddSpecialist) {
        history.push('/applications');
      } else {
        history.push(`/applicationsItem/${applicationId}`);
      }
    });
  }, [selected]);

  const clickHandler = useCallback(
    (event) => {
      if (selected === event.currentTarget.id) {
        setSelected('');
      } else {
        setSelected(event.currentTarget.id);
      }
    },
    [selected],
  );

  const backHandler = () => {
    history.push(`/applicationsItem/${applicationId}`);
  };

  if (ready === false) {
    getItem();
  }

  if (
    document.getElementsByClassName('sidebar') &&
    document.getElementsByClassName('sidebar')[0] &&
    document.getElementsByClassName('sidebar')[0].hidden !== true
  ) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }
  return (
    <div className="main auth-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      {currentApplication && (
        <h1 className="public-header">
          {currentApplication.sportCategoryType === 0 && !isAddSpecialist && 'Вибір тренера'}
          {currentApplication.sportCategoryType === 1 && !isAddSpecialist && 'Вибір куратора'}
          {(currentApplication.sportCategoryType === 2 || isAddSpecialist) && 'Вибір спеціаліста'}
        </h1>
      )}
      <label className="search">
        <div className="btn">
          <input
            className="search-input"
            type="text"
            onChange={searchHandler}
            placeholder="Пошук"
          />
          <i className="icon search-icon"></i>
        </div>
      </label>
      <div className="create-app btn-tab">
        <button className="icon-text-btn-secondary back" onClick={backHandler}>
          Назад
        </button>
        <button
          className="icon-text-btn-primary btn-primary"
          disabled={!selected}
          onClick={addHandler}
        >
          Додати
        </button>
      </div>
      {ready && currentApplication && (
        <div className="public-table">
          <table className="public-table-add assign-specialist">
            <thead>
              <tr>
                <th width="60%">
                  <span>
                    {currentApplication.sportCategoryType === 0 &&
                      !isAddSpecialist &&
                      'ПІБ тренера'}
                    {currentApplication.sportCategoryType === 1 &&
                      !isAddSpecialist &&
                      'ПІБ куратора'}
                    {(currentApplication.sportCategoryType === 2 || isAddSpecialist) &&
                      'ПІБ спеціаліста'}
                  </span>
                </th>
                {currentApplication.sportCategoryType < 2 && (
                  <th width="10%">
                    <span>Cпорт</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {mates &&
                mates.list &&
                mates.list.length > 0 &&
                mates.list
                  .filter((item) => {
                    if (currentApplication.sportCategoryType === 2 || isAddSpecialist) {
                      return !item.isHeadEmployee && !item.isHeadTrustedEmployee;
                    }
                    return true;
                  })
                  .map((item) => {
                    return (
                      <tr
                        className={selected && selected === item.id ? 'selected' : ''}
                        onClick={clickHandler}
                        id={item.id}
                        key={item.id}
                      >
                        <td>
                          {item.lastName} {item.firstName} {item.secondName}
                          <i className="icon ready-icon"></i>
                        </td>
                        {currentApplication.sportCategoryType < 2 && (
                          <td>
                            {item.sports &&
                              item.sports.length > 0 &&
                              item.sports.map((el) => {
                                return el.title + ', ';
                              })}
                          </td>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
            {mates && mates.total > 10 && (
              <tfoot className="">
                <tr>
                  <td colSpan="2">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={mates.total}
                      pageSize="10"
                      onPageChange={async (page) => {
                        await setCurrentPage(page);
                        await getItem(null, page);
                      }}
                    />{' '}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default AddTrainer;
