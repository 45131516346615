import {
  COVERDOCUMENTSGETITEM_SUCCESS,
  COVERDOCUMENTSGETITEM_FAIL,
  COVERDOCUMENTSGETLIST_FAIL,
  COVERDOCUMENTSGETLIST_SUCCESS,
  COVERDOCUMENTSDELETE_SUCCESS,
  COVERDOCUMENTSDELETE_FAIL,
  COVERDOCUMENTSCREATE_FAIL,
  COVERDOCUMENTSCREATE_SUCCESS,
} from '../actions/types';

const initialState = [];

export default function coverDocumentsReducer(coverDocuments = initialState, action) {
  const { type, list } = action;

  switch (type) {
    case COVERDOCUMENTSGETITEM_SUCCESS:
      return coverDocuments;
    case COVERDOCUMENTSGETITEM_FAIL:
      return coverDocuments;
    case COVERDOCUMENTSGETLIST_FAIL:
      return coverDocuments;
    case COVERDOCUMENTSGETLIST_SUCCESS:
      return [...list];
    case COVERDOCUMENTSDELETE_SUCCESS:
      return coverDocuments;
    case COVERDOCUMENTSDELETE_FAIL:
      return coverDocuments;
    case COVERDOCUMENTSCREATE_FAIL:
      return coverDocuments;
    case COVERDOCUMENTSCREATE_SUCCESS:
      return [{ ...coverDocuments, list }];
    default:
      return coverDocuments;
  }
}
