import { APPLICATIONGETLIST_SUCCESS, APPLICATIONGETITEM_SUCCESS } from '../actions/types';

const initialState = [];

export default function applicationsReducer(state = initialState, action) {
  const { type, list, item, offset, total } = action;

  switch (type) {
    case APPLICATIONGETITEM_SUCCESS:
      return { ...state, currentApplication: item };
    case APPLICATIONGETLIST_SUCCESS:
      return { list: [...list], offset, total };
    default:
      return state;
  }
}
