import axios from 'axios';
import authHeader from './auth-header';
import constants from '../../constants/constant.json';

const register = (form) => {
  return axios
    .post(constants.API_URL + '/users', { ...form })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return e.response;
    });
};

const restore = (data) => {
  return axios
    .post(constants.API_URL + '/users/restore-password', {
      ...data,
    })
    .then((res) => {});
};

const getCode = (data) => {
  let email = data.email;

  return axios
    .post(constants.API_URL + '/users/email', {
      email,
    })
    .then((res) => {});
};

const refresh = (refreshToken) => {
  let data = { refreshToken: refreshToken };
  return axios
    .post(constants.API_URL + '/auth/refresh', data, { headers: authHeader() })
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem('userData', JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      logout();
    });
};

const code = (data) => {
  return axios
    .post(constants.API_URL + '/auth/code', {
      ...data,
    })
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem('userData', JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response.data);
    });
};

const login = (email, password) => {
  return axios
    .post(constants.API_URL + '/auth/login', {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem('userData', JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response.data);
    });
};

const loginOrganisation = (data) => {
  return axios
    .post(constants.API_URL + '/auth/login-organisation', data,{ headers: authHeader() } )
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem('userData', JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response.data);
    });
};

const impersonate = (userId) => {
  return axios
    .post(constants.API_URL + '/auth/impersonate/' + userId, {}, { headers: authHeader() })
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem('userData', JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response.data);
    });
};

const logout = () => {
sessionStorage.clear();
localStorage.removeItem('userData');
localStorage.clear();
window.location.reload();
};

export default {
  register,
  login,
  logout,
  refresh,
  restore,
  getCode,
  code,
  impersonate,
  loginOrganisation
};
