import {
  DOCUMENTSGETITEM_SUCCESS,
  DOCUMENTSGETITEM_FAIL,
  DOCUMENTSGETLIST_FAIL,
  DOCUMENTSGETLIST_SUCCESS,
  DOCUMENTSDELETE_SUCCESS,
  DOCUMENTSDELETE_FAIL,
  DOCUMENTSCREATE_FAIL,
  DOCUMENTSCREATE_SUCCESS,
} from './types';

import DocumentsService from '../services/documents.service';

export const getDocumentsList = (data) => (dispatch) => {
  return DocumentsService.getDocumentsList(data).then(
    (response) => {
      dispatch({
        type: DOCUMENTSGETLIST_SUCCESS,
        list: response.items,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: DOCUMENTSGETLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const createDocument = (data) => (dispatch) => {
  return DocumentsService.addDocument(data).then(
    (response) => {
      dispatch({
        type: DOCUMENTSCREATE_SUCCESS,
        list: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: DOCUMENTSCREATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const deleteDocument = (data) => (dispatch) => {
  return DocumentsService.deleteDocument(data).then((response) => {
    dispatch({
      type: DOCUMENTSDELETE_SUCCESS,
      list: response.items,
    });
    return response.items;
  });
};

export const getDocumentItem = (data) => (dispatch) => {
  return DocumentsService.getDocument(data).then(
    (response) => {
      dispatch({
        type: DOCUMENTSGETITEM_SUCCESS,
        list: response.items,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: DOCUMENTSGETITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const regenerateDigitalProtocol = (data) => (dispatch) => {
  return DocumentsService.regenerateDigitalProtocol(data).then(
    (response) => {
      return response;
    },
    (error) => {
      dispatch({
        type: DOCUMENTSCREATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
