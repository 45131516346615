import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from '../../redux/actions/auth';
import { getCode, restore } from '../../redux/actions/auth';

const RestorePage = () => {
  const history = useHistory();
  const [form, setForm] = useState({
    email: '',
    code: '',
    password: '',
  });

  const [validForm, setValidForm] = useState({
    email: false,
    password: true,
    code: true,
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const [code, setCode] = useState(false);
  const [readyForChange, setReadyForChange] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [error, setError] = useState([]);
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);
  if (document.getElementsByClassName('sidebar') && document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }
  const handleClick = () => {
    history.push('/login');
  };
  const dispatch = useDispatch();
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const resiveCode = async (event) => {
    try {
      event.preventDefault();
      setSeconds(60);
      setReadyForChange(true);
      dispatch(getCode({ email: form.email }))
        .then((res) => {})
        .catch((e) => {
          console.log(e);
        });
      setCode(true);
    } catch (e) {
      console.log(e);
    }
  };

  const changeHandler = (event) => {
    const emailCheck =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{6,30}$/;
    const codeCheck = /^[0-9]{6}$/;
    if (error.param) {
      setError([]);
    }
    switch (event.target.name) {
      case 'email':
        if (code) {
          setReadyForChange(true);
        }
        setCode(false);
        if (emailCheck.test(String(event.target.value).toLowerCase())) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case 'password':
        if (passwordCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case 'code':
        if (codeCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      default:
        break;
    }
  };
  const restorePsw = async (event) => {
    event.preventDefault();
    setReadyForChange(true);
    dispatch(restore(form))
      .then((res) => {
        if (res && res.errors) {
          setError(res.errors[0]);
        } else {
          handleClick();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="auth main">
      <form className="mainBlock" onSubmit={restorePsw}>
        <h1 className="loginHeader">Відновлення паролю</h1>
        <label className="labelText btn">
          <span>Електронна пошта (логін)</span>
          {readyForChange && seconds > 0 && (
            <span className="prompt">Отримати код знову можна через: {seconds}сек.</span>
          )}
          <div className="btn">
            <input
              className={
                !validForm.email && form.email && form.email.length > 0
                  ? 'inputInvalid btn txt-input'
                  : 'btn txt-input'
              }
              type="email"
              name="email"
              required
              onChange={changeHandler}
              value={form.email}
            ></input>
            {code ? (
              <i className="icon ready-icon"></i>
            ) : (
              <button
                className="txt-button-small"
                onClick={resiveCode}
                disabled={!validForm.email || seconds > 0}
              >
                Отримати код
              </button>
            )}
          </div>
          {error.param === 'email' && <span className="validError">{error.msg}</span>}
        </label>
        {code && (
          <label className="labelText btn">
            <span>Код із ел. пошти </span>
            <input
              className={!validForm.code ? 'inputInvalid btn txt-input' : 'btn txt-input'}
              disabled={!code}
              type="text"
              name="code"
              required
              onChange={changeHandler}
              value={form.code}
            />
            {!validForm.code && <span className="validError">Перевірте правильність коду</span>}
            {error.param === 'code' && <span className="validError">{error.msg}</span>}
          </label>
        )}
        {code && (
          <label className="labelText ">
            <span>Новий пароль</span>
            <span className="prompt">
              Мінімум 6 символів, 1 велика і 1 маленька букви та 1 цифра
            </span>
            <div className="btn">
              <input
                className={!validForm.password ? 'inputInvalid psw-input' : 'psw-input'}
                type={passwordShown ? 'text' : 'password'}
                name="password"
                required
                onChange={changeHandler}
                value={form.password}
              />
              <i
                className={!passwordShown ? 'icon password-icon' : 'icon password-icon-noActive'}
                onClick={togglePasswordVisiblity}
              ></i>
            </div>
            {!validForm.password && <span className="validError">Перевірте пароль</span>}
            {error.param === 'password' && <span className="validError">{error.msg}</span>}
          </label>
        )}
        {code && (
          <button
            className="btn-primary text-btn-primary"
            type="submit"
            disabled={
              !validForm.password ||
              !form.password.length > 0 ||
              !validForm.code ||
              !form.code.length > 0 ||
              error.param
            }
          >
            Зберегти новий пароль
          </button>
        )}
        <a className="link" onClick={handleClick}>
          Відмінити{' '}
        </a>
      </form>
    </div>
  );
};

export default RestorePage;
