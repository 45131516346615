import {
  COVERDOCUMENTSGETITEM_SUCCESS,
  COVERDOCUMENTSGETITEM_FAIL,
  COVERDOCUMENTSGETLIST_FAIL,
  COVERDOCUMENTSGETLIST_SUCCESS,
  COVERDOCUMENTSDELETE_SUCCESS,
  COVERDOCUMENTSDELETE_FAIL,
  COVERDOCUMENTSCREATE_FAIL,
  COVERDOCUMENTSCREATE_SUCCESS,
} from './types';

import CoverDocumentsService from '../services/coverDocuments.service';

export const getDocumentsList = (data) => (dispatch) => {
  return CoverDocumentsService.getCoverDocumentsList(data).then(
    (response) => {
      dispatch({
        type: COVERDOCUMENTSGETLIST_SUCCESS,
        list: response.items,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERDOCUMENTSGETLIST_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const createDocument = (data) => (dispatch) => {
  return CoverDocumentsService.addCoverDocument(data).then(
    (response) => {
      dispatch({
        type: COVERDOCUMENTSCREATE_SUCCESS,
        list: response,
      });
      return response;
    },
    (error) => {
      dispatch({
        type: COVERDOCUMENTSCREATE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const deleteDocument = (data) => (dispatch) => {
  return CoverDocumentsService.deleteCoverDocument(data).then(
    (response) => {
      dispatch({
        type: COVERDOCUMENTSDELETE_SUCCESS,
        list: response.items,
      });

      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERDOCUMENTSDELETE_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};

export const getDocumentItem = (data) => (dispatch) => {
  return CoverDocumentsService.getCoverDocument(data).then(
    (response) => {
      dispatch({
        type: COVERDOCUMENTSGETITEM_SUCCESS,
        list: response.items,
      });
      return response.items;
    },
    (error) => {
      dispatch({
        type: COVERDOCUMENTSGETITEM_FAIL,
      });
      console.log(error);

      return Promise.reject();
    },
  );
};
